import React, { Component } from 'react';
import '../../assets/scss/core.scss';
import './HomeContent.scss';
import Header from '../../components/Header/Header';
//import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
//import { connect } from 'react-redux';
import Pux from '../../hoc/Pux';
import Typography from '@material-ui/core/Typography';
import Heading from '../../components/Heading/Heading';
import PromoBanner from '../../components/PromoBanner/PromoBanner';
import Button from '@material-ui/core/Button';
import * as routes from '../../utils/routes.js';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';
import firebase from '../../Firebase';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import {Helmet} from 'react-helmet';

  
export class HomeLoggedOut extends Component {

    state = {
        browser:true,
        featuredCourses:[]
    }

    componentDidMount() {
        if(typeof globalNative !== 'undefined'){
            this.setState({browser:false});
        }
        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);

        db.collection("courses")
            .where("featured","==",true)
            .limit(5)
            .get().then((querySnapshot) => {
                //console.log("querySnapshot: ",querySnapshot.size);
                let fetchedCourses = querySnapshot.docs.map(function (documentSnapshot) {
                    return {
                        ...documentSnapshot.data()
                    };
                });
                if(fetchedCourses){
                    this.setState({
                        featuredCourses:fetchedCourses
                    });
                }
            });
    }

    componentWillMount() {
        document.title = 'Golf Handicap - Making handicap easy';
    }


    render() {


        let actions = (
            <Typography className="gh-actionline">
                <Button
                    className="gh-actionline__button"
                    type="submit"
                    fullWidth
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        this.props.history.push('/signin');
                    }}
                >
                    Sign In
                </Button>
                <Button
                    className="gh-actionline__button"
                    fullWidth
                    type="submit"
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        this.props.history.push('/signup');
                    }}
                >
                    Sign Up FREE
                </Button>
            </Typography>
        );

        let appLinks = null;
        if(this.state.browser){
            appLinks = (
                <React.Fragment>
                    <div className="gh-offer gh-offer--reducedbottom --hidenative" onClick={this.purchase}>
                        <Typography className="gh-offer__text">Apple iOs App
                        </Typography>
                        <a 
                            href={routes.APPLE_STORE_APP}
                            className="gh-offer__price gh-offer__price--image"
                            >
                            <img 
                                src="https://firebasestorage.googleapis.com/v0/b/golf-handicapp.appspot.com/o/assets%2Fapp-store-logo.png?alt=media&token=866144a4-822a-4522-a0cf-01f1e7d2b155"
                                alt="Download on the App Store" />
                        </a>
                    </div>
                    <div className="gh-offer gh-offer--reducedbottom --hidenative">
                        <Typography className="gh-offer__text">Android App
                        </Typography>
                        <a 
                            href={routes.GOOGLE_PLAY_APP}
                            className="gh-offer__price gh-offer__price--image"
                            >
                            <img 
                                src="https://firebasestorage.googleapis.com/v0/b/golf-handicapp.appspot.com/o/assets%2Fgoogle-play-logo-new.png?alt=media&token=c4941689-c7d5-442f-a425-b51b5684665e"
                                alt="Get it on Google play" />
                        </a>
                    </div>
                </React.Fragment>
            );
        }


        return (
            <Pux>
                <Header loggedOut={true} />
                <div className="gh-content gh-content--contentful">

                    {/*<PromoBanner onClick={() => {
                        this.props.history.push(routes.CONTENT_PREMIUM);
                    }} />*/}

                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Golf Handicap - Handicap Calculator | Golf Courses | Reviews + Ratings | Free Sign Up Now!</title>
                        <meta name="description" content="Calculate your handicap here. Track your handicap. UK Golf Course Directory, read and review your golf club. Follow your friends Handicap. Free Sign up!" />
                        <meta property="og:title" content="Golf Handicap - Handicap Calculator | Golf Courses | Reviews + Ratings | Free Sign Up Now!" />
                        <meta property="og:url" content="https://golfhandicapp.com" />
                        <meta property="og:type" content="website" />
                        <meta property="og:description" content="Calculate your handicap here. Track your handicap. UK Golf Course Directory, read and review your golf club. Follow your friends Handicap. Free Sign up!" />
                        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/golf-handicapp.appspot.com/o/assets%2Fpromo%2Faugusta.jpg?alt=media&token=d33b64f7-b272-4a18-a1bd-96ad44716b6c" />
                    </Helmet>
                    
                    <React.Fragment>
                        <div className="gh-herotext">

                            <div className="gh-banner gh-herotext__hero">
                                <img 
                                    className="gh-banner__background"
                                    src="https://firebasestorage.googleapis.com/v0/b/golf-handicapp.appspot.com/o/assets%2Fpromo%2Faugusta.jpg?alt=media&token=d33b64f7-b272-4a18-a1bd-96ad44716b6c"
                                    alt="Golf Handicapp" />
                                <img 
                                    className="gh-banner__feature"
                                    src="https://firebasestorage.googleapis.com/v0/b/golf-handicapp.appspot.com/o/assets%2Fpromo%2Fscorecard-iphone.png?alt=media&token=5776b05f-0fcf-42f2-9bec-f7e6305afba3"
                                    alt="Golf Handicapp" />
                                <Heading variant="h1">Welcome to Golf Handicapp</Heading>
                            </div>
                            <div className="gh-banner gh-herotext__text">
                                {appLinks}
                                {actions}
                                
                                <Typography className="gh-content__para">
                                    Welcome, calculating your golf handicap can be tricky, here at Golf Handicapp we attempt to do all this for you. Sign up for FREE and see if this golf handicap app helps track your golf hanidcap progress.
                                </Typography>
                            </div>
                        </div>

                        <div className="gh-herotext gh-herotext--reverse">
                            <div className="gh-herotext__hero">
                                <img 
                                    src="https://firebasestorage.googleapis.com/v0/b/golf-handicapp.appspot.com/o/assets%2Fpromo%2Faddingscores-phone-opt.jpg?alt=media&token=4caec16f-634f-4464-95f8-819002bf2e1f"
                                    alt="Using Golf Handicapp" />
                            </div>

                            <div className="gh-content__block gh-herotext__text">
                                <Heading type="h2" variant="h2">Why <span className="gh-premium">Sign Up</span>?</Heading>
                                <List className="gh-lists">
                                    <ListItem className="gh-lists__item gh-lists__item--promo">
                                        <Avatar className="gh-lists__item-avatar">
                                            <CheckIcon className="gh-lists__item-avatar-icon" />
                                        </Avatar>
                                        <ListItemText primary="Free to use" secondary="This is free!" />
                                    </ListItem>
                                    <ListItem className="gh-lists__item gh-lists__item--promo">
                                        <Avatar className="gh-lists__item-avatar">
                                            <CheckIcon className="gh-lists__item-avatar-icon" />
                                        </Avatar>
                                        <ListItemText primary="Unlimited" secondary="Easily add as many round of golf as you want!" />
                                    </ListItem>
                                </List>
                            </div>
                        </div>

                        <div className="gh-herotext">
                            <div className="gh-herotext__hero">
                                <img 
                                    src="https://firebasestorage.googleapis.com/v0/b/golf-handicapp.appspot.com/o/assets%2Fpromo%2Fstats-hold.jpg?alt=media&token=79770de3-5c17-4e72-93d3-f7a8115e5047"
                                    alt="Using Golf Handicapp" />
                            </div>
                            <div className="gh-banner gh-herotext__text">
                                <Typography className="gh-content__para">
                                    Not a member of a club? This app is perfect for the golfer who isn't a member of a club and wants to work out your handicap adjustment. At the very least give the FREE app a go.    
                                </Typography>
                                {appLinks}
                                {actions}
                            </div>
                        </div>

                        {this.state.featuredCourses.length ? 
                            <div className="gh-featured-content">
                                <Heading type="h3" variant="left">Featured Courses</Heading>
                                <div className="gh-featured-content__tube --alone">
                                    {this.state.featuredCourses.map((course,i) => {
                                        return (
                                            <Card onClick={() => {
                                                this.props.history.push('/course/' + course.id)
                                            }} className="gh-featured-content__card" key={i}>
                                                <CardActionArea>
                                                <CardMedia
                                                    style={{height:'140px'}}
                                                    image={course.image}
                                                    title="Contemplative Reptile"
                                                />
                                                <CardContent style={{paddingBottom:'0'}}>
                                                    <Typography gutterBottom variant="h6" component="h3">
                                                    {course.title}
                                                    </Typography>
                                                    <Typography className="gh-featured-content__address" component="p">
                                                    {course.county ? course.county : course.town}
                                                    {', ' + course.country}
                                                    </Typography>
                                                </CardContent>
                                                </CardActionArea>
                                                <CardActions>
                                                    <Button size="small" color="primary">
                                                        Learn More
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        );
                                    })}
                                </div>
                            </div>
                        : null}

                        
                    </React.Fragment>
                    
                </div>
            </Pux>
        );
    }
}
/*
HomeLoggedOut.propTypes = {
    searchTypeHandler: PropTypes.func
}*/

export default withRouter(HomeLoggedOut);
