import React, { Component } from 'react';
import Pux from '../../hoc/Pux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import firebase from '../../Firebase';
import { connect } from 'react-redux';
import { Button } from '../../../node_modules/@material-ui/core';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';



class ConnectionButton extends Component {

    state = {
        profileData: {
            connections:null
        },
        loading:false
    }

    

    componentDidMount () {

        
        let currentConnections = this.props.currentConnections;
        if(currentConnections) {
            this.setState({
                profileData: {
                    connections: currentConnections
                }
            });
            return;
        }

        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);

       
        const profileDocRef = db.collection("user_meta").doc(this.props.to);
        profileDocRef.get().then((profiledoc) => {
            if(profiledoc.exists){
                
                const rawData = {...profiledoc.data()}
                //console.log("get data...",rawData);
                if(!rawData.following) {
                    rawData.following = [];
                }
                if(!rawData.followers) {
                    rawData.followers = [];
                }               
                this.setState({profileData:rawData});

            }
        }).catch((error) => {
            this.setState({profileData:true});
        });
        
        
    }

    follow = () => {

        this.setState({loading:true});

        //add users id to this profiles followers
        const cachedState = {...this.state}
        cachedState.profileData.followers.push(this.props.from);

        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);

        console.log("get data...",this.props.from);
        const myDocRef = db.collection("user_meta").doc(this.props.to);
        myDocRef.update({
            followers: cachedState.profileData.followers
        }).then(() => {

            //now need to update this users following (after getting users data)
            const myDocRef = db.collection("user_meta").doc(this.props.from);
            myDocRef.get().then((myDoc) => {
                if(myDoc.exists){
                    
                    const myRawData = {...myDoc.data()}
                    //console.log("get myRawData...",myRawData);
                    if(!myRawData.following) {
                        myRawData.following = [];
                    }

                    myRawData.following.push(this.props.to);
                    //now set it 
                    myDocRef.update({
                        following: myRawData.following
                    }).then(() => {
                        //uodate state
                        cachedState.loading = false;
                        this.setState(cachedState);
                    });
                } else {
                    cachedState.loading = false;
                    this.setState(cachedState);
                }
            }).catch((error) => {
                //uodate state
                cachedState.loading = false;
                this.setState(cachedState);
            });

            
        });
    }

    unfollow = () => {

        this.setState({loading:true});

        //remove user from their followers 
        const cachedState = {...this.state}
        const index = cachedState.profileData.followers.indexOf(this.props.from);
        if(index !== -1){
            cachedState.profileData.followers.splice(index,1);
        }

        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);

        //console.log("get data...",this.props.from);
        const myDocRef = db.collection("user_meta").doc(this.props.to);
        myDocRef.update({
            followers: cachedState.profileData.followers
        }).then(() => {

            //now update me, remove him from me
            const myDocRef = db.collection("user_meta").doc(this.props.from);
            myDocRef.get().then((myDoc) => {
                if(myDoc.exists){
                    
                    const myRawData = {...myDoc.data()}
                    //console.log("get myRawData...",myRawData);
                    if(!myRawData.following) {
                        return;
                    }
                    const index = myRawData.following.indexOf(this.props.to);
                    if(index !== -1){
                        myRawData.following.splice(index,1);
                    }
                    //now set it 
                    myDocRef.update({
                        following: myRawData.following
                    }).then(() => {
                        //uodate state
                        cachedState.loading = false;
                        this.setState(cachedState);
                    });
                } else {
                    cachedState.loading = false;
                    this.setState(cachedState);
                }
            }).catch((error) => {
                //uodate state
                cachedState.loading = false;
                this.setState(cachedState);
            });

            
        });
    }

    

    render() {

        //console.log("state date: ",this.state.profileData);
        
       
        let isFollowing = false;
        if(this.state.profileData.followers){
            isFollowing = (this.state.profileData.followers.indexOf(this.props.from) !== -1);
        }
        const hasData = this.state.profileData.following || this.state.profileData.followers;

        return (
            <Pux>
                <div className={this.props.to === this.props.from ? "gh-connections gh-connections--mine" : "gh-connections"}>
                    <Typography className="gh-connections__line">
                        {this.state.profileData.following || this.state.profileData.followers ? 
                            <Pux>
                                <Link className="gh-connections__line-link" to={'/connections/following/' + this.props.match.params.id}>
                                    <span className="gh-connections__line-link-icon">{this.state.profileData.following ? this.state.profileData.following.length : 0}</span> Following
                                </Link>
                                <Link className="gh-connections__line-link" to={'/connections/followers/' + this.props.match.params.id}>
                                    <span className="gh-connections__line-link-icon">{this.state.profileData.followers ? this.state.profileData.followers.length : 0}</span> Followers
                                </Link>
                            </Pux>  
                        : null}  
                        {hasData && (this.props.to !== this.props.from) ? 
                            <Pux>
                                {isFollowing ?
                                    <Button
                                        className={this.props.className}
                                        variant="outlined"
                                        color="primary"
                                        size="medium"
                                        disabled={this.state.loading}
                                        onClick={this.unfollow}
                                        >
                                        Following
                                    </Button>
                                : 
                                <Button
                                    className={this.props.className}
                                    variant="contained"
                                    color="primary"
                                    disabled={this.state.loading}
                                    size="medium"
                                    onClick={this.follow}
                                    >
                                    Follow
                                </Button>
                            }
                            </Pux>
                        : null}
                    </Typography>
                </div>
            </Pux>
        );
    }
}

ConnectionButton.propTypes = {
   profileData: PropTypes.object
}

const mapStateToProps = state => {
    return {
        userId: state.auth.userId,
        userMeta: state.auth.userMeta
    }
}


export default withRouter(connect(mapStateToProps, null)(ConnectionButton));
