import React, { Component } from 'react';
//import axios from 'axios';
import Pux from '../../hoc/Pux';
import { withRouter } from 'react-router-dom';
import decodeHtml from '../../utils/utils';
import PropTypes from 'prop-types';
import firebase from '../../Firebase';
import ScreenLoading from '../../components/ScreenLoading/ScreenLoading';
import Heading from '../../components/Heading/Heading';
import Header from '../../components/Header/Header';
import AppBar from '../AppBar/AppBar';
import Golfer from '../Golfer/Golfer';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';



class Connections extends Component {

    state = {
        profileData : [],
        connections: null,
        profileDetailsFetched:false,
        connectionMax: 5
    }
    

    componentDidMount () {

        
        const profileId = this.props.match.params.id;
        if(!profileId){
            return;
        }
        const type = this.props.match.params.type;
        
        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);

        
        const profileDocRef = db.collection("user_meta").doc(profileId);
        profileDocRef.get().then((profiledoc) => {
            if(profiledoc.exists){

                document.title = 'Golf Handicap - ' + type + ' - ' + decodeHtml(profiledoc.data().displayname);

                const cachedState = {...this.state}
                cachedState.profileData = profiledoc.data();
                cachedState.profileDetailsFetched = true;
                this.setState(cachedState);
                

                /*const storage = firebase.storage();
                const storageRef = storage.ref();
                storageRef.child('userpics/' + profileId + '-pp.jpg').getDownloadURL().then((url) => {
                    cachedState.profileData.profilepic = url;
                    this.setState(cachedState);
                }).catch((error) => {
                    //error method
                    this.setState(cachedState);
                });*/
                
            } else {
                this.setState({profileDetailsFetched:true});
            }
        }).catch((error) => {
            this.setState({profileDetailsFetched:true});
        });
        
        
    }

    backClick = () => {
        let path = '/profile/' + this.props.userId;
        this.props.history.push(path);
    }

   

    render() {

        const type = this.props.match.params.type;
        

        return (
            <Pux>
                <Header backClick={this.backClick} />
                {this.state.profileDetailsFetched && this.props.userId ? 
                    <Pux>
                        {this.state.profileData ? 
                            <div className="gh-content">
                            
                                <Heading type="h2" variant="central">
                                    {this.props.match.params.type === 'following' ? 
                                        <React.Fragment>{this.state.profileData.displayname} is {this.props.match.params.type}</React.Fragment>
                                    : <React.Fragment>{this.state.profileData.displayname} {this.props.match.params.type}</React.Fragment>}
                                    
                                </Heading>

                                {this.state.profileData[type] ? 
                                    <React.Fragment>
                                        {this.state.profileData[type].filter((val,i) => {
                                                return i < this.state.connectionMax;
                                            })
                                            .map((user,i) => {
                                                return (
                                                    <Golfer key={i} uid={user} />
                                                );
                                            })}
                                        {this.state.profileData[type].length > this.state.connectionMax ? 
                                            <Typography align="center" className="gh-seemore">
                                                <Badge color="primary" badgeContent={this.state.profileData[type].length - this.state.connectionMax} className="gh-seemore__badge">
                                                    <Button onClick={() => {
                                                        //add 10
                                                        this.setState({connectionMax: this.state.connectionMax + 10});
                                                    }} variant="outlined">See More</Button>
                                                </Badge>
                                            </Typography>
                                        : null}
                                    </React.Fragment> 
                                : <Typography>No connections yet.</Typography>}
                                
                                
                            </div>
                        : <div className="gh-content"><Typography>Oops something went wrong.</Typography></div>}
                    </Pux>
                : <ScreenLoading />}
                <AppBar selected="home" />
            </Pux>
        );
    }
}

Connections.propTypes = {
   profileData: PropTypes.object
}

const mapStateToProps = state => {
    return {
        userId: state.auth.userId,
        userMeta: state.auth.userMeta
    }
}


export default withRouter(connect(mapStateToProps, null)(Connections));
