import React from 'react';
import './LiveScores.scss';
import BigSmallValue from '../BigSmallValue/BigSmallValue';

const LiveScores = (props) => {

    let mainClasses = ['gh-livescores'];
    if(props.className){
        mainClasses.push(props.className);
    }


    return (
        <div className={mainClasses.join(' ')}>
            <BigSmallValue 
                className="gh-livescores__vals" 
                bigClass="gh-livescores__vals-big"
                smallClass="gh-livescores__vals-small"
                value={props.points} 
                label="Points" />
            <BigSmallValue 
                className="gh-livescores__vals" 
                bigClass="gh-livescores__vals-big"
                smallClass="gh-livescores__vals-small" 
                value={props.topar} 
                label="To Par" />
        </div>

    );
};
export default LiveScores;