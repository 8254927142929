import * as actionTypes from  '../actions/actionsTypes';
import { updateObject } from '../utility';

const intialState = {
    searchesmade:0
}
const reducer = (state = intialState,action) => {   
    switch ( action.type ) {
        case actionTypes.INC_SEARCHES:
            return updateObject(state,{searchesmade:state.searchesmade + 1});
        default:
            return state;
    } 
}

export default reducer;