import React, { Component } from 'react';
import Header from '../../components/Header/Header';
import AppBar from '../../containers/AppBar/AppBar';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import LinkUi from '@material-ui/core/Link';
import { connect } from 'react-redux';
import Pux from '../../hoc/Pux';
import firebase from '../../Firebase';
import Heading from '../../components/Heading/Heading';
import SearchField from '../../components/SearchField/SearchField';
import ScreenLoading from '../../components/ScreenLoading/ScreenLoading';
import InputAdornment from '@material-ui/core/InputAdornment';
import FlagIcon from '@material-ui/icons/GolfCourse';
import { Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import {Helmet} from 'react-helmet';
import Button from '@material-ui/core/Button';


export class SearchCourses extends Component {

    state = {
        searchResults: null,
        searchTerm: '',
        redirect: null,
        featuredCourses:[]
    }

    componentDidMount() {
        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);

        db.collection("courses")
            .where("featured","==",true)
            .limit(5)
            .get().then((querySnapshot) => {
                //console.log("querySnapshot: ",querySnapshot.size);
                let fetchedCourses = querySnapshot.docs.map(function (documentSnapshot) {
                    return {
                        ...documentSnapshot.data()
                    };
                });
                if(fetchedCourses){
                    this.setState({
                        featuredCourses:fetchedCourses
                    });
                }
            });
    }

    componentWillMount() {
        document.title = 'Golf Handicap - Find Golf Courses';
    }

    onCourseSelect = (id,title) => {
        this.props.history.push('/course/' + id);
    }

    loadRequestCourse = () => {
        this.props.history.push('/requestnewcourse');
    }

    inputChangedHandler = (event) => {
        const inputValue = event.target.value;
        this.setState({searchTerm:inputValue});
    }

    submitHandler = (event) => {
        event.preventDefault();
    }


    render() {

        return (
            <Pux>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Search 1000's of Golf Courses | Golf Handicap</title>
                    <meta name="description" content="Search from thousands of golf courses across the United Kingdom." />
                </Helmet>
                <Header loggedOut={this.props.isAuthenticated !== true} />
                <div className="gh-content">
                
                    <Pux>
                        <Heading type="h1" variant="central">Find Golf Courses</Heading>
                        <form className="form" onSubmit={this.submitHandler}>
                            <Typography>
                                Search from thousands of golf courses across the United Kingdom.
                                {this.props.isAuthenticated ? 
                                    <Pux>If you dont find the course you are looking for the please <LinkUi onClick={this.loadRequestCourse}>Request a new course.</LinkUi></Pux>
                                : null}
                            </Typography>
                            <SearchField 
                                fieldName="searchcourses"
                                fieldLabel="Course Name"
                                isValid={true}
                                returnHitHandler={this.onCourseSelect}
                                onCourseSelect={this.onCourseSelect}
                                inputProps={{
                                    onChange:this.inputChangedHandler,
                                    className:"form__input",
                                    id:"searchcourses",
                                    startAdornment:(
                                        <InputAdornment position="start">
                                            <FlagIcon />
                                        </InputAdornment>
                                    ),
                                    name:"searchcourses",
                                    value:this.state.searchTerm,
                                    inputProps:{autoComplete:"off"}
                                }}
                                
                                />
                        </form>
                        {this.state.featuredCourses.length ? 
                            <div className="gh-featured-content">

                                <div className="gh-featured-content__tube --alone">
                                    {this.state.featuredCourses.map((course,i) => {
                                        return (
                                            <Card onClick={() => {
                                                this.props.history.push('/course/' + course.id)
                                            }} className="gh-featured-content__card" key={i}>
                                                <CardActionArea>
                                                <CardMedia
                                                    style={{height:'140px'}}
                                                    image={course.image}
                                                    title="Contemplative Reptile"
                                                />
                                                <CardContent style={{paddingBottom:'0'}}>
                                                    <Typography gutterBottom variant="h6" component="h3">
                                                    {course.title}
                                                    </Typography>
                                                    <Typography className="gh-featured-content__address" component="p">
                                                    {course.county ? course.county : course.town}
                                                    {', ' + course.country}
                                                    </Typography>
                                                </CardContent>
                                                </CardActionArea>
                                                <CardActions>
                                                    <Button size="small" color="primary">
                                                        Learn More
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        );
                                    })}
                                </div>
                            </div>
                        : null}
                    </Pux>  
                </div>
                <AppBar selected="home" />
            </Pux>
        );
    }
}

SearchCourses.propTypes = {
    onCourseSelect: PropTypes.func
}

const mapStateToProps = state => {
    return {
        userId: state.auth.userId,
        userMeta: state.auth.userMeta,
        isAuthenticated: state.auth.token !== null
    }
}

export default withRouter(connect(mapStateToProps)(SearchCourses));
