import React, { Component } from 'react';
import PropTypes from 'prop-types';
import decodeHtml from '../../utils/utils';
import { Link, withRouter } from 'react-router-dom';
import SecurityIcon from '@material-ui/icons/Security';
import './RecommendedFriends.scss';
import ProfilePic from '../../components/ProfilePic/ProfilePic';
import Typography from '@material-ui/core/Typography';
import firebase from '../../Firebase.js';

class RecommendedFriends extends Component {

    state = {
        recommendations: [],
        showResults: false,
        fetchingData: false,
    }
      

    getRecommendations = () => {
        this.setState({fetchingData: true});
        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);
        

        const { 
            meta: {
                followers = [],
                following = []
            },
            userId
        } = this.props;

        let connections = followers.concat(following);
        connections = [...new Set([...followers,...following])];
        
        connections.forEach(connection => {
            const docRef = db.collection("user_meta").doc(connection);
            docRef.get().then((doc) => {
                if (doc.exists) {
                    const { following = [] } = doc.data();
                    if(following.length){
                        const clensFollowing = following.filter(id => {
                            const alreadyInConnections = connections.some(alCon => alCon === id);
                            return id !== userId && !alreadyInConnections;
                        });
                        const connectionResults = [...new Set([...this.state.recommendations,...clensFollowing])];
                        connectionResults.forEach(conn => {
                            
                            const userRef = db.collection("user_meta").doc(conn);
                            userRef.get().then((userDoc) => {
                                const alreadyInRecs = this.state.recommendations.some(rec => conn === rec.userId);
                                if(userDoc.exists && !alreadyInRecs){
                                    const currentRecs = [...this.state.recommendations];
                                    if(currentRecs.length >= 10) {
                                        return;
                                    }

                                    const storage = firebase.storage();
                                    const storageRef = storage.ref();
                                    storageRef.child('userpics/' + conn + '-pp.jpg').getDownloadURL().then((url) => {
                                        const stillAlreadyInRecs = this.state.recommendations.some(rec => conn === rec.userId);
                                        if(!stillAlreadyInRecs) {
                                            this.setState({
                                                recommendations: [...this.state.recommendations, ...[{...userDoc.data(), userId: conn, profilepic: url}]],
                                            });
                                        }
                                    }).catch(error => {
                                        const stillAlreadyInRecs = this.state.recommendations.some(rec => conn === rec.userId);
                                        if(!stillAlreadyInRecs) {
                                            this.setState({
                                                recommendations: [...this.state.recommendations, ...[{...userDoc.data(), userId: conn}]],
                                            });
                                        }
                                    });

                                    
                                }
                            });
                            
                        });
                    }
                }
            }).catch((error) => {
                // console.log("Error getting document:", error);
            });
        });
            
    }

    componentDidMount() {
        this.getRecommendations();
    }


  render() {

    const { recommendations } = this.state;

    if(!recommendations.length) {
        return null;
    }

    // console.log("recommendations: ",recommendations);

    return (
      <>
        <div className="gh-recommended-friends">
            <h3 className="gh-heading gh-heading--h3 gh-heading--left" type="h3" variant="left">Recommended <span className="gh-brand-premium">Friends</span></h3>
        
            <div className="gh-recommended-friends__entries">
                {recommendations.map(rec => {
                    const { userId, handicap, displayname, homecourse, profilepic } = rec;
                    return (
                        <div key={`rec-${userId}`} className="gh-detailsMeta gh-recommended-friends__entries-panel">
                            <ProfilePic 
                                onClick={() => {
                                    this.props.history.push(`/profile/${userId}`);
                                }}
                                displayname={displayname}
                                profilepic={profilepic}
                                className="gh-detailsMeta__avatar" />
                            <Typography className="gh-detailsMeta__name" onClick={() => {
                                    this.props.history.push(`/profile/${userId}`);
                                }}>
                                {displayname} <SecurityIcon className="gh-detailsMeta__name-icon" /> {handicap}
                            </Typography>
                            {homecourse && homecourse !== '' && <Typography className="gh-detailsMeta__metaline">{homecourse}</Typography>}
                            
                        </div>
                    );
                })}
            </div>
            
        
        </div>
      </>
    );
  }
}

RecommendedFriends.propTypes = {
  toggleDrawer: PropTypes.func
};

export default withRouter(RecommendedFriends);