import React, { Component } from 'react';
//import axios from 'axios';
import Pux from '../../hoc/Pux';
import { Link, Redirect } from 'react-router-dom';
import './History.scss';
import PropTypes from 'prop-types';
import firebase from '../../Firebase.js';
import ScreenLoading from '../../components/ScreenLoading/ScreenLoading';
import DateDisplay from '../../components/DateDisplay/DateDisplay';
import Heading from '../../components/Heading/Heading';
import Header from '../../components/Header/Header';
import AppBar from '../../containers/AppBar/AppBar';
import SecurityIcon from '@material-ui/icons/Security';
import PublicIcon from '@material-ui/icons/Public';
import BigSmallValue from '../../components/BigSmallValue/BigSmallValue';
import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import ConfirmOverlay from '../../components/ConfirmOverlay/ConfirmOverlay';
import Typography from '@material-ui/core/Typography';
import { AccordionActions, AccordionDetails, AccordionSummary, Accordion } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import * as actionsCreators from '../../store/actions/index';
import { 
    getPlayingHandicap, 
    getRoundIndex, 
    getContributingRoundsLimit, 
    getWHIndexAdjustments, 
    calculateSlopeRating, 
    calculateCourseRating, 
    calculateAdjustedScore,
    getIndexBasedOnLatestRounds,
    extractIndexData,
    HANDICAP_CONTS,
} from '../../store/actions/handicap';
//import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { LineChart } from 'react-easy-chart';
import { Alert, AlertTitle } from '@material-ui/lab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import SuccessIcon from '@material-ui/icons/CheckCircleOutlined';
import IconButton from '@material-ui/core/IconButton';

const columns = [
    { id: 'course', label: 'Course', minWidth: 200 },
    { id: 'adj', label: 'Adjusted', minWidth: 50, align: 'right', },
    // {
    //   id: 'population',
    //   label: 'Population',
    //   minWidth: 170,
    //   align: 'right',
    //   format: (value) => value.toLocaleString('en-US'),
    // },
    {
      id: 'index',
      label: 'Index',
      minWidth: 75,
      align: 'right',
    },
    {
      id: 'points',
      label: 'Points',
      minWidth: 50,
      align: 'right',
    },
    {
        id: 'date',
        label: 'Date',
        minWidth: 150,
        align: 'right',
      },
  ];
  



class History extends Component {

    state = {
        historyData : [],
        historyDetailsFetched:false,
        confirmDeleteId: null,
        confirmEditRound: null,
        redirect: false,
        maxhistory: 3,
        historyLength:0,
        historyGraphData:[],
        historyIndexData: [],
        historyIndexFetched: false,
        page: 0,
        openSnackBar: false,
        loadingData: false,
        processedRounds: 0,
        historyIndexHandicap: null,
    }

    getHistoryPageData = () => {
        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);
        const roundsRef = db.collection("rounds");

        roundsRef.where("userid","==",this.props.userId)
            //.orderBy('rounddate')
            .get().then((querySnapshot) => {
                //console.log("querySnapshot: ",querySnapshot.size);
                let fetchedData = querySnapshot.docs.map(function (documentSnapshot) {
                    return {
                        ...documentSnapshot.data(),
                        roundid:documentSnapshot.id//merge in id to obj
                    };
                });
                if(fetchedData){
                    //sort fetched before storing:
                    fetchedData.sort(function(a,b){
                        return b.rounddate.toDate() - a.rounddate.toDate();
                    });
                    const graphData = this.extractGraphData(fetchedData);
                    const indexData = extractIndexData(fetchedData);
                    const contributingOrderedIndexedRounds = [...indexData];
                    contributingOrderedIndexedRounds.sort((a, b) => (a.index > b.index) ? 1 : -1);
                    const contributingRoundsLimit = getContributingRoundsLimit(indexData.length);
                    const contributingLimitedRounds = contributingOrderedIndexedRounds.filter((round,i) => i < contributingRoundsLimit);
                    const whIndexAdjustments = getWHIndexAdjustments(indexData.length)
                    const whIndexBasedOnLatestRounds = getIndexBasedOnLatestRounds(contributingLimitedRounds, whIndexAdjustments);
                    
                    const historyIndexHandicap = {
                        whIndexBasedOnLatestRounds,
                        contributingLimitedRounds,
                        whIndexAdjustments
                    }
                    
                    this.setState({
                        historyData:fetchedData,
                        historyDetailsFetched:true,
                        historyLength:querySnapshot.size,
                        historyGraphData:graphData,
                        historyIndexData: indexData,
                        historyIndexHandicap,
                        historyIndexFetched: true,
                    });
                } else {
                    this.setState({historyData:null,historyDetailsFetched:true,historyLength:0});
                }
            });
    }

    componentDidMount () {

        document.title = 'Golf Handicap - Your History';

        if(!this.props.userId){
            return;
        }

        this.getHistoryPageData();
        
    }

    addWHSData = rounds => {
        return [...rounds].map(round => {
            const { roundsss = 72, handicap = 0, scores } = round;
            const adjustedGross = calculateAdjustedScore(scores, handicap);
            const sloperating = calculateSlopeRating();
            const courserating = calculateCourseRating(roundsss)
            const updatedRound = {
                ...round,
                system: 'whs',
                sloperating,
                courserating,
                recordedHandicapIndex: handicap,
                recordedPlayingHandicap : getPlayingHandicap(handicap),
                roundIndex: getRoundIndex(adjustedGross, courserating, sloperating),
                adjustedGross,
                convertedFromSSS: true,
            }
            this.updateRoundInDB(updatedRound, () => this.checkForFinished(rounds.length));
        });
    }

    checkForFinished = targetLength => {
        
        this.setState((prevState) => ({
            processedRounds: prevState.processedRounds + 1
        }), () => {
            if(this.state.processedRounds === targetLength) {
                this.setState({ loadingData:false }, () => {
                    this.props.setConvertSSStoWHSRan(this.props.userId);
                    this.getHistoryPageData();
                });
            }
        });
          
        
    }

    updateRoundInDB = async (round, callback) => {
        const db = firebase.firestore();
        const settings = {};
        await db.collection("rounds").doc(round.roundid).update(round)
        .then(() => {
            callback();
        }).catch(err => {
            callback();
        });
    }

    convertSSStoWHS = () => {
        // get last 20 18 hole rounds (unprocessed)
        const roundsToConvert = this.state.historyData.filter(round => {
            const { system, convertedFromSSS, roundtype } = round;
            return system !== 'whs' && !convertedFromSSS && roundtype !== '9hole';
        }).filter((r,i) => i < HANDICAP_CONTS.maxIndexHistory);

        if(roundsToConvert.length) {
            this.setState({ loadingData:true }, () => {
                // loop through and workout missing data and update round
                this.addWHSData(roundsToConvert);
            });
        }
    }

    extractGraphData = (data) => {
        let graphData = data.filter(round => {
            const lastYear = new Date();
            const pastYear = lastYear.getFullYear() - 1;
            lastYear.setFullYear(pastYear);
            return round.rounddate.seconds > (lastYear.getTime() / 1000);
        }).map((round) => {
            const roundDate = new Date(round.rounddate.seconds * 1000);
            const dateString = roundDate.getDate() + '-' + roundDate.toLocaleString('en-us', { month: 'short' }) + '-' + (roundDate.getYear() - 100);
            const handicapToUse = round.recordedHandicapIndex || round.newHandicap;

            return {
                x: dateString,
                y: handicapToUse
            };
        });
        return [graphData];
    }

    editRoundHandler = (round) => {
        this.setState({confirmEditRound:round});
    }

    deleteRoundHandler = (id) => {
        this.setState({confirmDeleteId:id});
    }

    closeDeleteOverlay = (id) => {
        this.setState({confirmDeleteId:null});
    }
    closeEditOverlay = (id) => {
        this.setState({confirmEditId:null});
    }

    deleteRound = (id) => {
        
        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);

        db.collection("rounds").doc(id).delete().then(() => {

            const mutatedState = {...this.state};
            let mutatedHistory = mutatedState.historyData.filter(function(round){
                return round.roundid !== id;
            });
           
            mutatedState.historyData = mutatedHistory;
            mutatedState.confirmDeleteId = null;
            this.setState(mutatedState, () => {
                this.getHistoryPageData();
            });
        }).catch((error) => {
            //console.error("Error removing document: ", error);
            this.setState({confirmDeleteId:null});
        });
        
    }

    editRoundConfirm = (round) => {
        this.props.editRound(round);
        this.setState({redirect:true});
    }

    showAll = () => {
        const cachedState = {...this.state}
        this.setState({maxhistory:cachedState.historyLength});
    }

    render() {

        let redirect = null;
        if(this.state.redirect){
            redirect = <Redirect to={{
                pathname: "/addroundscores"
              }}  />;
        };
        let showShowMoreButton = false;
        if(this.state.historyLength > 0 && 
            this.state.historyLength !== this.state.maxhistory && 
            this.state.historyLength > this.state.maxhistory){
            showShowMoreButton = true;
        }

        const whsMode = this.props.userMeta && this.props.userMeta.whindex && this.props.userMeta.whmode;
        const whindex = whsMode ? this.props.userMeta.whindex : '?';
        const needsMoreIndexedRounds = this.state.historyIndexData.length < HANDICAP_CONTS.maxIndexHistory;
        const sssRounds = this.state.historyLength - this.state.historyIndexData.length;
        const hasRoundsToTryAndConvert = sssRounds > 0;

        // in whsmode, has less than 20 whs rounds, has unprocessed sss rounds
        const showConvertMessagebox = whsMode && needsMoreIndexedRounds && hasRoundsToTryAndConvert && !this.props.userMeta.convertSSStoWHSRan;

        return (
            <Pux>
                <Header />
                {redirect}
                {!this.state.loadingData && this.state.historyDetailsFetched && this.props.userMeta ? 
                    <Pux>
                        {this.state.historyData ? 
                            <div className="gh-content">

                                <Heading type="h3" variant="left">
                                    Your History
                                    {whsMode && (
                                        <>
                                            <BigSmallValue
                                                value={whindex}
                                                label="WHI"
                                                className="gh-heading__bigsmall"
                                                icon={
                                                    <PublicIcon />
                                                }
                                                />
                                        </>
                                    )}
                                    {!whsMode && this.props.userMeta.handicap ? 
                                        <BigSmallValue
                                            value={this.props.userMeta.handicap}
                                            label="HCP"
                                            className="gh-heading__bigsmall"
                                            icon={
                                                <SecurityIcon />
                                            }
                                            />
                                    : null}
                                </Heading>

                                {Boolean(showConvertMessagebox) && (
                                    <Alert
                                        severity="warning"
                                        action={
                                            <Button color="inherit" size="small" onClick={() => {
                                                this.convertSSStoWHS();
                                            }}>
                                                Convert
                                            </Button>
                                        }
                                    >
                                        We notice you have some old rounds that are entered under the old system, would you like us to try and convert them into world handicap index rounds?
                                    </Alert>
                                )}

                                {!showConvertMessagebox && 
                                    whsMode &&
                                    this.state.historyIndexData && 
                                    this.state.historyIndexData.length >= HANDICAP_CONTS.minIndexHistory &&
                                    this.state.historyIndexHandicap &&
                                    this.state.historyIndexHandicap.whIndexBasedOnLatestRounds && (
                                    <Alert
                                        severity="success"
                                        icon={<PublicIcon fontSize="inherit" />}
                                    >
                                        <AlertTitle>Your World Handicap Index is {this.state.historyIndexHandicap.whIndexBasedOnLatestRounds}</AlertTitle>
                                            This may differ from  your saved handicap index if you have manually changed it or removed any previous rounds.
                                        
                                    </Alert>
                                )}

                                {!showConvertMessagebox && 
                                    whsMode &&
                                    this.state.historyIndexData && 
                                    this.state.historyIndexData.length < HANDICAP_CONTS.minIndexHistory && (
                                    <Alert
                                        severity="error"
                                    >
                                        <AlertTitle>We need some data</AlertTitle>
                                        You need to add at least 3 rounds for us to calculate your handicap index.
                                    </Alert>
                                )}

                                {this.state.historyIndexFetched && Boolean(this.state.historyIndexData.length) && (
                                    <>
                                        <Heading type="h4" variant="left">Indexed Rounds (Last {this.state.historyIndexData.length})</Heading>
                                        
                                        <TableContainer className="gh-scrolltable">
                                            <Table stickyHeader size="small" aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        {columns.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth }}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                {this.state.historyIndexData.map((row,i) => {
                                                    const { historyIndexHandicap: { contributingLimitedRounds = null } = {} } = this.state;
                                                    let isContributingRound = false;
                                                    if(contributingLimitedRounds) {
                                                        isContributingRound = contributingLimitedRounds.find(round => round.id === row.id);
                                                    }

                            
                                                    
                                                    return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={`${row.course}-${i}`}>
                                                        {columns.map((column) => {
                                                        const value = row[column.id];
                                                        return (
                                                            <TableCell className={isContributingRound ? 'gh__table-cell gh__table-cell--diff' : 'gh__table-cell'} key={column.id} align={column.align}>
                                                                {typeof value === 'object' && <DateDisplay date={value} />}
                                                                {column.format && typeof value === 'number' && column.format(value)}
                                                                {!column.format && typeof value !== 'object' && value}
                                                            </TableCell>
                                                        );
                                                        })}
                                                    </TableRow>
                                                    );
                                                })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </>
                                )}

                                {this.state.historyData.length ? 
                                    <div className="gh-expand">
                                        <Heading type="h4" variant="left">All Rounds</Heading>
                                        {this.state.historyData.map(round => {
                                            const diff = parseFloat(round.newHandicap - round.handicap).toFixed(1);
                                            const displayDiff = (diff > 0) ? '+' + diff : diff;
                                            let totalPar = 0;
                                            let totalScore = 0;
                                            for(let a = 0; a < round.scores.length; a++){
                                                //max of 2 initials
                                                const holeData = Object.values(round.scores[a]);
                                                
                                                const [holePar, holeScore] = holeData[0];
                                                if(!holePar || !holeScore){
                                                    continue;
                                                }
                                                totalPar += holePar;
                                                totalScore += holeScore;
                                            }
                                            return (
                                                <Accordion className="gh-expand__panel" key={round.roundid}>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <Typography className="gh-flex-p">{round.coursename} <br/><DateDisplay date={round.rounddate} /> </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails className="gh-expand__details">
                                                        <Grid className="" container spacing={0}>
                                                            <Grid item xs={3}>
                                                                <BigSmallValue
                                                                    value={totalPar}
                                                                    label="PAR"
                                                                    />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <BigSmallValue
                                                                    value={round.points}
                                                                    label="POINTS"
                                                                    />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <BigSmallValue
                                                                    value={totalScore}
                                                                    label="SHOTS"
                                                                    />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <BigSmallValue
                                                                    value={(round.topar > 0) ? '+' + round.topar : round.topar}
                                                                    label="TO PAR"
                                                                    />
                                                            </Grid>
                                                        </Grid>

                                                        {round.system === 'whs' && (
                                                            <div className="gh-history-chip">
                                                                <Chip
                                                                    icon={<PublicIcon />}
                                                                    label={"Handicap Index: " + round.recordedHandicapIndex + " (" + round.recordedPlayingHandicap + ")"}
                                                                    color="primary"
                                                                />
                                                            </div>
                                                        )}

                                                        {round.system !== 'whs' && (
                                                            <div className="gh-history-chip">
                                                                <Chip
                                                                    icon={<SecurityIcon />}
                                                                    label={"Handicap: " + round.newHandicap + " (" + displayDiff + ")"}
                                                                    color="primary"
                                                                />
                                                            </div>
                                                        )}
                                                    </AccordionDetails>
                                                    <Divider />
                                                    <AccordionActions>
                                                         
                                                        {this.props.userMeta && !this.props.userMeta.locked ?
                                                            <Button 
                                                                size="small" 
                                                                onClick={() => {
                                                                    this.deleteRoundHandler(round.roundid);
                                                                }} 
                                                                variant="outlined" 
                                                                color="secondary">Delete</Button> : null}
                                                            
                                                        {this.props.userMeta && !this.props.userMeta.locked ? <Button 
                                                            size="small" 
                                                            onClick={() => {
                                                                this.editRoundHandler(round);
                                                            }} 
                                                            variant="outlined" 
                                                            color="secondary">Edit</Button> : null}
                                                       
                                                       
                                                        <Button 
                                                            component={Link} 
                                                            to={"/round/"+round.roundid} 
                                                            size="small" 
                                                            variant="outlined" 
                                                            color="primary" >
                                                            View
                                                        </Button>
                                                    </AccordionActions>
                                                </Accordion>
                                            );
                                        }).filter((round,i) => {
                                            return (i+1) <= this.state.maxhistory;
                                        })}
                                        {this.state.confirmDeleteId ? 
                                            <ConfirmOverlay 
                                                closeOverlay={this.closeDeleteOverlay}
                                                open={this.state.confirmDeleteId !== null}
                                                title="Delete Round"
                                                description="You are about to delete this round, are you sure you want to do this?"
                                                confirmOverlay={() => {
                                                    this.deleteRound(this.state.confirmDeleteId);
                                                }} /> 
                                        : null }
                                        {this.state.confirmEditRound ? 
                                            <ConfirmOverlay 
                                                closeOverlay={this.closeEditOverlay}
                                                open={this.state.confirmEditId !== null}
                                                title="Edit Round"
                                                description="You are about to edit this round, are you sure you want to do this?"
                                                confirmOverlay={() => {
                                                    this.editRoundConfirm(this.state.confirmEditRound);
                                                }} /> 
                                        : null }
                                        {showShowMoreButton ?
                                        <Typography align="center" className="gh-seemore">
                                            <Badge color="primary" badgeContent={this.state.historyLength - this.state.maxhistory} className="gh-seemore__badge">
                                                <Button onClick={this.showAll} variant="outlined">See All</Button>
                                            </Badge>
                                        </Typography>
                                        : null}
                                        {this.state.historyGraphData ? 
                                            <Pux>
                                                <Heading type="h3" variant="left">
                                                    Handicap Tracker
                                                </Heading>
                                                <div className="gh-graphs">
                                                    <LineChart 
                                                        xType={'time'}
                                                        margin={{top: 8, right: 8, bottom: 60, left: 42}}
                                                        axes
                                                        dataPoints
                                                        tickTimeDisplayFormat={'%d %b %y'}
                                                        grid
                                                        width={350}
                                                        height={250}
                                                        xTicks={5}
                                                        yTicks={5}
                                                        interpolate={'cardinal'}
                                                        data={this.state.historyGraphData}
                                                    />
                                                </div>
                                            </Pux>
                                        : null}
                                    </div>
                                : (
                                    <>
                                        <Divider style={{marginTop: '12px', marginBottom: '12px'}} />
                                        <Typography>No History exists yet, please add your first round.</Typography>
                                    </>
                                )}
                            </div>
                        : <div className="gh-content"><Typography>Oops something went wrong.</Typography></div>}

                            <Snackbar
                                className="gh-snackBar gh-snackBar--success"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                open={this.state.openSnackBar}
                                autoHideDuration={6000}
                                onClose={() => {
                                    this.setState({openSnackBar:false});
                                }}
                                ContentProps={{
                                    'aria-describedby': 'message-id',
                                }}
                                message={
                                    <>
                                        <span id="message-id" className="gh-snackBar__message">
                                            <SuccessIcon className="gh-snackBar__message-svg" />
                                            Great News! We've converted all the latest rounds we've found and attempted to get you the most accurate handicap index, as you continue to add rounds this will become more accurate.
                                        </span>
                                    </>
                                }
                                action={[
                                    <IconButton
                                        key="close"
                                        aria-label="Close"
                                        color="inherit"
                                        onClick={() => {
                                            this.setState({openSnackBar:false});
                                        }}
                                        >
                                        <CloseIcon />
                                    </IconButton>
                                ]}
                                />
                    </Pux>
                : <ScreenLoading />}
                <AppBar selected="home" />
            </Pux>
        );
    }
}

History.propTypes = {
   historyData: PropTypes.object
}

const mapStateToProps = state => {
    return {
        userId: state.auth.userId,
        userMeta: state.auth.userMeta,
        redirect: state.round.roundid != null
    }
}

const mapDispatchToProps = dispatch =>  {
    return {
        editRound: (round) => dispatch(actionsCreators.editRound(round)),
        setConvertSSStoWHSRan: (userId) => dispatch(actionsCreators.setConvertSSStoWHSRan(userId)),
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(History);
