import React from 'react';
import './ScoreCard.scss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Pux from '../../hoc/Pux';

const netClassMatrix = {
    '-3' : 'albatros',
    '-2' : 'eagle',
    '-1' : 'birdie',
    '0' : 'par',
    '1' : 'bogie',
    '2' : 'dbogie',
    'over' : 'tbogie'
}

const ScoreCard = (props) => {
    let holeData = (props.type === 'preview') ? Object.values(props.carddata.card) : props.carddata;
    if(props.type === 'tees') {
        holeData = Object.values(props.carddata.meta.holedata);
    }
    let totalPar = 0;
    let totalScore = 0;
    let totalYards = 0;

    // Object.values(holeData[10])[0].length < 1
    const isNine = props.type !== 'preview' ? holeData && holeData[10][11] && !holeData[10][11].length : holeData && !holeData[10].length;

    return (
        <div className="gh-scorecard">
            <Table className="gh-scorecard__table">
                <TableBody>
                    <TableRow className="gh-scorecard__row">
                        <TableCell className="gh-scorecard__cell" component="th" scope="row">
                            Hole
                        </TableCell>
                        {holeData
                            .filter((hole,i) => {
                                return !isNine || i < 9;
                            })
                            .map((hole,i) => {
                            
                            const holeNumber = i+1;
                            if(props.type === 'scores'){
                                hole = Object.values(hole)[0];
                            }
                            return (
                                <Pux key={i}>
                                    <TableCell className="gh-scorecard__cell" >{holeNumber}</TableCell>
                                </Pux>
                            );
                        })}
                        {props.type === 'scores' ? 
                            <TableCell className="gh-scorecard__cell gh-scorecard__cell--totals" ></TableCell>
                        : null}
                    </TableRow>


                    <TableRow className="gh-scorecard__row">
                        <TableCell className="gh-scorecard__cell" component="th" scope="row">
                            Par
                        </TableCell>
                        {holeData.filter((hole,i) => {
                                return !isNine || i < 9;
                            })
                            .map((hole,i) => {
                            if(props.type === 'scores'){
                                hole = Object.values(hole)[0];
                            }
                            const [holePar, , ] = hole;
                            totalPar += holePar;
                            return (
                                <Pux key={i}>
                                    <TableCell className="gh-scorecard__cell" >{holePar}</TableCell>
                                </Pux>
                            );
                        })}
                        {props.type === 'scores' ? 
                            <TableCell className="gh-scorecard__cell gh-scorecard__cell--totals" >{totalPar}</TableCell>
                        : null}
                    </TableRow>
                    <TableRow className="gh-scorecard__row">
                        <TableCell className="gh-scorecard__cell" component="th" scope="row">
                            S.I
                        </TableCell>
                        {holeData.filter((hole,i) => {
                                return !isNine || i < 9;
                            })
                            .map((hole,i) => {
                            if(props.type === 'scores'){
                                hole = Object.values(hole)[0];
                            }
                            const [, , holeStroke] = hole;
                            return (
                                <Pux key={i}>
                                    <TableCell className="gh-scorecard__cell" >{holeStroke}</TableCell>
                                </Pux>
                            );
                        })}
                        {props.type === 'scores' ? 
                            <TableCell className="gh-scorecard__cell gh-scorecard__cell--totals" ></TableCell>
                        : null}
                    </TableRow>
                    {props.hasYards ? 
                        <TableRow className="gh-scorecard__row">
                            <TableCell className="gh-scorecard__cell" component="th" scope="row">
                                Yards
                            </TableCell>
                            {holeData.filter((hole,i) => {
                                return !isNine || i < 9;
                            })
                            .map((hole,i) => {
                                if(props.type === 'scores'){
                                    hole = Object.values(hole)[0];
                                }
                                const [, , , holeYards] = hole;
                                totalYards += holeYards;
                                return (
                                    <Pux key={i}>
                                        <TableCell className="gh-scorecard__cell" >{holeYards}</TableCell>
                                    </Pux>
                                );
                            })}
                            {props.type === 'scores' ? 
                                <TableCell className="gh-scorecard__cell gh-scorecard__cell--totals" >{totalYards}</TableCell>
                            : null}
                        </TableRow>
                    : null}
                    {props.type === 'scores' ? 
                        <TableRow className="gh-scorecard__row">
                            <TableCell className="gh-scorecard__cell" component="th" scope="row">
                                Score
                            </TableCell>
                            {holeData.filter((hole,i) => {
                                return !isNine || i < 9;
                            })
                            .map((hole,i) => {
                                if(props.type === 'scores'){
                                    hole = Object.values(hole)[0];
                                }
                                const [holePar, holeScore, , ] = hole;
                                totalScore += holeScore;
                                let diff = (holeScore - holePar);
                                if(diff > 2) {
                                    diff = 'over';
                                }
                                const netClassName = netClassMatrix[diff];
                                return (
                                    <Pux key={i}>
                                        <TableCell className="gh-scorecard__cell gh-scorecard__cell--score" ><span className={"gh-scorecard__cell-score gh-scorecard__cell-score--"+netClassName}>{holeScore}</span></TableCell>
                                    </Pux>
                                );
                            })}
                            <TableCell className="gh-scorecard__cell gh-scorecard__cell--totals gh-scorecard__cell--emp" >{totalScore}</TableCell>
                        </TableRow>
                    : null}
                </TableBody>
            </Table>
        </div>
    );
};
export default ScoreCard;