import React, {Component} from 'react';
import Pux from '../Pux';
import './Layout.scss';

class Layout extends Component {

    render() {
        return (
            <Pux>
                {React.cloneElement(this.props.children, { pageTitleBase: this.props.pageTitleBase })}
            </Pux>
        );
    }
};

export default Layout;