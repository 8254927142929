import React, { Component } from 'react';
import Header from '../../components/Header/Header';
import AppBar from '../AppBar/AppBar';
//import PropTypes from 'prop-types';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Pux from '../../hoc/Pux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PayPalImg from '../../assets/images/paypal.png';

  
export class Thankyou extends Component {

    state = {
        content:null,
        title:null,
        native: false
    }
    

    componentDidMount() {
        
        if(typeof globalNative !== 'undefined'){
            this.setState({native:true});
        }

    }

    componentWillMount() {
        document.title = 'Golf Handicap - A huge Thank you!';
    }


    render() {

        
        


        return (
            <Pux>
                <Header noads={true} loggedOut={!this.props.isAuthenticated} />
                
                <div className="gh-content gh-content--contentful">

                    

                    <h1 className="gh-heading gh-heading--h1">Thank you <span className="gh-brand-premium">so much</span></h1>

                    <p>What you have just done is HUGELY appreciated here at Golf Handicapp, you are an amazing person to volentarily donate and this will go a long way towards keeping the app up to date and hopefully some new features, again, you are AMAZING. </p>

                    

                    
                    
                    
                            
                       
                </div>
                {this.props.isAuthenticated ? <AppBar /> : null}
            </Pux>
        );
    }
}


const mapStateToProps = state => {
    return {
      isAuthenticated:state.auth.token !== null,
      userMeta: state.auth.userMeta,
    }
}
  
export default withRouter(connect(mapStateToProps)(Thankyou));
