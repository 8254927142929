import React, {Component} from 'react';
import './UserMeta.scss';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import ProfilePic from '../../components/ProfilePic/ProfilePic';
import Pux from '../../hoc/Pux';
import BigSmallValue from '../../components/BigSmallValue/BigSmallValue';
import SecurityIcon from '@material-ui/icons/Security';
import PublicIcon from '@material-ui/icons/Public';



class UserMeta extends Component {

    state = {
        
    }

    render() {

        let userMetaSnippet = null;
        if(this.props.userMeta ) {
            
            let avatarHandicap = '?';
            if(this.props.userMeta.handicap){
                avatarHandicap = this.props.userMeta.handicap;
            }
            const whsMode = this.props.userMeta && this.props.userMeta.whindex && this.props.userMeta.whmode;
            const whindex = whsMode ? this.props.userMeta.whindex : '?';
            

            userMetaSnippet = (
                <Pux>
                    <ProfilePic 
                        premium={this.props.userMeta && this.props.userMeta.role === 'premium'}
                        displayname={this.props.userMeta.displayname}
                        profilepic={this.props.userMeta.profilepic}
                        className="gh-usermeta__avatar" />
                    <Typography className="gh-usermeta__name">{this.props.userMeta.displayname || ''}</Typography>
                    <Typography className="gh-usermeta__name gh-usermeta__name--sub">{this.props.userMeta.homecourse || ''}</Typography>
                    {whsMode ? (
                        <>
                            <BigSmallValue
                                value={whindex}
                                label="WHI"
                                className="gh-usermeta__bigsmall"
                                icon={
                                    <PublicIcon />
                                }
                                />
                        </>
                    ) : (
                        <>
                            <BigSmallValue
                                value={avatarHandicap}
                                label="HCP"
                                className="gh-usermeta__bigsmall"
                                icon={
                                    <SecurityIcon />
                                }
                                />
                        </>
                    )}
                    
                </Pux>
            );
        }

        return (
            <div className="gh-usermeta">
                {userMetaSnippet}
            </div>
        );
    }
};
const mapStateToProps = state => {
    return {
        userMeta: state.auth.userMeta,
        auth: state.auth
    }
}

export default connect(mapStateToProps)(UserMeta);