import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pux from '../../hoc/Pux';
import decodeHtml from '../../utils/utils';
import { Link } from 'react-router-dom';
import './SearchField.scss';
import LocationIcon from '@material-ui/icons/LocationOn';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import firebase from '../../Firebase.js';

class SearchField extends Component {

    state = {
        courses: [],
        showResults: false,
        fetchingData: false,
        fetchTimout: null,
        hoveredResultIndex: -1,
        searchesmade : 0
    }  

    getCourses = (term) => {
        this.setState({fetchingData: true});
        const db = firebase.firestore();
        db.settings({
            
        });
        const ref = db.collection("courses");
        let results = [];
        // console.log("whsMode: ", this.props.whsMode);
        ref.where("searchTitle", ">=", term)
            .limit(5)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((course) => {
                    results.push(course.data());
                });
                if(results.length){
                    this.setState({
                        courses:results,
                        fetchingData: false,
                        showResults:true
                    });
                } else {
                    this.setState({
                        courses:[],
                        showResults:true,
                        fetchingData: false,
                        hoveredResultIndex: -1
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    courses:[],
                    showResults:false,
                    fetchingData: false,
                    hoveredResultIndex: -1
                });
            });
            
    }

    searchTypeHandler = (event) => {
        const term = event.target.value;
        const keyCode = event.keyCode;

        if(keyCode === 38){
            this.selectNextResultUp();
            return;
        } else if(keyCode === 40) {
            this.selectNextResultDown();
            return;
        } else if(keyCode === 13) {
            if(this.state.hoveredResultIndex >= 0 && this.state.courses.length > 0){
                if(this.props.returnHitHandler){
                    const resultData = this.state.courses[this.state.hoveredResultIndex];
                    this.props.returnHitHandler(resultData.title,resultData.id);
                }
            }
            return;
        }

        if(term.length > 2) {
            //set timeout so doesn't call multiples
            if(this.state.fetchTimout){
                clearTimeout(this.state.fetchTimout);
            }
            this.setState({
                fetchTimout:setTimeout(() => {
                    this.getCourses(term.toLowerCase());
                },500)
            });
        } else {
            this.closeResults();
        }
    }

    selectNextResultUp = () => {
        const currentState = {...this.state};
        const newIndex = currentState.hoveredResultIndex-1;
        if(newIndex < 0){
            return;
        }
        currentState.hoveredResultIndex = newIndex;
        this.setState(currentState);
    }

    selectNextResultDown = () => {
        const currentState = {...this.state};
        const newIndex = currentState.hoveredResultIndex+1;
        if(newIndex >= currentState.courses.length){
            return;
        }
        currentState.hoveredResultIndex = newIndex;
        this.setState(currentState);
    }

    closeResults = () => this.setState({
        courses:[],
        showResults:false,
        hoveredResultIndex: -1
    });

    resultHovered = (i) => {
        this.setState({hoveredResultIndex:i});
    }

    formSubmission = (event) => {
        //stop sumission
        event.preventDefault();
        event.stopPropagation();
        return false;
    }

    handleClickAway = () => {
        this.setState({
          showResults: false
        });
    };


  render() {
    
    const notFoundCourselink = (
        <li className="gh-searchfield__results-listitem gh-searchfield__results-listitem--requestcourse">
            <ListItem 
                component={Link}
                className="gh-searchfield__results-listlink"
                to={{
                    pathname : 'requestnewcourse'
                }}
                >
                <ListItemText
                    primary="Request New Course"
                />
            </ListItem>
        </li>
    );

    let courses = null;
    if(!this.state.courses.length){
        courses = (
            <ListItem 
                className="gh-searchfield__results-listitem gh-searchfield__results-listitem--noresults" 
                >
                <ListItemText
                    className="gh-searchfield__results-listlink"
                    primary="No Results Found"
                />
            </ListItem>
        );
    } else {
        courses = this.state.courses.map((course,i) => {
            
            let usable = true;
            if(!course.title) {
                usable = false;
            }
    
            let locationSnippet = course.country;
            if(course.county.length){
                locationSnippet = course.county + ', ' + locationSnippet;
            }
            
            return (
                (!usable) ? null : 
                <ListItem 
                    onMouseEnter={li => {
                        this.resultHovered(i)
                    }} 
                    onMouseLeave={li => {
                        this.resultHovered(-1)
                    }} 
                    className="gh-searchfield__results-listitem" 
                    key={course.id}
                    onClick={() => {
                        this.props.onCourseSelect(course.id,decodeHtml(course.title),course.scorecards,course.tees);
                        this.setState({
                            courses:[],
                            showResults:false,
                            fetchingData: false,
                            hoveredResultIndex: -1
                        });
                    }}
                    >
                    <ListItemText
                        to={{
                            pathname : 'course/'+course.slug+'/'+course.id
                        }}
                        className={(i === this.state.hoveredResultIndex) ? 'gh-searchfield__results-listlink gh-searchfield__results-listlink--active' : 'gh-searchfield__results-listlink'}
                        primary={decodeHtml(course.title)}
                        secondary={(
                            <Pux>
                                <LocationIcon className="gh-searchfield__results-icon" />{locationSnippet}
                            </Pux>
                        )}
                    />
                </ListItem>
            )
        });
    }
    
    const courseslist = (this.state.showResults) ? 
        <ClickAwayListener onClickAway={this.handleClickAway}><List dense={true} className="gh-searchfield__results">{courses}{notFoundCourselink}</List></ClickAwayListener> : null;
    

    return (
      <Pux>
        <FormControl className="gh-searchfield" margin="normal" fullWidth error={!this.props.isValid}>
            <InputLabel htmlFor={this.props.fieldName}>{this.props.fieldLabel}</InputLabel>
            <Input 
                {...this.props.inputProps}
                onKeyUp={this.searchTypeHandler}
                    />

                {courseslist}
            
            {!this.props.isValid ?
                <FormHelperText id={this.props.fieldName + '-error'}>{this.props.error}</FormHelperText>
            : null }
        </FormControl>
      </Pux>
    );
  }
}

SearchField.propTypes = {
  toggleDrawer: PropTypes.func
};

export default SearchField;