import React, { Component } from 'react';
import Header from '../../components/Header/Header';
import AppBar from '../AppBar/AppBar';
//import PropTypes from 'prop-types';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Pux from '../../hoc/Pux';
import Typography from '@material-ui/core/Typography';
import PayPalDonate from '../../components/PayPalDonate/PayPalDonate';

  
export class Cancel extends Component {

    state = {
        content:null,
        title:null,
        native: false
    }
    

    componentDidMount() {
        
        if(typeof globalNative !== 'undefined'){
            this.setState({native:true});
        }

    }

    componentWillMount() {
        document.title = 'Golf Handicap - Donate to Golf Handicapp - Very Kind!';
    }


    render() {

        
        


        return (
            <Pux>
                <Header noads={true} loggedOut={!this.props.isAuthenticated} />
                
                <div className="gh-content gh-content--contentful">

                    

                    <h1 className="gh-heading gh-heading--h1">Cancelled Donation</h1>

                    <Typography>We are sorry if something went wrong with your donation, please try again, if you get any more issues <Link to="/contactus" className="gh-link">please let us know.</Link></Typography>

                    <Typography>
                        <PayPalDonate />
                    </Typography>
                    


                    
                    
                    
                            
                       
                </div>
                {this.props.isAuthenticated ? <AppBar /> : null}
            </Pux>
        );
    }
}


const mapStateToProps = state => {
    return {
      isAuthenticated:state.auth.token !== null,
      userMeta: state.auth.userMeta,
    }
}
  
export default withRouter(connect(mapStateToProps)(Cancel));
