import firebase from '../../Firebase.js';
import * as actionTypes from  './actionsTypes.js';
import { roundRecover } from  './round.js';
import { 
    getContributingRoundsLimit, 
    getWHIndexAdjustments,
    getIndexBasedOnLatestRounds,
    extractIndexData,
    HANDICAP_CONTS,
} from './handicap';

console.log(firebase) // Undefined
console.log(firebase) // Function

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
}

export const authSuccess = (userId) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        userId: userId,
        uid: userId,
        triedAuthentication:true
    };
}

export const authUserMeta = (userMeta,userId) => {
    return dispatch => {
        if(userMeta) {
            const storage = firebase.storage();
            const storageRef = storage.ref();
            storageRef.child('userpics/' + userId + '-pp.jpg').getDownloadURL().then((url) => {
                userMeta.profilepic = url;
                dispatch(authSetUserMeta(userMeta));
            }).catch((error) => {
                //console.log("no image found");
                dispatch(authSetUserMeta(userMeta));
            });
        } else {
            dispatch(authSetUserMeta(userMeta));
        }
    }
}

export const authSetUserMeta = (userMeta) => {
    return {
        type: actionTypes.AUTH_USER_META,
        userMeta: userMeta,
        userMetaFetched: true
    };
}


export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error,
        triedAuthentication:true
    };
}

export const logout = () => {
    firebase.auth().signOut();
    return {
        type: actionTypes.AUTH_LOGOUT,
        triedAuthentication:true
    };
}


export const auth = (email, password, register) => {
    return dispatch => {
        dispatch(authStart());
        if(register) {
            firebase.auth().createUserWithEmailAndPassword(email, password).catch((error) => {
                dispatch(authFail(error));
            });
        } else {
            firebase.auth().signInWithEmailAndPassword(email,password).catch((error) => {
                console.log("error signing...",error);
                dispatch(authFail(error));
            });
        }
    };
}

export const authGetUserMeta = (userId) => {
    return dispatch => {
       
        if(!userId){
            return;
        }
        //need to put get user meta into the cloud functions
        //const getUserMeta = firebase.functions().httpsCallable('getUserMeta');
        //console.log("[getUserMeta]",getUserMeta(userId));
       
        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);
        db.collection("user_meta").doc(userId).get()
            .then((doc) => {
                if (doc.exists) {
                    const cachedData = doc.data();
                    dispatch(authUserMeta(cachedData,userId));
                    dispatch(roundRecover(userId));

                } else {
                    dispatch(authUpdateCreatedTime(userId));
                }
            });
        
    }
}

const authUpdateCreatedTime = (userId) => {
    return dispatch => {

        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);
        db.collection("user_meta").doc(userId).set({
            createdTime: new Date(),
            role:'standard',
            receiveemails: true
        }).then(() => {
            //risk of loop
            dispatch(authGetUserMeta(userId));
        })
    }
};

export const setConvertSSStoWHSRan = userId => {
    return dispatch => {

        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);
        db.collection("user_meta").doc(userId).update({
            convertSSStoWHSRan: true
        }).then(() => {
            //risk of loop
            dispatch(reindexHandicap(userId));
        })
    }
};

export const reindexHandicap = userId => {
    return dispatch => {

        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);

        const roundsRef = db.collection("rounds");
        roundsRef.where("userid","==",userId)
            //.orderBy('rounddate')
            .get().then((querySnapshot) => {
                //console.log("querySnapshot: ",querySnapshot.size);
                let fetchedData = querySnapshot.docs.map(function (documentSnapshot) {
                    return {
                        ...documentSnapshot.data(),
                        roundid:documentSnapshot.id//merge in id to obj
                    };
                });
                let whindex = null;
                if(fetchedData){
                    //sort fetched before storing:
                    fetchedData.sort(function(a,b){
                        return b.rounddate.toDate() - a.rounddate.toDate();
                    });
                    
                    const indexData = extractIndexData(fetchedData);

                    if(indexData.length >= HANDICAP_CONTS.minIndexHistory) {
                        const contributingOrderedIndexedRounds = [...indexData];
                        contributingOrderedIndexedRounds.sort((a, b) => (a.index > b.index) ? 1 : -1);
                        
                        const contributingRoundsLimit = getContributingRoundsLimit(indexData.length);
                        const contributingLimitedRounds = contributingOrderedIndexedRounds.filter((round,i) => i < contributingRoundsLimit);
                        const whIndexAdjustments = getWHIndexAdjustments(indexData.length);
                        
                        const whIndexBasedOnLatestRounds = getIndexBasedOnLatestRounds(contributingLimitedRounds, whIndexAdjustments, indexData.length);
                        whindex = whIndexBasedOnLatestRounds;
                    }
                }

                if(whindex) {
                    db.collection("user_meta").doc(userId).update({
                        whindex
                    }).then(() => {
                        dispatch(authGetUserMeta(userId));
                    })
                }
                

            });


        
       
    }
};

export const authCheckState = () => {
    return dispatch => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                dispatch(authSuccess(user.uid));
                dispatch(authGetUserMeta(user.uid));
            } else {
                dispatch(logout());
            }
        });
    }
}

export const authUpdateMeta = (newMeta) => {
    return {
        type: actionTypes.AUTH_UPDATE_META,
        userMeta:newMeta
    };
}

export const authRefreshMeta = (userId) => {
    return dispatch => {
        
        if(!userId){
            return;
        }

        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);
        db.collection("user_meta").doc(userId).get()
            .then((doc) => {
                if (doc.exists) {
                    dispatch(authUserMeta(doc.data(),userId));
                } 
            });
        
    }
}
