import React, { Component } from 'react';
import { renderEmail } from  '../../utils/emails';
import '../../assets/scss/core.scss';
import Header from '../../components/Header/Header';
import AppBar from '../AppBar/AppBar';
import {validateForm, scrollToRef, validateUnit } from  '../../utils/validation';
//import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Pux from '../../hoc/Pux';
import ScreenLoading from '../../components/ScreenLoading/ScreenLoading';
import Heading from '../../components/Heading/Heading';
import IconButton from '@material-ui/core/IconButton';
import firebase from '../../Firebase';
import Button from '@material-ui/core/Button';
import LinkIcon from '@material-ui/icons/Link';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/MailOutline';
import FlagIcon from '@material-ui/icons/GolfCourse';
import AddressIcon from '@material-ui/icons/LocationOn';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import SuccessIcon from '@material-ui/icons/CheckCircleOutlined';



const styles = theme => ({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      position: 'relative',
      margin: '8px 0'
    },
    fabProgress: {
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    customWidth: {
        width: 200,
    },
    noMaxWidth: {
        maxWidth: 'none',
    },
});


export class AddCourse extends Component {

    state = {
        showSnackBar:false,
        snackBarMessage:'Thanks we have received your request to a new course. We hope to add your course in the next 24-48 hours',
        snackBarType: 'success',
        showPassword: false,
        sssTooltipOpen:false,
        scoreCardCache:false,
        startAdding:false,
        updatedFromStorage:false,
        resetPageOverlay:false,
        formLoading:false,
        form: {
            isValid:true,
            firstError:null,
            fields: {
                courseemail: {
                    value: '', 
                    default: '',
                    valid: true,
                    errorType: null,
                    rules: {
                        emailOptionalFormat : {
                            message: 'Oops, course email address doesn\'t look right.'
                        },
                        maxlength: {
                            message: 'Have you got a shorter course email?',
                            length: 50
                        }
                    }
                },
                phone: {
                    value: '', 
                    default: '',
                    valid: true,
                    errorType: null,
                    rules: {
                        maxlength: {
                            message: 'Have you got a shorter phone?',
                            length: 50
                        }
                    }
                },
                coursename: {
                    value: '', 
                    default: '',
                    valid: true,
                    errorType: null,
                    rules: {
                        notEmpty : {
                            message: 'Required field.'
                        },
                        maxlength: {
                            message: 'Course too long.',
                            length: 100
                        }
                    }
                },
                description: {
                    value: '', 
                    default: '',
                    valid: true,
                    errorType: null,
                    rules: {
                        notEmpty : {
                            message: 'Required field.'
                        },
                        maxlength: {
                            message: 'Description too long.',
                            length: 5000
                        }
                    }
                },
                address1: {
                    value: '', 
                    default: '',
                    valid: true,
                    errorType: null,
                    rules: {
                        notEmpty : {
                            message: 'Required field.'
                        },
                        maxlength: {
                            message: 'Address line 1 too long.',
                            length: 1000
                        }
                    }
                },
                town: {
                    value: '', 
                    default: '',
                    valid: true,
                    errorType: null,
                    rules: {
                        maxlength: {
                            message: 'Town too long.',
                            length: 1000
                        }
                    }
                },
                county: {
                    value: '', 
                    default: '',
                    valid: true,
                    errorType: null,
                    rules: {
                        maxlength: {
                            message: 'Country too long.',
                            length: 1000
                        }
                    }
                },
                postcode: {
                    value: '', 
                    default: '',
                    valid: true,
                    errorType: null,
                    rules: {
                        notEmpty : {
                            message: 'Required field.'
                        },
                        maxlength: {
                            message: 'Postcode too long.',
                            length: 1000
                        }
                    }
                },
                country: {
                    value: 'United Kingdom', 
                    default: '',
                    valid: true,
                    errorType: null,
                    rules: {
                        notEmpty : {
                            message: 'Required field.'
                        },
                        maxlength: {
                            message: 'Country too long.',
                            length: 1000
                        }
                    }
                },
                twitter: {
                    value: '', 
                    default: '',
                    valid: true,
                    errorType: null,
                    rules: {
                        maxlength: {
                            message: 'Twitter too long.',
                            length: 1000
                        }
                    }
                },
                facebook: {
                    value: '', 
                    default: '',
                    valid: true,
                    errorType: null,
                    rules: {
                        maxlength: {
                            message: 'Facebook too long.',
                            length: 1000
                        }
                    }
                },
                website: {
                    value: '', 
                    default: '',
                    valid: true,
                    errorType: null,
                    rules: {
                        maxlength: {
                            message: 'Website too long.',
                            length: 1000
                        }
                    }
                }
            }
        }
    }

    submitHandler = (event) => {
        event.preventDefault();
        const validateFormState = validateForm(this.state.form);
        this.setState({form:validateFormState,snackBarOpen:true}, () => {
            if(this.state.form.isValid){
                this.setState({formLoading:true});
                this.addCourse();
            } else {
                if(this.state.form.firstError){
                    scrollToRef(this.state.form.fields[this.state.form.firstError].ref);
                }
            }
        });
        
    }

    addCourse = () => {
        const courseData = {
            searchTitle: this.state.form.fields.coursename.value.toLowerCase(),
            title: this.state.form.fields.coursename.value,
            addressLine1: this.state.form.fields.address1.value,
            town: this.state.form.fields.town.value,
            county: this.state.form.fields.county.value,
            postcode: this.state.form.fields.postcode.value,
            country: this.state.form.fields.country.value,
            phone: this.state.form.fields.phone.value,
            email: this.state.form.fields.courseemail.value,
            desc: this.state.form.fields.description.value,
            id: '',
            image: '',
            twitter: this.state.form.fields.twitter.value,
            facebook: this.state.form.fields.facebook.value,
            website: this.state.form.fields.website.value,
            reviews: null,
            rounds:'',
            scorecards:'',
            lat: '',
            long: '',
            tees: ''
        }


        //console.log("[course to add...]",courseData);
        //return;

        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);

        db.collection("courses").add(courseData)
        .then((docRef) => {
            
            db.collection("courses").doc(docRef.id).update({
                id:docRef.id,
                updated: new Date(),
                created: new Date()
            });
            console.log("Course Added with ID, updated ID: ", docRef.id);

            this.setState({formLoading:false},() => {
                this.props.history.push("/course/" + docRef.id);
            });
            
        })
        .catch(function(error) {
            console.error("Error adding document: ", error);
        });


    }

    inputChangedHandler = (event) => {
        const inputValue = event.target.value;
        const inputName = event.target.name;
        const updateState = {...this.state};
        updateState.form.fields[inputName].value = inputValue;
        this.setState(updateState);
    }

    blurField = (event) => {
        const fieldName = event.target.name;
        if(validateUnit(fieldName)){
            const updateState = {...this.state};
            updateState.form.fields[fieldName].errorType = false;
            updateState.form.fields[fieldName].valid = true;
            this.setState(updateState);
        }
    }
    
    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        this.setState({snackBarOpen:false});
    };

    handleDateChange = date => {
        //console.log("date: ",date);
        const updateState = {...this.state};
        updateState.form.fields.rounddate.value = date;
        this.setState(updateState);
    };

    handleTooltipClose = () => {
        this.setState({ sssTooltipOpen: false });
    };
    
    handleTooltipOpen = () => {
        this.setState({ sssTooltipOpen: true });
    };

    updateFieldFromProps = (value,fieldname) => {
        const updateState = {...this.state};
        updateState.form.fields[fieldname].value = value;
        this.setState(updateState);
        return value;
    }



    backClick = () => {
        this.props.history.goBack();
    }

    closeSnackBar = () => {
        this.setState({showSnackBar:false});
    }

    componentDidMount() {
        if(this.props.userId !== 'O0q6vgZ3fYdEED6Vw0sLVTXQ6j83') {
            this.props.history.push('/');
        }
    }
   

    render() {

        const { classes } = this.props;
        const buttonClassname = classNames({
        [classes.buttonSuccess]: true,
        });

       


        return (
            <Pux>
                <Header backClick={this.backClick} />
                <div className="gh-content">
                    <Heading type="h1" variant="central">Add New Course</Heading>
                    {this.props.userMeta ? 
                        <Pux>
                            <form className="form" onSubmit={this.submitHandler}>

                               <FormControl margin="normal" fullWidth error={!this.state.form.fields.coursename.valid}>
                                    <InputLabel htmlFor="coursename">Course Name</InputLabel>
                                    <Input 
                                        onChange={this.inputChangedHandler}
                                        className="form__input" 
                                        id="coursename" 
                                        onBlur={this.blurField}
                                        ref={(this.state.form.fields.coursename.ref) ? this.state.form.fields.coursename.ref : null}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <FlagIcon />
                                            </InputAdornment>
                                        }
                                        name="coursename" 
                                        value={this.state.form.fields.coursename.value}
                                        autoComplete="coursename" 
                                         />
                                    {!this.state.form.fields.coursename.valid ?
                                        <FormHelperText id="coursename-error">{this.state.form.fields.coursename.rules[this.state.form.fields.coursename.errorType].message}</FormHelperText>
                                    : null }
                                </FormControl>

                                <FormControl margin="normal" fullWidth error={!this.state.form.fields.description.valid}>
                                    <InputLabel htmlFor="description">Description</InputLabel>
                                    <Input 
                                        onChange={this.inputChangedHandler}
                                        className="form__input" 
                                        id="description" 
                                        onBlur={this.blurField}
                                        ref={(this.state.form.fields.description.ref) ? this.state.form.fields.description.ref : null}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <EditIcon />
                                            </InputAdornment>
                                        }
                                        name="description" 
                                        value={this.state.form.fields.description.value}
                                        autoComplete="description" 
                                         />
                                    {!this.state.form.fields.description.valid ?
                                        <FormHelperText id="description-error">{this.state.form.fields.description.rules[this.state.form.fields.description.errorType].message}</FormHelperText>
                                    : null }
                                </FormControl>
                                
                                
                                <FormControl margin="normal" fullWidth error={!this.state.form.fields.address1.valid}>
                                    <InputLabel htmlFor="address1">Address Line 1</InputLabel>
                                    <Input 
                                        onChange={this.inputChangedHandler}
                                        className="form__input" 
                                        id="address1" 
                                        onBlur={this.blurField}
                                        ref={(this.state.form.fields.address1.ref) ? this.state.form.fields.address1.ref : null}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <AddressIcon />
                                            </InputAdornment>
                                        }
                                        name="address1" 
                                        value={this.state.form.fields.address1.value}
                                        autoComplete="address1" 
                                         />
                                    {!this.state.form.fields.address1.valid ?
                                        <FormHelperText id="address1-error">{this.state.form.fields.address1.rules[this.state.form.fields.address1.errorType].message}</FormHelperText>
                                    : null }
                                </FormControl>

                                <FormControl margin="normal" fullWidth error={!this.state.form.fields.town.valid}>
                                    <InputLabel htmlFor="town">Town</InputLabel>
                                    <Input 
                                        onChange={this.inputChangedHandler}
                                        className="form__input" 
                                        id="town" 
                                        onBlur={this.blurField}
                                        ref={(this.state.form.fields.town.ref) ? this.state.form.fields.town.ref : null}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <AddressIcon />
                                            </InputAdornment>
                                        }
                                        name="town" 
                                        value={this.state.form.fields.town.value}
                                        autoComplete="town" 
                                         />
                                    {!this.state.form.fields.town.valid ?
                                        <FormHelperText id="town-error">{this.state.form.fields.town.rules[this.state.form.fields.town.errorType].message}</FormHelperText>
                                    : null }
                                </FormControl>

                                <FormControl margin="normal" fullWidth error={!this.state.form.fields.county.valid}>
                                    <InputLabel htmlFor="county">County</InputLabel>
                                    <Input 
                                        onChange={this.inputChangedHandler}
                                        className="form__input" 
                                        id="county" 
                                        onBlur={this.blurField}
                                        ref={(this.state.form.fields.county.ref) ? this.state.form.fields.county.ref : null}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <AddressIcon />
                                            </InputAdornment>
                                        }
                                        name="county" 
                                        value={this.state.form.fields.county.value}
                                        autoComplete="county" 
                                         />
                                    {!this.state.form.fields.county.valid ?
                                        <FormHelperText id="county-error">{this.state.form.fields.county.rules[this.state.form.fields.county.errorType].message}</FormHelperText>
                                    : null }
                                </FormControl>

                                <FormControl margin="normal" fullWidth error={!this.state.form.fields.postcode.valid}>
                                    <InputLabel htmlFor="postcode">Postcode</InputLabel>
                                    <Input 
                                        onChange={this.inputChangedHandler}
                                        className="form__input" 
                                        id="postcode" 
                                        onBlur={this.blurField}
                                        ref={(this.state.form.fields.postcode.ref) ? this.state.form.fields.postcode.ref : null}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <AddressIcon />
                                            </InputAdornment>
                                        }
                                        name="postcode" 
                                        value={this.state.form.fields.postcode.value}
                                        autoComplete="postcode" 
                                         />
                                    {!this.state.form.fields.postcode.valid ?
                                        <FormHelperText id="postcode-error">{this.state.form.fields.postcode.rules[this.state.form.fields.postcode.errorType].message}</FormHelperText>
                                    : null }
                                </FormControl>

                                <FormControl margin="normal" fullWidth error={!this.state.form.fields.country.valid}>
                                    <InputLabel htmlFor="country">Country</InputLabel>
                                    <Input 
                                        onChange={this.inputChangedHandler}
                                        className="form__input" 
                                        id="country" 
                                        onBlur={this.blurField}
                                        ref={(this.state.form.fields.country.ref) ? this.state.form.fields.country.ref : null}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <AddressIcon />
                                            </InputAdornment>
                                        }
                                        name="country" 
                                        value={this.state.form.fields.country.value}
                                        autoComplete="country" 
                                         />
                                    {!this.state.form.fields.country.valid ?
                                        <FormHelperText id="country-error">{this.state.form.fields.country.rules[this.state.form.fields.country.errorType].message}</FormHelperText>
                                    : null }
                                </FormControl>

                                <FormControl margin="normal" fullWidth error={!this.state.form.fields.twitter.valid}>
                                    <InputLabel htmlFor="twitter">Twitter</InputLabel>
                                    <Input 
                                        onChange={this.inputChangedHandler}
                                        className="form__input" 
                                        id="twitter" 
                                        onBlur={this.blurField}
                                        ref={(this.state.form.fields.twitter.ref) ? this.state.form.fields.twitter.ref : null}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <LinkIcon />
                                            </InputAdornment>
                                        }
                                        name="twitter" 
                                        value={this.state.form.fields.twitter.value}
                                        autoComplete="twitter" 
                                         />
                                    {!this.state.form.fields.twitter.valid ?
                                        <FormHelperText id="twitter-error">{this.state.form.fields.twitter.rules[this.state.form.fields.twitter.errorType].message}</FormHelperText>
                                    : null }
                                </FormControl>

                                <FormControl margin="normal" fullWidth error={!this.state.form.fields.facebook.valid}>
                                    <InputLabel htmlFor="facebook">Facebook</InputLabel>
                                    <Input 
                                        onChange={this.inputChangedHandler}
                                        className="form__input" 
                                        id="facebook" 
                                        onBlur={this.blurField}
                                        ref={(this.state.form.fields.facebook.ref) ? this.state.form.fields.facebook.ref : null}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <LinkIcon />
                                            </InputAdornment>
                                        }
                                        name="facebook" 
                                        value={this.state.form.fields.facebook.value}
                                        autoComplete="facebook" 
                                         />
                                    {!this.state.form.fields.facebook.valid ?
                                        <FormHelperText id="facebook-error">{this.state.form.fields.facebook.rules[this.state.form.fields.facebook.errorType].message}</FormHelperText>
                                    : null }
                                </FormControl>

                                <FormControl margin="normal" fullWidth error={!this.state.form.fields.website.valid}>
                                    <InputLabel htmlFor="website">Website</InputLabel>
                                    <Input 
                                        onChange={this.inputChangedHandler}
                                        className="form__input" 
                                        id="website" 
                                        onBlur={this.blurField}
                                        ref={(this.state.form.fields.website.ref) ? this.state.form.fields.website.ref : null}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <LinkIcon />
                                            </InputAdornment>
                                        }
                                        name="website" 
                                        value={this.state.form.fields.website.value}
                                        autoComplete="website" 
                                         />
                                    {!this.state.form.fields.website.valid ?
                                        <FormHelperText id="website-error">{this.state.form.fields.website.rules[this.state.form.fields.website.errorType].message}</FormHelperText>
                                    : null }
                                </FormControl>

                                <FormControl margin="normal" className="form__formcontrol" fullWidth error={!this.state.form.fields.courseemail.valid}>
                                    <InputLabel htmlFor="courseemail">Email</InputLabel>
                                    <Input 
                                        onChange={this.inputChangedHandler}
                                        className="form__input" 
                                        id="courseemail" 
                                        ref={(this.state.form.fields.courseemail.ref) ? this.state.form.fields.courseemail.ref : null}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <MailIcon />
                                            </InputAdornment>
                                        }
                                        onBlur={this.blurField}
                                        name="courseemail" 
                                        value={this.state.form.fields.courseemail.value}
                                        autoComplete="courseemail" 
                                         />
                                    {!this.state.form.fields.courseemail.valid ?
                                        <FormHelperText id="courseemail-error">{this.state.form.fields.courseemail.rules[this.state.form.fields.courseemail.errorType].message}</FormHelperText>
                                    : null }
                                </FormControl>

                                <FormControl margin="normal" className="form__formcontrol" fullWidth error={!this.state.form.fields.phone.valid}>
                                    <InputLabel htmlFor="phone">Phone</InputLabel>
                                    <Input 
                                        onChange={this.inputChangedHandler}
                                        className="form__input" 
                                        id="phone" 
                                        ref={(this.state.form.fields.phone.ref) ? this.state.form.fields.phone.ref : null}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <PhoneIcon />
                                            </InputAdornment>
                                        }
                                        onBlur={this.blurField}
                                        name="phone" 
                                        value={this.state.form.fields.phone.value}
                                        autoComplete="phone" 
                                         />
                                    {!this.state.form.fields.phone.valid ?
                                        <FormHelperText id="phone-error">{this.state.form.fields.phone.rules[this.state.form.fields.phone.errorType].message}</FormHelperText>
                                    : null }
                                </FormControl>
                                
                                <FormControl margin="normal" fullWidth>
                                    <div className={classes.wrapper}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            size="large"
                                            variant="contained"
                                            color="primary"
                                            disabled={this.state.formLoading}
                                            className={buttonClassname}
                                        >
                                            Add Course
                                        </Button>
                                        {this.state.formLoading ? <CircularProgress size={24} className={classes.buttonProgress} /> : null}
                                    </div>
                                </FormControl>
                                
                                <Snackbar
                                    className={(this.state.snackBarType === 'success') ? 'gh-snackBar gh-snackBar--success' : 'gh-snackBar'}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    open={this.state.showSnackBar}
                                    autoHideDuration={6000}
                                    onClose={this.closeSnackBar}
                                    ContentProps={{
                                        'aria-describedby': 'message-id',
                                    }}
                                    message={
                                        <span id="message-id" className="gh-snackBar__message">
                                            <SuccessIcon className="gh-snackBar__message-svg" />
                                            {this.state.snackBarMessage}
                                        </span>
                                    }
                                    action={[
                                        <IconButton
                                            key="close"
                                            aria-label="Close"
                                            color="inherit"
                                            onClick={this.closeSnackBar}
                                            >
                                        <CloseIcon />
                                        </IconButton>
                                    ]}
                                    />
                            </form>
                        </Pux>    
                         : <ScreenLoading />}
                </div>
                
            </Pux>
        );
    }
}
/*
RequestCourse.propTypes = {
    searchTypeHandler: PropTypes.func
}*/

const mapStateToProps = state => {
    return {
        userMeta: state.auth.userMeta,
        userId: state.auth.userId
    }
}

const mapDispatchToProps = dispatch =>  {
    return {
        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddCourse));
