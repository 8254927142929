import React, { Component } from 'react';
import './Manifest.scss';
//import PropTypes from 'prop-types';
//import firebase from '../../Firebase.js';
import Logo from '../../components/Logo/Logo';
import { connect } from 'react-redux';


export class Manifest extends Component {

    render() {

        

        return (
            <main className="">

                <Logo bemClass="gh-logo--centralpage" tagline={true} />
            
            </main>
        );
    }
}

export default connect(null,null)(Manifest);
