const textMap = {
    INVALID_PASSWORD: 'Wrong email or password given.',
    EMAIL_NOT_FOUND: 'Wrong email or password given.',
    EMAIL_EXISTS: 'The email already exists.'
}
textMap['authwrongpassword'] = 'Wrong email or password given.';
textMap['authemailalreadyinuse'] = 'The email already exists.';
textMap['"authusernotfound"'] = 'Wrong email or password given.';

const storeText = (key) => {
    console.log("key: ",key);
    key = key.replace(/\//g,"").replace(/-/g,"");
    const message = (textMap[key]) ? textMap[key] : key;
    return message;
}
export default storeText;