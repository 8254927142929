import React from 'react';
import './BigSmallValue.scss';
import Typography from '@material-ui/core/Typography';

const BigSmallValue = (props) => {

    let mainClasses = ['gh-bigsmall'];
    if(props.className){
        mainClasses.push(props.className);
    }

    let bigClasses = ['gh-bigsmall__value'];
    if(props.bigClass){
        bigClasses.push(props.bigClass);
    }

    let smallClasses = ['gh-bigsmall__label'];
    if(props.smallClass){
        smallClasses.push(props.smallClass);
    }

    return (
        <div className={mainClasses.join(' ')}>
            <Typography className={bigClasses.join(' ')}>{props.value}</Typography>
            <Typography className={smallClasses.join(' ')}>{props.icon || null}{props.label}</Typography>
        </div>
    );
};
export default BigSmallValue;