import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Pux from '../../hoc/Pux';
import {validateForm, scrollToRef, validateUnit } from  '../../utils/validation';
import './EditProfile.scss';
import PropTypes from 'prop-types';
import firebase from '../../Firebase';
import ScreenLoading from '../../components/ScreenLoading/ScreenLoading';
import Heading from '../../components/Heading/Heading';
import Header from '../../components/Header/Header';
import AppBar from '../AppBar/AppBar';
import Typography from '@material-ui/core/Typography';
import ProfilePic from '../../components/ProfilePic/ProfilePic';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import FlagIcon from '@material-ui/icons/GolfCourse';
import SecurityIcon from '@material-ui/icons/Security';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import EditIcon from '@material-ui/icons/Edit';
import PublicIcon from '@material-ui/icons/Public';
import CloseIcon from '@material-ui/icons/Close';
import SuccessIcon from '@material-ui/icons/CheckCircleOutlined';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import HelpIcon from '@material-ui/icons/HelpOutline';
import * as actionsCreators from '../../store/actions';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = theme => ({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      position: 'relative',
      margin: '8px 0'
    },
    fabProgress: {
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    customWidth: {
        width: 200,
    },
    noMaxWidth: {
        maxWidth: 'none',
    },
});


class EditProfile extends Component {

    state = {
        profileData : [],
        profileDetailsFetched:false,
        requesting: false,
        imageTooltipOpen:false,
        snackBarOpen: false,
        snackBarSuccess: false,
        snackBarMessage: '',
        newProfileImage:null,
        receiveemails:true,
        whmode: true,
        front9back9layout: false,
        form: {
            isValid:true,
            firstError:null,
            fields: {
                homecourse: {
                    value: '',
                    valid: true,
                    id:null,
                    card:null,
                    errorType: null,
                    rules: {}
                },
                handicap: {
                    value: '',
                    valid: true,
                    errorType: null,
                    rules: {
                        isNumeric : {
                            message: 'Format Error. Handicap should be a numeric value.'
                        },
                        validHandicap: {
                            message: 'Handicap must be less than 54 and greater than +10.'
                        }
                    }
                },
                whindex: {
                    value: '',
                    valid: true,
                    errorType: null,
                    rules: {
                        isNumeric : {
                            message: 'Format Error. Handicap Index should be a numeric value.'
                        },
                        validHandicap: {
                            message: 'Handicap Index must be less than 54 and greater than +10.'
                        }
                    }
                },
                displayname: {
                    value: '',
                    valid: true,
                    errorType: null,
                    rules: {
                        notEmpty : {
                            message: 'Please provide a name.'
                        },
                        minlength: {
                            message: 'Have you got a longer name.',
                            length: 5
                        },
                        maxlength: {
                            message: 'Have you got a shorter name.',
                            length: 20
                        }
                    }
                }
            }
        }
    }

    componentWillMount() {
        document.title = 'Golf Handicap - My Settings';
    }
    

    componentDidMount () {

        
        const profileId = this.props.userId;
        if(!profileId){
            return;
        }
        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);

        const handicapRules = {
            isNumeric : {
                message: 'Format Error. Handicap Index should be a numeric value.'
            },
            validHandicap: {
                message: 'Handicap Index must be less than 54 and greater than +10.'
            }
        }

        const profileDocRef = db.collection("user_meta").doc(profileId);
        profileDocRef.get().then((profiledoc) => {
            if(profiledoc.exists){
                const cachedState = {...this.state}
                cachedState.profileData = profiledoc.data();
                // console.log("profiledoc.data(): ",profiledoc.data());
                //update form values into state
                if(profiledoc.data().handicap){
                    cachedState.form.fields.handicap.value = profiledoc.data().handicap;
                }
                if(profiledoc.data().homecourse){
                    cachedState.form.fields.homecourse.value = profiledoc.data().homecourse;
                }
                if(profiledoc.data().displayname){
                    cachedState.form.fields.displayname.value = profiledoc.data().displayname;
                }
                if(profiledoc.data().whindex){
                    cachedState.form.fields.whindex.value = profiledoc.data().whindex;
                }

                cachedState.whmode = !!profiledoc.data().whmode;

                if(profiledoc.data().front9back9layout){
                    cachedState.front9back9layout = profiledoc.data().front9back9layout;
                } else {
                    cachedState.front9back9layout = false;
                }

                if(cachedState.whmode) {
                    cachedState.form.fields.handicap.rules = {}
                    cachedState.form.fields.whindex.rules = handicapRules;
                } else {
                    cachedState.form.fields.whindex.rules = {};
                    cachedState.form.fields.handicap.rules = handicapRules;
                }
                

                //check for profile pic
                const storage = firebase.storage();
                const storageRef = storage.ref();
                storageRef.child('userpics/' + profileId + '-pp.jpg').getDownloadURL().then((url) => {
                    cachedState.profileData.profilepic = url;
                    cachedState.profileDetailsFetched = true;
                    this.setState(cachedState);
                }).catch((error) => {
                    cachedState.profileDetailsFetched = true;
                    this.setState(cachedState);
                });
                
            } else {
                this.setState({profileDetailsFetched:true});
            }
        }).catch((error) => {
            this.setState({profileDetailsFetched:true});
        });
        
        
    }

    submitHandler = (event) => {
        event.preventDefault();
        const validateFormState = validateForm(this.state.form);
        this.setState({form:validateFormState}, () => {
            if(this.state.form.isValid){
                this.setState({requesting:true});
                this.updateUserMeta(this.state.form.fields.displayname.value, this.state.form.fields.handicap.value, this.state.form.fields.homecourse.value, this.state.receiveemails, this.state.front9back9layout, this.state.whmode, parseFloat(this.state.form.fields.whindex.value));
            } else {
                if(this.state.form.firstError){
                    scrollToRef(this.state.form.fields[this.state.form.firstError].ref);
                }
            }
        });
        
    }

    updateUserMeta = (displayname, handicap, homecourse, receiveemails, front9back9layout, whmode, whindex) => {
        
        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);
        db.collection("user_meta").doc(this.props.userId).update({
            displayname: displayname,
            handicap: handicap,
            whindex: whindex,
            homecourse: homecourse,
            whmode: whmode,
            receiveemails: receiveemails,
            front9back9layout: front9back9layout
        })
        .then(() => {
            const cacheState = {...this.state}
            this.setState({ 
                profileData: {
                    ...cacheState.profileData,
                    displayname: displayname,
                    handicap: handicap,
                    homecourse: homecourse,
                    whmode: whmode,
                    receiveemails: receiveemails,
                    front9back9layout: front9back9layout,
                    whindex: whindex
                },
                snackBarOpen: true, 
                snackBarSuccess: true,  
                snackBarMessage : 'Great News! We have updated your settings.',
                requesting: false
            });
            this.props.authRefreshMeta(this.props.userId);
        })
        .catch((error) => {
            this.setState({ snackBarOpen: true, snackBarSuccess: false, snackBarMessage : 'Oops, something went wrong, please try again.' });
        });
    }

    inputChangedHandler = (event) => {
        const inputValue = event.target.value;
        const inputName = event.target.name;
        const updateState = {...this.state};
        updateState.form.fields[inputName].value = inputValue;
        this.setState(updateState);
    }

    fileSelectedHandler = (event) => {
        const maxFileSize = 2000000; //1 mb
        const file = event.target.files[0];
        if(file){
            if(file.size > maxFileSize) {
                this.setState({snackBarOpen:true,snackBarSuccess:false,snackBarMessage: 'File size error.'});
            } else if (!file.type.match(/image/i)){
                this.setState({snackBarOpen:true,snackBarSuccess:false,snackBarMessage: 'File type error.'})
            } else {
                this.setState({imageUploading:true});
                this.storeImage(file);
            }
        }
    } 

    storeImage = (file) => {
        const storage = firebase.storage();
        const storageRef = storage.ref();
        const spaceRef = storageRef.child('userpics/' + this.props.userId + '-pp.jpg');
        spaceRef.put(file).then((snapshot) => {
            console.log('Uploaded a  file!',snapshot);
            //now update user mata with path:
            this.setState({ 
                snackBarOpen: true, 
                snackBarSuccess: true,  
                snackBarMessage : 'Profile Pic Saved!.',
                requesting: false
            });
            //check for profile pic
            const storage = firebase.storage();
            const storageRef = storage.ref();
            storageRef.child('userpics/' + this.props.userId + '-pp.jpg').getDownloadURL().then((url) => {
                this.setState({newProfileImage:url,imageUploading:false});
                this.props.authRefreshMeta(this.props.userId);
            }).catch((error) => {
                this.setState({imageUploading:false});
            });
        });
    }

    deleteProfilePic = () => {
        //set image changeng loader
        this.setState({imageUploading:true});

        const storage = firebase.storage();
        const storageRef = storage.ref();
        const spaceRef = storageRef.child('userpics/' + this.props.userId + '-pp.jpg');
        spaceRef.delete().then((snapshot) => {
            console.log('file deleted!',snapshot);
            //now update user mata with path:
            const cacheState = {...this.state}
            this.setState({ 
                profileData: {
                    ...cacheState.profileData,
                    profilepic: null
                },
                newProfileImage:null,
                snackBarOpen: true, 
                snackBarSuccess: true,  
                snackBarMessage : 'Profile Pic Removed!.',
                requesting: false,
                imageUploading:false
            });
            this.props.authRefreshMeta(this.props.userId);
        }).catch((error) => {
            this.setState({imageUploading:false});
        });
    }

    blurField = (event) => {
        const fieldName = event.target.name;
        if(validateUnit(fieldName)){
            const updateState = {...this.state};
            updateState.form.fields[fieldName].errorType = false;
            updateState.form.fields[fieldName].valid = true;
            this.setState(updateState);
        }
    }
    

    loadEditView = () => {
        let path = '/editprofile';
        this.props.history.push(path);
    }

    backClick = () => {
        let path = '/profile/' + this.props.userId;
        this.props.history.push(path);
    }

    closeSnackBar = () => {
        this.setState({snackBarOpen:false});
    }

    handleImageTooltipClose = () => {
        this.setState({ imageTooltipOpen: false });
    };
    
    handleImageTooltipOpen = () => {
        this.setState({ imageTooltipOpen: true });
    };

    render() {

        const { classes } = this.props;
        const buttonClassname = classNames({
        [classes.buttonSuccess]: true,
        });

    
        return (
            <Pux>
                <Header backClick={this.backClick} />
                {this.state.profileDetailsFetched && this.props.userId ? 
                    <Pux>
                        {this.state.profileData ? 
                            <div className="gh-content">
                            
                                <Heading type="h1" variant="central">My Settings</Heading>

                                <form className="form" onSubmit={this.submitHandler}>

                                    <div className="form__field form__field--imageuploader">
                                        
                                        <ProfilePic 
                                            premium={this.state.profileData.role === 'premium'}
                                            displayname={this.state.profileData.displayname}
                                            profilepic={this.state.newProfileImage !== null ? this.state.newProfileImage : this.state.profileData.profilepic}
                                            className="form__field--imageuploader-asis" />
                                        {this.state.imageUploading ? 
                                            <CircularProgress className="form__field--imageuploader-loading" />
                                        : null}
                                       
                                         <input 
                                            type="file"
                                            onChange={this.fileSelectedHandler}
                                            style={{display:'none'}} 
                                            id="profilepic"
                                            ref={fileInput => this.fileInput = fileInput} 
                                            name="profilepic" 
                                            value='' />
                                        <div className="form__field--imageuploader-actions">
                                            <Button
                                                    fullWidth
                                                    size="small"
                                                    onClick={() => {
                                                        this.fileInput.click()
                                                    }}  
                                                >
                                                    {this.state.newProfileImage || this.state.profileData.profilepic ? 'Change' : 'Add'} Profile Pic
                                            </Button>
                                            <ClickAwayListener onClickAway={this.handleImageTooltipClose}>
                                                <Tooltip
                                                PopperProps={{
                                                    disablePortal: true,
                                                }}
                                                placement="top-end"
                                                className='gh-tooltip'
                                                onClose={this.handleImageTooltipClose}
                                                open={this.state.imageTooltipOpen}
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                                title="Image must be square in ratio, roughly 250px x 250px"
                                                >
                                                    <HelpIcon onClick={this.handleImageTooltipOpen} />
                                                </Tooltip>
                                            </ClickAwayListener>
                                            {this.state.newProfileImage || this.state.profileData.profilepic ? 
                                                <DeleteIcon onClick={this.deleteProfilePic} className="form__field--imageuploader-actions-delete" />
                                            : null}
                                        </div>
                                    </div>  

                                     
                                    
                                    <FormControl margin="normal" fullWidth error={this.state.form.fields.displayname.valid ? false : true}>
                                        <InputLabel htmlFor="displayname">Display Name</InputLabel>
                                        <Input 
                                            onChange={this.inputChangedHandler}
                                            onBlur={this.blurField}
                                            className="form__input" 
                                            id="displayname" 
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <EditIcon />
                                                </InputAdornment>
                                            }
                                            name="displayname" 
                                            value={this.state.form.fields.displayname.value}
                                            autoComplete="displayname" 
                                            
                                             />
                                        {!this.state.form.fields.displayname.valid ?
                                            <FormHelperText id="displayname-error">{this.state.form.fields.displayname.rules[this.state.form.fields.displayname.errorType].message}</FormHelperText>
                                        : null }
                                        
                                    </FormControl>

                                    {this.state.whmode ? (
                                        <FormControl margin="normal" fullWidth error={this.state.form.fields.whindex.valid ? false : true}>
                                            <InputLabel htmlFor="whindex">Your World Handicap Index</InputLabel>
                                            <Input 
                                                onChange={this.inputChangedHandler}
                                                onBlur={this.blurField}
                                                className="form__input" 
                                                id="whindex" 
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <PublicIcon />
                                                    </InputAdornment>
                                                }
                                                name="whindex" 
                                                value={this.state.form.fields.whindex.value}
                                                autoComplete="whindex" 
                                                />
                                            {!this.state.form.fields.whindex.valid ?
                                                <FormHelperText id="whindex-error">{this.state.form.fields.whindex.rules[this.state.form.fields.whindex.errorType].message}</FormHelperText>
                                            : null }
                                            
                                            
                                        </FormControl>
                                    ) : (
                                        <FormControl margin="normal" fullWidth error={this.state.form.fields.handicap.valid ? false : true}>
                                            <InputLabel htmlFor="handicap">Your Current Handicap</InputLabel>
                                            <Input 
                                                onChange={this.inputChangedHandler}
                                                onBlur={this.blurField}
                                                className="form__input" 
                                                id="handicap" 
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <SecurityIcon />
                                                    </InputAdornment>
                                                }
                                                name="handicap" 
                                                value={this.state.form.fields.handicap.value}
                                                autoComplete="handicap" 
                                                />
                                            {!this.state.form.fields.handicap.valid ?
                                                <FormHelperText id="handicap-error">{this.state.form.fields.handicap.rules[this.state.form.fields.handicap.errorType].message}</FormHelperText>
                                            : null }
                                            {this.state.form.fields.handicap.value === '' ? 
                                                <FormHelperText id="handicap-helper">If you dont know this already either use our start up caluclator or start off 28 and adjust from there on.</FormHelperText>
                                            : null}
                                            
                                        </FormControl>
                                    )}
                                    
                                    

                                    
                                    <FormControl margin="normal" fullWidth error={this.state.form.fields.homecourse.valid ?  false : true}>
                                        <InputLabel htmlFor="homecourse">Home Golf Club (Optional)</InputLabel>
                                        <Input 
                                            onChange={this.inputChangedHandler}
                                            onBlur={this.blurField}
                                            className="form__input" 
                                            id="homecourse" 
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <FlagIcon />
                                                </InputAdornment>
                                            }
                                            name="homecourse" 
                                            value={this.state.form.fields.homecourse.value}
                                            autoComplete="homecourse" 
                                             />
                                        {!this.state.form.fields.homecourse.valid ?
                                            <FormHelperText id="handicap-error">{this.state.form.fields.homecourse.rules[this.state.form.fields.homecourse.errorType].message}</FormHelperText>
                                        : null }
                                    </FormControl>

                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.whmode}
                                                    onChange={() => {
                                                        this.setState({whmode:!this.state.whmode})
                                                    }}
                                                    color="primary"
                                                    value="yes"
                                                    />
                                            }
                                            label="World Handicap Index Mode"
                                            />
                                    </FormGroup>

                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.receiveemails}
                                                    onChange={() => {
                                                        this.setState({receiveemails:!this.state.receiveemails})
                                                    }}
                                                    color="primary"
                                                    value="re"
                                                    />
                                            }
                                            label="Receive Emails"
                                            />
                                    </FormGroup>

                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.front9back9layout}
                                                    onChange={() => {
                                                        this.setState({front9back9layout:!this.state.front9back9layout})
                                                    }}
                                                    color="primary"
                                                    value="f9b9"
                                                    />
                                            }
                                            label="Front 9 Back 9 Scorecard Layout"
                                            />
                                    </FormGroup>
                                    
                                    <FormControl margin="normal" fullWidth>
                                        <div className={classes.wrapper}>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                size="large"
                                                variant="contained"
                                                color="primary"
                                                disabled={this.state.requesting}
                                                className={buttonClassname}
                                            >
                                                Update Settings
                                            </Button>
                                            {this.state.requesting ? <CircularProgress size={24} className={classes.buttonProgress} /> : null}
                                        </div>
                                    </FormControl>

                                    <div className="form__alts">
                                        <Typography variant="body1" className="form__alts-para" align="right">
                                            <Link to="/changeemail">Change Email</Link>
                                        </Typography>
                                    </div>

                                    <Snackbar
                                        className={(this.state.snackBarSuccess) ? 'gh-snackBar gh-snackBar--success' : 'gh-snackBar'}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        open={this.state.snackBarOpen}
                                        autoHideDuration={6000}
                                        onClose={this.closeSnackBar}
                                        ContentProps={{
                                            'aria-describedby': 'message-id',
                                        }}
                                        message={
                                            <span id="message-id" className="gh-snackBar__message">
                                                <SuccessIcon className="gh-snackBar__message-svg" />
                                                {this.state.snackBarMessage}
                                            </span>
                                        }
                                        action={[
                                            <IconButton
                                                key="close"
                                                aria-label="Close"
                                                color="inherit"
                                                onClick={this.closeSnackBar}
                                                >
                                            <CloseIcon />
                                            </IconButton>
                                        ]}
                                        />
                                </form>
                                
                                
                            </div>
                        : <div className="gh-content"><Typography>Oops something went wrong.</Typography></div>}
                    </Pux>
                : <ScreenLoading />}
                <AppBar selected="home" />
            </Pux>
        );
    }
}

EditProfile.propTypes = {
   profileData: PropTypes.object
}

const mapStateToProps = state => {
    return {
        userId: state.auth.userId,
        userMeta: state.auth.userMeta
    }
}

const mapDispatchToProps = dispatch =>  {
    return {
        authRefreshMeta: (userId) => dispatch(actionsCreators.authRefreshMeta(userId))
    };
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditProfile)));
