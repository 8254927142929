import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Logo from '../Logo/Logo';
import Pux from '../../hoc/Pux';
import UserMeta from '../../containers/UserMeta/UserMeta';
import MainMenu from '../MainMenu/MainMenu';
import { Redirect } from 'react-router';
import './Header.scss';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import BackIcon from '@material-ui/icons/ChevronLeft';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class Header extends Component {

  state = {
    logout: false,
    left: false,
    back: false,
    profileUrl: null
  };

  toggleDrawer = (open) => () => {
    this.setState({
      left: open
    });
  };

  logout = event => {
    event.preventDefault();
    this.setState({
      logout:true
    });
  }

  backHandler = () => {
    this.setState({
      back: true
    });
  }

  loadMyProfile = () => {
    this.setState({
      profileUrl: '/profile/' + this.props.userId
    });
  }


  render() {

    let pageRedirect = null;
    if(this.state.logout) {
      pageRedirect = <Redirect to="/logout" />
    } else if(this.state.profileUrl !== null){
      pageRedirect = <Redirect to={this.state.profileUrl} />
    }

    return (
      <Pux>
        {pageRedirect}
        <header className="header">
            {this.props.backClick ? <BackIcon onClick={this.props.backClick} className="header__back" /> : null}
            <IconButton className="header__menu" onClick={this.toggleDrawer(true)} color="inherit" aria-label="Menu">
              <MenuIcon />
            </IconButton>
            <Logo bemClass="header__logo" type="header" />
            {!this.props.loggedOut && this.props.userMeta ? 
              <IconButton onClick={this.loadMyProfile} className="header__person" color="inherit" aria-label="Menu">
                <PersonIcon className={this.props.userMeta.role === 'premium' ? 'header__person-icon --premium' : 'header__person-icon'} />
              </IconButton>
            : null}
        </header>
        
        
        <Drawer classes={{ paper: "drawer" }} open={this.state.left} onClose={this.toggleDrawer(false)}>
            <div className="drawer__content">
              <UserMeta reduced />
              <MainMenu premium={this.props.userMeta && this.props.userMeta.role === 'premium'} loggedOut={this.props.loggedOut} />
              {!this.props.loggedOut ? <Button
                  onClick={this.logout}
                  type="submit"
                  fullWidth
                  size="large"
                  variant="contained"
                  color="secondary"
                  className=""
              >
                  Log Out
              </Button> : null}
            </div>
            <Typography className="drawer__version">Version 1.5</Typography>
        </Drawer>
      </Pux>
    );
  }
}

Header.propTypes = {
  toggleDrawer: PropTypes.func
};


const mapStateToProps = state => {
  return {
      userId: state.auth.userId,
      userMeta: state.auth.userMeta
  }
}


export default withRouter(connect(mapStateToProps, null)(Header));