import React, { Component } from 'react';
import { renderEmail } from  '../../utils/emails';
import { Link } from 'react-router-dom';
import '../../assets/scss/core.scss';
import Header from '../../components/Header/Header';
import AppBar from '../AppBar/AppBar';
import {validateForm, scrollToRef, validateUnit } from  '../../utils/validation';
//import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Pux from '../../hoc/Pux';
import ScreenLoading from '../../components/ScreenLoading/ScreenLoading';
import Heading from '../../components/Heading/Heading';
import IconButton from '@material-ui/core/IconButton';
import firebase from '../../Firebase';
import Button from '@material-ui/core/Button';
import LinkIcon from '@material-ui/icons/Link';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/MailOutline';
import FlagIcon from '@material-ui/icons/GolfCourse';
import AddressIcon from '@material-ui/icons/LocationOn';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import SuccessIcon from '@material-ui/icons/CheckCircleOutlined';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';



const styles = theme => ({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      position: 'relative',
      margin: '8px 0'
    },
    fabProgress: {
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    customWidth: {
        width: 200,
    },
    noMaxWidth: {
        maxWidth: 'none',
    },
});


export class ListCourses extends Component {

    state = {
        showSnackBar:false,
        snackBarMessage:'Thanks we have received your request to a new course. We hope to add your course in the next 24-48 hours',
        snackBarType: 'success',
        coursesData: null,
        recentCoursesData: null,
        recentPerPage: 5,
        perPage: 50,
    }

    
    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        this.setState({snackBarOpen:false});
    };

    handleTooltipClose = () => {
        this.setState({ sssTooltipOpen: false });
    };
    
    handleTooltipOpen = () => {
        this.setState({ sssTooltipOpen: true });
    };

    



    backClick = () => {
        this.props.history.goBack();
    }

    closeSnackBar = () => {
        this.setState({showSnackBar:false});
    }

    componentDidMount() {
        if(this.props.userId !== 'O0q6vgZ3fYdEED6Vw0sLVTXQ6j83') {
            this.props.history.push('/');
        }

        this.getCourses();
        this.getRecentCourses();
    }

    getRecentCourses = () => {

      const db = firebase.firestore();
      const settings = {};
      db.settings(settings);

      db.collection("courses")
        .limit(this.state.recentPerPage)
        .orderBy("updated")
        .get().then(querySnapshot => {
        // console.log("data? ",querySnapshot);
        querySnapshot.forEach(doc => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            const cacheState = {...this.state};
            
            if(!cacheState.recentCoursesData) {
              cacheState.recentCoursesData = [];
            }
            // console.log("cacheState.coursesData: ",cacheState.coursesData);
            cacheState.recentCoursesData.push({
              ...doc.data(),
              id: doc.id,
            });
            this.setState(cacheState);
            
        });
      }).catch(err => {
        console.log("error: ",err);
      });
    }

    getCourses = () => {

      const db = firebase.firestore();
      const settings = {};
      db.settings(settings);

      db.collection("courses")
        .limit(this.state.perPage)
        .orderBy("title")
        .get().then(querySnapshot => {
        // console.log("data? ",querySnapshot);
        querySnapshot.forEach(doc => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            const cacheState = {...this.state};
            
            if(!cacheState.coursesData) {
              cacheState.coursesData = [];
            }
            // console.log("cacheState.coursesData: ",cacheState.coursesData);
            cacheState.coursesData.push({
              ...doc.data(),
              id: doc.id,
            });
            this.setState(cacheState);
            
        });
      }).catch(err => {
        console.log("error: ",err);
      });
    }
   

    render() {

        const { classes } = this.props;
        const buttonClassname = classNames({
        [classes.buttonSuccess]: true,
        });

        const { coursesData = null, recentCoursesData = null } = this.state;

       const tableRowGH = data => {
        const { title, id } = data;
         return (
          <TableRow key={id}>
          <TableCell component="th" scope="row">
              {title}
          </TableCell>
          <TableCell align="right">
            <Button href={`/course/${id}`} >
            View
            </Button>
            <Button href={`/editcourse/${id}`} >
            Edit
            </Button>
          </TableCell>
        </TableRow>
         );
       };


        return (
            <Pux>
                <Header backClick={this.backClick} />
                <div className="gh-content">
                    
                    {coursesData && coursesData.length > 0 ? 
                        <Pux>
                            {recentCoursesData && recentCoursesData.length &&
                              <Pux>
                                <Heading type="h1" variant="central">Recent Courses</Heading>
                                <Table className="gh-adminTable">
                                  <TableHead>
                                      <TableRow>
                                          <TableCell>Course Name</TableCell>
                                          <TableCell align="right"></TableCell>
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                      {recentCoursesData.map(doc => {
                                        return tableRowGH(doc);
                                      })}
                                  </TableBody>
                              </Table> 
                              </Pux>
                            }

                            <Heading type="h1" variant="central">All Courses</Heading>
                            <Table className="gh-adminTable">
                              <TableHead>
                                  <TableRow>
                                      <TableCell>Course Name</TableCell>
                                      <TableCell align="right"></TableCell>
                                  </TableRow>
                              </TableHead>
                              <TableBody>
                                  {coursesData.map(doc => {
                                    return tableRowGH(doc);
                                  })}
                              </TableBody>
                          </Table> 
                        </Pux>    
                         : <ScreenLoading />}
                </div>
                
            </Pux>
        );
    }
}
/*
RequestCourse.propTypes = {
    searchTypeHandler: PropTypes.func
}*/

const mapStateToProps = state => {
    return {
        userMeta: state.auth.userMeta,
        userId: state.auth.userId
    }
}

const mapDispatchToProps = dispatch =>  {
    return {
        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ListCourses));
