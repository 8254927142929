import React, { Component } from 'react';
import Pux from '../../hoc/Pux';
import {validateForm, scrollToRef, validateUnit } from  '../../utils/validation';
import './ChangeEmail.scss';
import PropTypes from 'prop-types';
import firebase from '../../Firebase';
import ScreenLoading from '../../components/ScreenLoading/ScreenLoading';
import Heading from '../../components/Heading/Heading';
import Header from '../../components/Header/Header';
import AppBar from '../AppBar/AppBar';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MailIcon from '@material-ui/icons/MailOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import SuccessIcon from '@material-ui/icons/CheckCircleOutlined';
import IconButton from '@material-ui/core/IconButton';
import LockIcon from '@material-ui/icons/LockOutlined';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import * as actionsCreators from '../../store/actions';

const styles = theme => ({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      position: 'relative',
      margin: '8px 0'
    },
    fabProgress: {
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    customWidth: {
        width: 200,
    },
    noMaxWidth: {
        maxWidth: 'none',
    },
});


class ChangeEmail extends Component {

    state = {
        initalDataFetched:false,
        requesting: false,
        snackBarOpen: false,
        snackBarSuccess: false,
        snackBarMessage: '',
        showPassword: false,
        form: {
            isValid:true,
            firstError:null,
            fields: {
                email: {
                    value: '',
                    valid: true,
                    errorType: null,
                    rules: {
                        notEmpty : {
                            message: 'Dont forget your email.'
                        },
                        emailFormat : {
                            message: 'Oops, your email address doesn\'t look right.'
                        },
                        maxlength: {
                            message: 'Have you got a shorter email?',
                            length: 50
                        }
                    }
                },
                password: {
                    value: '',
                    valid: true,
                    errorType: null,
                    rules: {
                        notEmpty : {
                            message: 'Dont forget your password.'
                        },
                        maxlength: {
                            message: 'Password needs to be less than 50 characters.',
                            length: 50
                        },
                        minlength: {
                            message: 'Password needs to be more than 6 characters.',
                            length: 6
                        }
                    }
                }
            }
        }
    }

    componentWillMount() {
        document.title = 'Golf Handicap - Change Email';
    }
    

    componentDidMount () {
        
        this.setState({initalDataFetched:true});
        
    }

    submitHandler = (event) => {
        event.preventDefault();
        const validateFormState = validateForm(this.state.form);
        this.setState({form:validateFormState}, () => {
            if(this.state.form.isValid){
                this.setState({requesting:true});
                this.updateEmailAddress(this.state.form.fields.email.value, this.state.form.fields.password.value);
            } else {
                if(this.state.form.firstError){
                    scrollToRef(this.state.form.fields[this.state.form.firstError].ref);
                }
            }
        });
        
    }

    updateEmailAddress = (updatedEmail, password) => {
        
        
        const user = firebase.auth().currentUser;

        const credential = firebase.auth.EmailAuthProvider.credential(
            firebase.auth().currentUser.email, 
            password
        );

        console.log("credential: ",credential);
        console.log("email: ",firebase.auth().currentUser.email);
        

        user.reauthenticateWithCredential(credential).then(() => {
            // User re-authenticated.
            user.updateEmail(updatedEmail).then(() => {
                // Update successful.
                this.setState({ 
                    snackBarOpen: true, 
                    snackBarSuccess: true,  
                    snackBarMessage : `Great News! We have updated your email address to ${updatedEmail}`,
                    requesting: false
                });
              }).catch(error => {
                // An error happened.
                // console.log("error: ",error);
                this.setState({ requesting: false, snackBarOpen: true, snackBarSuccess: false, snackBarMessage : error.message || 'Oops, something went wrong, please try again later.' })
              });
          }).catch(error => {
            console.log("error: ",error);
            this.setState({ requesting: false, snackBarOpen: true, snackBarSuccess: false, snackBarMessage : 'Email or Password may be incorrect.' })
          });
          

        
          
    }

    inputChangedHandler = (event) => {
        const inputValue = event.target.value;
        const inputName = event.target.name;
        const updateState = {...this.state};
        updateState.form.fields[inputName].value = inputValue;
        this.setState(updateState);
    }

   

    

    

    blurField = (event) => {
        const fieldName = event.target.name;
        if(validateUnit(fieldName)){
            const updateState = {...this.state};
            updateState.form.fields[fieldName].errorType = false;
            updateState.form.fields[fieldName].valid = true;
            this.setState(updateState);
        }
    }
    

    loadEditView = () => {
        let path = '/editprofile';
        this.props.history.push(path);
    }

    backClick = () => {
        let path = '/editprofile';
        this.props.history.push(path);
    }

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };

    closeSnackBar = () => {
        this.setState({snackBarOpen:false});
    }

    handleImageTooltipClose = () => {
        this.setState({ imageTooltipOpen: false });
    };
    
    handleImageTooltipOpen = () => {
        this.setState({ imageTooltipOpen: true });
    };

    render() {

        const { classes } = this.props;
        const buttonClassname = classNames({
        [classes.buttonSuccess]: true,
        });

    
        return (
            <Pux>
                <Header backClick={this.backClick} />
                {this.state.initalDataFetched && this.props.userId ? 
                    <Pux>
                        
                            <div className="gh-content">
                            
                                <Heading type="h1" variant="central">Change Email</Heading>

                                
                                <form className="form" onSubmit={this.submitHandler}>
                                    
                                    <FormControl margin="normal" fullWidth error={this.state.form.fields.email.valid ? false : true}>
                                        <InputLabel htmlFor="displayname">New email</InputLabel>
                                        <Input 
                                            onChange={this.inputChangedHandler}
                                            onBlur={this.blurField}
                                            className="form__input" 
                                            id="email" 
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <MailIcon />
                                                </InputAdornment>
                                            }
                                            name="email" 
                                            value={this.state.form.fields.email.value}
                                            autoComplete="Email address" 
                                            
                                             />
                                        {!this.state.form.fields.email.valid ?
                                            <FormHelperText id="displayname-error">{this.state.form.fields.email.rules[this.state.form.fields.email.errorType].message}</FormHelperText>
                                        : null }
                                        
                                    </FormControl>

                                    <FormControl margin="normal" fullWidth error={this.state.form.fields.password.valid ? false : true}>
                                        <InputLabel htmlFor="password">Password</InputLabel>
                                        <Input 
                                            className="form__input" 
                                            name="password"
                                            onChange={this.inputChangedHandler}
                                            onBlur={this.blurField}
                                            value={this.state.password}
                                            type={this.state.showPassword ? 'text' : 'password'} 
                                            id="password" 
                                            startAdornment={
                                                <InputAdornment position="start">
                                                <LockIcon />
                                                </InputAdornment>
                                            }
                                            endAdornment={
                                                <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="Toggle Password Visibility"
                                                    onClick={this.handleClickShowPassword}
                                                >
                                                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                                </InputAdornment>
                                            }
                                            autoComplete="current-password" />
                                            <FormHelperText id="helper">You need to re-enter password for security reasons.</FormHelperText>
                                            {!this.state.form.fields.password.valid ?
                                            <FormHelperText id="email-error">{this.state.form.fields.password.rules[this.state.form.fields.password.errorType].message}</FormHelperText>
                                        : null }
                                    </FormControl>
                                    
                                    <FormControl margin="normal" fullWidth>
                                        <div className={classes.wrapper}>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                size="large"
                                                variant="contained"
                                                color="primary"
                                                disabled={this.state.requesting}
                                                className={buttonClassname}
                                            >
                                                Change Email
                                            </Button>
                                            {this.state.requesting ? <CircularProgress size={24} className={classes.buttonProgress} /> : null}
                                        </div>
                                    </FormControl>

                                    <Snackbar
                                        className={(this.state.snackBarSuccess) ? 'gh-snackBar gh-snackBar--success' : 'gh-snackBar'}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        open={this.state.snackBarOpen}
                                        autoHideDuration={6000}
                                        onClose={this.closeSnackBar}
                                        ContentProps={{
                                            'aria-describedby': 'message-id',
                                        }}
                                        message={
                                            <span id="message-id" className="gh-snackBar__message">
                                                <SuccessIcon className="gh-snackBar__message-svg" />
                                                {this.state.snackBarMessage}
                                            </span>
                                        }
                                        action={[
                                            <IconButton
                                                key="close"
                                                aria-label="Close"
                                                color="inherit"
                                                onClick={this.closeSnackBar}
                                                >
                                            <CloseIcon />
                                            </IconButton>
                                        ]}
                                        />
                                </form>
                                
                                
                            </div>
                        
                    </Pux>
                : <ScreenLoading />}
                <AppBar selected="home" />
            </Pux>
        );
    }
}

ChangeEmail.propTypes = {
   profileData: PropTypes.object
}

const mapStateToProps = state => {
    return {
        userId: state.auth.userId,
        userMeta: state.auth.userMeta
    }
}

const mapDispatchToProps = dispatch =>  {
    return {
        authRefreshMeta: (userId) => dispatch(actionsCreators.authRefreshMeta(userId))
    };
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChangeEmail)));
