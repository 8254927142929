import React, { Component } from 'react';
import Header from '../../components/Header/Header';
import AppBar from '../../containers/AppBar/AppBar';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import LinkUi from '@material-ui/core/Link';
import { connect } from 'react-redux';
import Pux from '../../hoc/Pux';
import Heading from '../../components/Heading/Heading';
import SearchUsersField from '../../components/SearchField/SearchUsersField';
import ScreenLoading from '../../components/ScreenLoading/ScreenLoading';
import RecommendedFriends from '../../components/RecommendedFriends/RecommendedFriends';
import InputAdornment from '@material-ui/core/InputAdornment';
import PersonIcon from '@material-ui/icons/Person';
import { Typography } from '@material-ui/core';


export class SearchUsers extends Component {

    state = {
        searchResults: null,
        searchTerm: '',
        redirect: null
    }

    componentWillMount() {
        document.title = 'Golf Handicap - Find Users';
    }

    onUserSelect = (id) => {
        this.props.history.push('/profile/' + id);
    }

    inputChangedHandler = (event) => {
        const inputValue = event.target.value;
        this.setState({searchTerm:inputValue});
    }

    submitHandler = (event) => {
        event.preventDefault();
    }

    loadSearchCourse = () => {
        this.props.history.push('/courses');
    }


    render() {

        return (
            <Pux>
                <Header />
                <div className="gh-content">
                {this.props.userMeta ? 
                    <Pux>
                        <Heading type="h1" variant="central">Find Users</Heading>
                        <form className="form" onSubmit={this.submitHandler}>
                            <Typography>Use the search below to find friends or other golfers and compare your stats.</Typography>
                            <SearchUsersField 
                                searchType="users"
                                fieldName="searchusers"
                                fieldLabel="Users Name"
                                isValid={true}
                                returnHitHandler={this.onUserSelect}
                                onUserSelect={this.onUserSelect}
                                inputProps={{
                                    onChange:this.inputChangedHandler,
                                    className:"form__input",
                                    id:"searchusers",
                                    startAdornment:(
                                        <InputAdornment position="start">
                                            <PersonIcon />
                                        </InputAdornment>
                                    ),
                                    name:"searchusers",
                                    value:this.state.searchTerm,
                                    inputProps:{autoComplete:"off"}
                                }}
                                
                                />
                                <Typography>If you cannot find your friends using this search try visting the course details page where they have posted a recent round <LinkUi onClick={this.loadSearchCourse}>Find Friends Course</LinkUi></Typography>
                        </form>

                        {this.props.userMeta && <RecommendedFriends userId={this.props.userId} meta={this.props.userMeta} />}

                        
                    </Pux>
                    : <ScreenLoading />}                
                </div>
                <AppBar selected="home" />
            </Pux>
        );
    }
}

SearchUsers.propTypes = {
    onUserSelect: PropTypes.func
}

const mapStateToProps = state => {
    return {
        userId: state.auth.userId,
        userMeta: state.auth.userMeta
    }
}

export default withRouter(connect(mapStateToProps)(SearchUsers));
