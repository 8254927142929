import React, { Component } from 'react';
import Layout from './hoc/Layout/Layout';
import UserMetaOverlay from './containers/UserMetaOverlay/UserMetaOverlay';
import Home from './containers/Home/Home';
import HomeLoggedOut from './containers/HomeContent/HomeContent';
import PremiumDetails from './containers/PremiumDetails/PremiumDetails';
import AddRound from './containers/AddRound/AddRound';
import AddRoundWHS from './containers/AddRound/AddRoundWHS';
import RoundDetails from './containers/RoundDetails/RoundDetails';
import YourHistory from './containers/History/History';
import Profile from './containers/Profile/Profile';
import DataFix from './containers/DataFix/DataFix';
import Content from './containers/Content/Content';
import Connections from './containers/Connections/Connections';
import EditProfile from './containers/EditProfile/EditProfile';
import ChangeEmail from './containers/ChangeEmail/ChangeEmail';
import AddRoundScores from './containers/AddRoundScores/AddRoundScores';
import { connect } from 'react-redux';
import * as actions from './store/actions/index';
import SearchUsers from './containers/SearchUsers/SearchUsers';
import SearchCourses from './containers/SearchCourses/SearchCourses';
import RequestCourse from './containers/RequestCourse/RequestCourse';
import ContactUs from './containers/ContactUs/ContactUs';
import CourseDetails from './containers/CourseDetails/CourseDetails';
import CountiesDisplay from './containers/CountiesDisplay/CountiesDisplay';
import SignIn from './containers/Auth/SignIn/SignIn';
import SignUp from './containers/Auth/SignUp/SignUp';
import AddCourse from './containers/Admin/AddCourse';
import EditCourse from './containers/Admin/EditCourse';
import ListCourses from './containers/Admin/ListCourses';
import ResetPassword from './containers/Auth/ResetPassword/ResetPassword';
import Logout from './containers/Auth/Logout/Logout';
import Donate from './containers/Donate/Donate';
import Thankyou from './containers/Thankyou/Thankyou';
import Cancel from './containers/Cancel/Cancel';
import Manifest from './containers/Manifest/Manifest';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import ReactGA from 'react-ga';




const pageTitleBase = 'Golf Handicapp';


class App extends Component {

  componentDidMount() {
    this.props.onTryAutoSignUp();
    ReactGA.initialize('UA-3720421-32');
    ReactGA.pageview(window.location.pathname + window.location.search);

  }

  componentWillUpdate() {
    window.scrollTo(0, 0);
  }

  render() {

    let routes = (
      <Switch>
          <Route path="*" exact component={Manifest} />
      </Switch>
    );

    const securedRoutes = (
      <>
        <Route path="/manifest" exact component={Manifest} />
          <Route path="/" exact component={Home}  />
          <Route path="/users" exact component={SearchUsers} />
          <Route path="/courses" exact component={SearchCourses} />
          <Route path="/requestnewcourse" exact component={RequestCourse} />
          <Route path="/contactus" exact component={ContactUs} />
          <Route path="/course/:id" component={CourseDetails} />
          <Route path="/county/:county" component={CountiesDisplay} />
          <Route path="/logout" exact component={Logout}/>
          <Route path="/addround" exact component={AddRound}/>
          <Route path="/addroundscores" exact component={AddRoundScores}/>
          <Route path="/round/:id/:type?" component={RoundDetails} />
          <Route path="/history/" component={YourHistory} />
          <Route path="/profile/:id" render={(props) => (<Profile {...props} key={this.props.location.key} />)} />
          <Route path="/connections/:type/:id" component={Connections} />
          <Route path="/editprofile" exact component={EditProfile} />
          <Route path="/changeemail" exact component={ChangeEmail} />
          <Route path="/premium" exact component={PremiumDetails} />
          <Route path="/content/:slug/:id" render={(props) => (<Content {...props} key={this.props.location.key} />)} />
          <Route path="/import" exact component={DataFix} />
          <Route path="/donate" component={Donate} />
          <Route path="/cancel" component={Cancel} />
          <Route path="/thankyou" component={Thankyou} />
      </>
    );


    if(this.props.triedAuthentication && this.props.isAuthenticated){
      routes = (
        <Switch>
            <Route path="/manifest" exact component={Manifest} />
            <Route path="/" exact component={Home}  />
            <Route path="/users" exact component={SearchUsers} />
            <Route path="/courses" exact component={SearchCourses} />
            <Route path="/requestnewcourse" exact component={RequestCourse} />
            <Route path="/contactus" exact component={ContactUs} />
            <Route path="/course/:id" component={CourseDetails} />
            <Route path="/county/:county" component={CountiesDisplay} />
            <Route path="/logout" exact component={Logout}/>
            <Route path="/addround" exact component={AddRound}/>
            <Route path="/addroundwhs" exact component={AddRoundWHS}/>
            <Route path="/addroundscores" exact component={AddRoundScores}/>
            <Route path="/round/:id/:type?" component={RoundDetails} />
            <Route path="/history/" component={YourHistory} />
            <Route path="/profile/:id" render={(props) => (<Profile {...props} key={this.props.location.key} />)} />
            <Route path="/connections/:type/:id" component={Connections} />
            <Route path="/editprofile" exact component={EditProfile} />
            <Route path="/changeemail" exact component={ChangeEmail} />
            <Route path="/premium" exact component={PremiumDetails} />
            <Route path="/content/:slug/:id" render={(props) => (<Content {...props} key={this.props.location.key} />)} />
            <Route path="/import" exact component={DataFix} />
            <Route path="/donate" component={Donate} />
            <Route path="/cancel" component={Cancel} />
            <Route path="/thankyou" component={Thankyou} />
            <Route path="/editcourse/:courseid" component={EditCourse} />
            <Route path="/addcourse" component={AddCourse} />
            <Route path="/allcourses" component={ListCourses} />
            <Redirect to="/" />
        </Switch>
      );
    } else if (this.props.triedAuthentication){
      routes = (
        <Switch>
            <Route path="/manifest" exact component={Manifest} />
            <Route path="/courses" exact component={SearchCourses} />
            <Route path="/requestnewcourse" exact component={RequestCourse} />
            <Route path="/premium" exact component={PremiumDetails} />
            <Route path="/course/:id" component={CourseDetails} />
            <Route path="/signin" exact component={SignIn}/>
            <Route path="/signup" exact component={SignUp}/>
            <Route path="/resetpassword" exact component={ResetPassword}/>
            <Route path="/content/:slug/:id" render={(props) => (<Content {...props} key={this.props.location.key} />)} />
            <Route path="/" exact component={HomeLoggedOut}  />
            <Redirect to="/" />
        </Switch>
      );
    }

    let userDataOverlay = null;
    if(this.props.userMetaFetched  && !this.props.userMeta.whindex) {
        userDataOverlay = <UserMetaOverlay currentHandicap={this.props.userMeta.handicap} displayName={this.props.userMeta.displayname} userId={this.props.userId} />;
    }

    return (
      <MuiThemeProvider theme={theme}>
        <Layout pageTitleBase={pageTitleBase}>
          {routes}
        </Layout>
        {userDataOverlay}
        <CssBaseline />
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated:state.auth.token !== null,
    userId: state.auth.userId,
    userMeta: state.auth.userMeta,
    userMetaFetched: state.auth.userMetaFetched,
    triedAuthentication: state.auth.triedAuthentication
  }
}

const mapDispatchToProps = dispatch => {
    return {
      onTryAutoSignUp: () => {
        dispatch(actions.authCheckState());
      }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
