import React from 'react';
import './RatingDisplay.scss';
import { Typography } from '../../../node_modules/@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import StarEmptyIcon from '@material-ui/icons/StarBorderOutlined';
import StarHalfIcon from '@material-ui/icons/StarHalf';


const RatingDisplay = (props) => {

    let mainClasses = ['gh-rating-display'];
    if(props.className){
        mainClasses.push(props.className);
    }
    
    let totalreviews = 0;
    let courseRating = 0;
    let showRating = false;
    if(props.reviews && props.reviews.length > 0){
        totalreviews = props.reviews.length;
        const maxscore = totalreviews * 5;
        let ratingTally = 0;
        for(let r = 0;r < props.reviews.length;r++){
            const reviewData = props.reviews[r];
            ratingTally += parseInt(reviewData.review_rating);
        }
        //console.log(ratingTally / maxscore);
        courseRating = Math.round((ratingTally / maxscore) * 10) / 2;
        showRating = true;
    } else if(props.rating){
        courseRating = props.rating;
        showRating = true;
    }
    
    

    return (
        <React.Fragment>
           {showRating ?
                <Typography className={mainClasses.join(' ')}>
                    {[...Array(5)].map((index,i) => {
                        let stars = <StarEmptyIcon className="gh-rating-display__stars" key={i} />;
                        if((i+1) <= courseRating){
                            stars = <StarIcon className="gh-rating-display__stars" key={i} />;
                        } else if(((i+1) - courseRating) > 0 && ((i+1) - courseRating) < 1) {
                            stars = <StarHalfIcon className="gh-rating-display__stars" key={i} />;
                        }
                        return stars;
                    })} <span className="gh-rating-display__text">{courseRating} / 5</span>
                </Typography>
            : null}
        </React.Fragment>

    );
};
export default RatingDisplay;