import * as actionTypes from  '../actions/actionsTypes';
import { updateObject } from '../utility';

const intialState = {
    userid:null,
    coursename:null,
    courseid:null,
    rounddate:null,
    roundsss:null,
    roundtype:null,
    handicap:null,
    gender:null,
    scores: [
        {1: []}, {2: []}, {3: []}, {4: []}, {5: []}, {6: []}, {7: []}, {8: []}, {9: []}, {10: []}, {11: []}, {12: []}, {13: []}, {14: []}, {15: []}, {16: []}, {17: []}, {18: []}, 
    ],
    viewname:'addround',
    points:0,
    topar:0,
    system:null,
    courserating:null,
    sloperating:null,
    loading:false
}

const roundStart = (state,action) => {
    return updateObject(state, {
        userid:action.userid,
        coursename:action.coursename,
        courseid:action.courseid,
        coursecard:action.coursecard,
        rounddate:action.rounddate,
        roundsss:action.roundsss,
        roundtype:action.roundtype,
        handicap:action.handicap,
        viewname:action.viewname,
        gender:action.gender,
        system:action.system,
        courserating:action.courserating,
        sloperating:action.sloperating,
        loading:false
    });
}

const roundUpdate = (state,action) => {
    return updateObject(state, {
        userid:action.userid,
        coursename:action.coursename,
        courseid:action.courseid,
        coursecard:action.coursecard,
        rounddate:action.rounddate,
        roundsss:action.roundsss,
        roundtype:action.roundtype,
        handicap:action.handicap,
        gender:action.gender,
        scores:action.scores,
        points:action.points,
        topar:action.topar,
        system:action.system,
        courserating:action.courserating,
        sloperating:action.sloperating,
        updated:true,
        viewname:action.viewname,
        loading:false
    });
}

const roundScoreReset = (state,action) => {
    return updateObject(state, {
        coursename:action.coursename,
        courseid:action.courseid,
        coursecard:action.coursecard,
        scores:action.scores,
        points:action.points,
        topar:action.topar,
        viewname:action.viewname
    });
}

const roundCompleteReset = (state,action) => {
    return updateObject(state, {
        coursename:action.coursename,
        courseid:action.courseid,
        coursecard:action.coursecard,
        rounddate:action.rounddate,
        roundsss:action.roundsss,
        roundtype:action.roundtype,
        handicap:action.handicap,
        gender:action.gender,
        scores:action.scores,
        points:action.points,
        topar:action.topar,
        roundid:action.roundid,
        updated:false,
        viewname:action.viewname,
        system:action.system,
        courserating:action.courserating,
        sloperating:action.sloperating,
        loading:false
    });
}

const roundViewUpdate = (state,action) => {
    return updateObject(state, {
        viewname:action.viewname
    });
}

const roundCalculate = (state,action) => {
    return updateObject(state, {
        points:action.points,
        topar:action.topar
    });
}

const updateHoleData = (state,action) => {
    
    const updateScores = [...state.scores];
    //console.log("action.par: ",action.par);
    let numbersObject = {}
    numbersObject[action.holeNumber] = [action.par,action.score,action.stroke];
    updateScores[action.holeNumber-1] = numbersObject;
    return updateObject(state, {
        scores:updateScores
    });
}

const addHoleData = (state,action) => {
    
    const updateScores = [...state.scores];
    let numbersObject = {}
    let currentScore = null;
    if(updateScores[action.holeNumber-1][action.holeNumber]){
        currentScore = updateScores[action.holeNumber-1][action.holeNumber][1]; //dont lose current score
    }
    numbersObject[action.holeNumber] = [action.par,currentScore,action.stroke];
    updateScores[action.holeNumber-1] = numbersObject;
    return updateObject(state, {
        scores:updateScores
    });
}

const addRoundHandicap = (state,action) => {
    return updateObject(state, {
        newHandicap:action.newHandicap,
        coursePar:action.coursePar,
        recordedHandicapIndex: action.recordedHandicapIndex,
        recordedPlayingHandicap: action.recordedPlayingHandicap,
        roundIndex: action.roundIndex,
        adjustedGross: action.adjustedGross
    });
}

const editRound = (state,action) => {
    return updateObject(state, {
        userid:action.userid,
        coursename:action.coursename,
        courseid:action.courseid,
        coursecard:action.coursecard,
        rounddate:action.rounddate,
        roundsss:action.roundsss,
        roundtype:action.roundtype,
        handicap:action.handicap,
        gender:action.gender,
        scores:action.scores,
        points:action.points,
        topar:action.topar,
        roundid:action.roundid,
        updated:true,
        viewname:action.viewname,
        system:action.system,
        courserating:action.courserating,
        sloperating:action.sloperating,
        loading:false
    });
}

const reducer = (state = intialState,action) => {   
    switch ( action.type ) {
        case actionTypes.ROUND_START: return roundStart(state,action);
        case actionTypes.ROUND_UPDATE: return roundUpdate(state,action);
        case actionTypes.ROUND_VIEW_UPDATE: return roundViewUpdate(state,action);
        case actionTypes.ROUND_HOLE_UPDATE: return updateHoleData(state,action);
        case actionTypes.ROUND_HOLE_ADD: return addHoleData(state,action);
        case actionTypes.ROUND_CALCULATE: return roundCalculate(state,action);
        case actionTypes.ROUND_SCORE_RESET: return roundScoreReset(state,action);
        case actionTypes.ROUND_FINISH: return addRoundHandicap(state,action);
        case actionTypes.ROUND_COMPLETE_RESET: return roundCompleteReset(state,action);
        case actionTypes.ROUND_EDIT: return editRound(state,action);
        default:
            return state;
    } 
}

export default reducer;