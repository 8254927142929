import React, {Component} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import EditIcon from '@material-ui/icons/Edit';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import {validateForm, validateUnit } from  '../../utils/validation';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import firebase from '../../Firebase.js';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { connect } from 'react-redux';

const paperStyle = {
    background: 'radial-gradient(farthest-corner at 90% 0,#08A6BE 0%, #0E3676 50%,  #08193C 100%)',
    borderRadius: 3,
    border:'1px solid #ffffff',
    margin: '24px'
};

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    fabProgress: {
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

class AddReviewOverlay extends Component {

    state = {
        open: true,
        requesting:false,
        form: {
            isValid:true,
            fields: {
                reviewername: {
                    value: '',
                    valid: true,
                    errorType: null,
                    rules: {
                        notEmpty : {
                            message: 'Please provide a name.'
                        },
                        minlength: {
                            message: 'Have you got a longer name.',
                            length: 5
                        },
                        maxlength: {
                            message: 'Have you got a shorter name.',
                            length: 20
                        }
                    }
                },
                reviewertitle: {
                    value: '',
                    valid: true,
                    errorType: null,
                    rules: {
                        notEmpty : {
                            message: 'Please provide a review title.'
                        },
                        minlength: {
                            message: 'Have you got a longer title.',
                            length: 5
                        },
                        maxlength: {
                            message: 'Have you got a shorter title.',
                            length: 150
                        }
                    }
                },
                review: {
                    value: '',
                    valid: true,
                    errorType: null,
                    rules: {
                        notEmpty : {
                            message: 'Please provide the review.'
                        },
                        minlength: {
                            message: 'Have you got a longer review.',
                            length: 5
                        },
                        maxlength: {
                            message: 'Have you got a shorter review.',
                            length: 500
                        }
                    }
                },
                rating: {
                    value: '5',
                    default: '5',
                    valid: true,
                    errorType: null,
                    rules: {
                        notEmpty : {
                            message: 'Required field.'
                        }
                    }
                }
            }
        }
    }

    componentWillMount() {
        
        const cachedState = {...this.state};
        const cachedProps = {...this.props};

        //need to update data not from elements
        if(cachedProps.userMeta){
            //console.log("update name and id",cachedProps);
            if(cachedProps.userMeta.displayname) {
                cachedState.form.fields.reviewername.value = cachedProps.userMeta.displayname;
            }
            this.setState(cachedState);
        }
    }

    submitHandler = (event) => {
        event.preventDefault();
        const validateFormState = validateForm(this.state.form);
        this.setState({form:validateFormState});
        if(validateFormState.isValid){
            this.setState({requesting:true});
            this.addCourseReview();
        }
    }

    addCourseReview = () => {
        
        let newReviews = this.props.currentReviews;
        newReviews.push({
            date_time: new Date(),
            review_rating: this.state.form.fields.rating.value,
            review_status: "1",
            review_text: this.state.form.fields.review.value,
            review_title: this.state.form.fields.reviewertitle.value,
            reviewer_name: this.state.form.fields.reviewername.value,
            reviewer_uid: this.props.userId
        });
        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);
        db.collection("courses").doc(this.props.courseid).update({
            reviews:newReviews
        })
        .then(() => {
            //console.log("review added all good!");
            db.collection("courses").doc(this.props.courseid)
                .get()
                .then((thisDoc) => {
                    const updatedReviews = thisDoc.data().reviews;
                    this.props.onClose(updatedReviews);
                });
            
        })
        .catch((error) => {
            this.props.onClose();
        });
    }

    inputChangedHandler = (event) => {
        const inputValue = event.target.value;
        const inputName = event.target.name;
        const updateState = {...this.state};
        updateState.form.fields[inputName].value = inputValue;
        this.setState(updateState);
    }

    blurField = (event) => {
        const fieldName = event.target.name;
        if(validateUnit(fieldName)){
            const updateState = {...this.state};
            console.log("fieldName: ",fieldName);
            updateState.form.fields[fieldName].errorType = false;
            updateState.form.fields[fieldName].valid = true;
            this.setState(updateState);
        }
    }

    render() {

        const { classes } = this.props;
        const buttonClassname = classNames({
            [classes.buttonSuccess]: true,
        });
        
        return (
            <Dialog
                open={true}
                onClose={() => {
                    this.props.onClose(null);
                }}
                aria-labelledby="form-dialog-title"
                className="gh-overlays"
                PaperProps={{className:'gh-overlays__paper',style:paperStyle}}
                >
                <DialogTitle className="gh-overlays__title" id="form-dialog-title">Add Review</DialogTitle>
                <DialogContent className="gh-overlays__content">

                    {this.props.userMeta ? 
                        <form className="form form--overlayed" onSubmit={this.submitHandler}>
                            <FormControl margin="normal" fullWidth>
                                <Typography>You are posting a review of {this.props.coursename}. Please use appropriate language for your review.</Typography>
                            </FormControl>
                            <FormControl margin="normal" fullWidth>
                                <InputLabel htmlFor="reviewername">Your Name</InputLabel>
                                <Input 
                                    className="form__input" 
                                    readOnly={true}
                                    id="reviewername" 
                                    startAdornment={
                                        <InputAdornment position="start">
                                        <EditIcon />
                                        </InputAdornment>
                                    }
                                    name="displayname" 
                                    value={this.props.userMeta.displayname ? this.props.userMeta.displayname : 'Not Set'}
                                    autoComplete="displayname" 
                                    />
                            </FormControl>

                            <FormControl margin="normal" className="form__formcontrol" fullWidth error={!this.state.form.fields.reviewertitle.valid}>
                                <InputLabel htmlFor="reviewertitle">Title</InputLabel>
                                <Input 
                                    onChange={this.inputChangedHandler}
                                    className="form__input" 
                                    id="reviewertitle" 
                                    ref={(this.state.form.fields.reviewertitle.ref) ? this.state.form.fields.reviewertitle.ref : null}
                                    
                                    onBlur={this.blurField}
                                    name="reviewertitle" 
                                    value={this.state.form.fields.reviewertitle.value}
                                    autoComplete="reviewertitle" 
                                        />
                                {!this.state.form.fields.reviewertitle.valid ?
                                    <FormHelperText id="reviewertitle-error">{this.state.form.fields.reviewertitle.rules[this.state.form.fields.reviewertitle.errorType].message}</FormHelperText>
                                : null }
                            </FormControl>

                            <FormControl margin="normal" className="form__formcontrol" fullWidth error={!this.state.form.fields.review.valid}>
                            
                                <TextField
                                    id="review"
                                    label="Review"
                                    multiline
                                    value={this.state.form.fields.review.value}
                                    ref={(this.state.form.fields.review.ref) ? this.state.form.fields.review.ref : null}                                    
                                    margin="normal"
                                    InputProps={{
                                        className:"form__input",
                                        onChange:this.inputChangedHandler,
                                        onBlur:this.blurField,
                                        name:"review"
                                    }}                          
                                    
                                    />                                
                                {!this.state.form.fields.review.valid ?
                                    <FormHelperText id="review-error">{this.state.form.fields.review.rules[this.state.form.fields.review.errorType].message}</FormHelperText>
                                : null }
                            </FormControl>

                            <FormControl margin="normal" fullWidth>
                                <RadioGroup
                                    className="form__group form__group--radio"
                                    aria-label="Rating"
                                    name="rating"
                                >
                                    <FormControlLabel value="1" control={<Radio onChange={this.inputChangedHandler} checked={this.state.form.fields.rating.value === '1'} />} label="1" />
                                    <FormControlLabel value="2" control={<Radio onChange={this.inputChangedHandler} checked={this.state.form.fields.rating.value === '2'} />} label="2" />
                                    <FormControlLabel value="3" control={<Radio onChange={this.inputChangedHandler} checked={this.state.form.fields.rating.value === '3'} />} label="3" />
                                    <FormControlLabel value="4" control={<Radio onChange={this.inputChangedHandler} checked={this.state.form.fields.rating.value === '4'} />} label="4" />
                                    <FormControlLabel value="5" control={<Radio onChange={this.inputChangedHandler} checked={this.state.form.fields.rating.value === '5'} />} label="5" />
                                </RadioGroup>
                            </FormControl>
                            
                            
                            <FormControl margin="normal" fullWidth>
                                <div className={classes.wrapper}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                        disabled={this.state.requesting}
                                        className={buttonClassname}
                                    >
                                        Save
                                    </Button>
                                    {this.state.requesting ? <CircularProgress size={24} className={classes.buttonProgress} /> : null}
                                </div>
                            </FormControl>
                        </form>
                    : null}
                </DialogContent>
            </Dialog>
        );
    }
};

const mapStateToProps = state => {
    return {
        userId: state.auth.userId,
        userMeta: state.auth.userMeta
    }
}

export default connect(mapStateToProps)(withStyles(styles)(AddReviewOverlay));