import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pux from '../../hoc/Pux';
import { Link } from 'react-router-dom';
import './AppBar.scss';
import { withStyles } from '@material-ui/core/styles';
import FlagIcon from '../../components/FlagIcon/FlagIcon';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import TimeIcon from '@material-ui/icons/AccessTime';
import HomeIcon from '@material-ui/icons/Home';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux'; 
import * as routes from '../../utils/routes.js';

const styles = theme => ({
    typography: {
      padding: theme.spacing(2),
    },
  });

export class AppBar extends Component {

    state = {
        anchorEl: null,
        open: false,
    };

    handleClick = event => {
        const { currentTarget } = event;
        this.setState(state => ({
          anchorEl: currentTarget,
          open: !state.open,
        }));
    };

    

    render() {
        const { classes } = this.props;
        const { anchorEl, open } = this.state;
        const id = open ? 'simple-popper' : null;
        const addRoundPath = this.props && this.props.userMeta && this.props.userMeta.whmode ? '/addroundwhs' : '/addround';

        return (
            <Pux>
                <footer className="gh-appbar">
                    {this.props.userMeta ? <Grid 
                        container
                        alignItems="center"
                        direction="row"
                        justify="center"
                        >   
                        <Grid item xs={4} className="gh-appbar__gridcol">
                            <IconButton className="gh-appbar__item gh-appbar__item--home" color="inherit" aria-label="Footer">
                                <Link to="/" className="gh-appbar__item-link">
                                    <HomeIcon />
                                    <Typography className="gh-appbar__item-desc">Home</Typography>
                                </Link>
                            </IconButton>
                        </Grid>
                        <Grid item xs={4} className="gh-appbar__gridcol">
                            <IconButton className="gh-appbar__item gh-appbar__item--addround" color="inherit" aria-label="Footer">
                                {this.props.userMeta.locked ? 
                                    <React.Fragment>
                                        <Popper id={id} open={open} anchorEl={anchorEl} transition>
                                        {({ TransitionProps }) => (
                                            <Fade {...TransitionProps} timeout={350}>
                                            <Paper className="gh-popper">
                                                <Typography className={classes.typography}>You have come to end of your free trial. Please upgrade to <Link className="gh-popper__link gh-popper__link--premium" to={routes.CONTENT_PREMIUM}>Premium</Link> to enable this feature.</Typography>
                                            </Paper>
                                            </Fade>
                                        )}
                                    </Popper>
                                    <div className="gh-appbar__item-link gh-appbar__item-link--disabled" onClick={this.handleClick}>
                                        <FlagIcon />
                                        {(this.props.round && this.props.round.scores[0][1].length) ? <Badge className="gh-appbar__item-badge" badgeContent={1} color="primary"><React.Fragment /></Badge> : null}
                                        <Typography className="gh-appbar__item-desc">Add Round</Typography>
                                    </div>
                                </React.Fragment>
                                : 
                                <Link to={addRoundPath} className="gh-appbar__item-link">
                                    <FlagIcon />
                                    {(this.props.round && this.props.round.scores[0][1].length) ? <Badge className="gh-appbar__item-badge" badgeContent={1} color="primary"><React.Fragment /></Badge> : null}
                                    <Typography className="gh-appbar__item-desc">Add Round</Typography>
                                </Link>
                                }
                            </IconButton>
                        </Grid>
                        <Grid item xs={4} className="gh-appbar__gridcol">
                            <IconButton className="gh-appbar__item gh-appbar__item--history" color="inherit" aria-label="Footer">
                                <Link to="/history" className="gh-appbar__item-link">
                                    <TimeIcon />
                                    <Typography className="gh-appbar__item-desc">History</Typography>
                                </Link>
                            </IconButton>
                        </Grid>
                    </Grid>
                    : null}
                </footer>
            </Pux>
        );
    }
}

AppBar.propTypes = {
  toggleDrawer: PropTypes.func
};

const mapStateToProps = state => {
    return {
        round: state.round,
        userMeta: state.auth.userMeta
    }
}

export default connect(mapStateToProps, null)(withStyles(styles)(AppBar));