import React, { Component } from 'react';
import '../../assets/scss/core.scss';
import './AddRound.scss';
import Header from '../../components/Header/Header';
import AppBar from '../AppBar/AppBar';
import ChooseScoreCardOverlay from '../ChooseScoreCardOverlay/ChooseScoreCardOverlay';
import {validateForm, scrollToRef, validateUnit } from  '../../utils/validation';
import { Redirect } from 'react-router-dom';
import * as routes from '../../utils/routes.js';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
//import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Pux from '../../hoc/Pux';
import ScreenLoading from '../../components/ScreenLoading/ScreenLoading';
import Heading from '../../components/Heading/Heading';
import SearchField from '../../components/SearchField/SearchField';
import ResetRoundOverlay from '../ResetRoundOverlay/ResetRoundOverlay';
//import firebase from '../../Firebase.js';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FlagIcon from '@material-ui/icons/GolfCourse';
import DateIcon from '@material-ui/icons/DateRange';
import SSSIcon from '@material-ui/icons/CalendarTodayOutlined';
import HelpIcon from '@material-ui/icons/HelpOutline';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import * as actionsCreators from '../../store/actions/index';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import theme from '../../theme';

const styles = theme => ({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      position: 'relative',
      margin: '8px 0'
    },
    fabProgress: {
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    customWidth: {
        width: 200,
    },
    noMaxWidth: {
        maxWidth: 'none',
    },
});


export class AddRound extends Component {

    state = {
        snackBarOpen: true,
        showPassword: false,
        courseratingTooltipOpen: false,
        sloperatingTooltipOpen: false,
        scoreCardCache:false,
        startAdding:false,
        updatedFromStorage:false,
        resetPageOverlay:false,
        resetScoresOverlay:false,
        formLoading:false,
        form: {
            isValid:true,
            firstError:null,
            isWHS: true,
            fields: {
                coursename: {
                    value: null, 
                    default: '',
                    valid: true,
                    id: null,
                    card: null,
                    errorType: null,
                    rules: {
                        notEmpty : {
                            message: 'Required field.'
                        },
                        maxlength: {
                            message: 'Course name too long.',
                            length: 100
                        },
                        courseId: {
                            message: 'You will need to select a course from the look up, if you cannot find it, please request a new course.',
                        }
                    }
                },
                rounddate: {
                    value: null,
                    default: new Date(),
                    valid: true,
                    errorType: null,
                    rules: {
                        notEmpty : {
                            message: 'Dont forget the date.'
                        },
                        maxdate: new Date()
                    }
                },
                roundtype: {
                    value: null,
                    default: '18hole',
                    valid: true,
                    errorType: null,
                    rules: {
                        notEmpty : {
                            message: 'Required field.'
                        }
                    }
                },
                sloperating: {
                    value: null,
                    default:'',
                    valid: true,
                    errorType: null,
                    rules: {
                        isNumeric: {
                            message: 'Format Error.',
                            length: 100
                        },
                    }
                },
                courserating: {
                    value: null,
                    default: '',
                    valid: true,
                    errorType: null,
                    rules: {
                        isNumeric : {
                            message: 'Format Error.'
                        },
                    }
                },
            }
        }
    }

    submitHandler = (event) => {
        event.preventDefault();
        const validateFormState = validateForm(this.state.form);
        this.setState({form:validateFormState,snackBarOpen:true}, () => {
            if(this.state.form.isValid){
                this.setState({formLoading:true});
                this.props.roundStart({
                    coursename:this.state.form.fields.coursename.value,
                    courseid:this.state.form.fields.coursename.id,
                    coursecard:this.state.form.fields.coursename.card,
                    rounddate:this.state.form.fields.rounddate.value,
                    roundtype:this.state.form.fields.roundtype.value,
                    courserating:this.state.form.fields.courserating.value,
                    sloperating:this.state.form.fields.sloperating.value,
                    scores:this.props.round.scores,
                    viewname:'addroundscores',
                    system: 'whs',
                    loading:true
                },this.props.userId);
                this.props.roundSave();
            } else {
                if(this.state.form.firstError){
                    scrollToRef(this.state.form.fields[this.state.form.firstError].ref);
                }
            }
        });
        
    }

    inputChangedHandler = (event) => {
        const inputValue = event.target.value;
        const inputName = event.target.name;
        
        if(inputName === 'roundtype') {
            this.editRoundTypeHandler(inputValue);
        } else {
            const updateState = {...this.state};
            updateState.form.fields[inputName].value = inputValue;
            this.setState(updateState);
        }
        
    }

    blurField = (event) => {
        const fieldName = event.target.name;
        if(validateUnit(fieldName)){
            const updateState = {...this.state};
            updateState.form.fields[fieldName].errorType = false;
            updateState.form.fields[fieldName].valid = true;
            this.setState(updateState);
        }
    }
    
    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        this.setState({snackBarOpen:false});
    };

    handleDateChange = date => {
        //console.log("date: ",date);
        const updateState = {...this.state};
        updateState.form.fields.rounddate.value = date;
        this.setState(updateState);
    };

    handleTooltipClose = target => {
        const targetName = `${target}TooltipOpen`;
        this.setState({ [targetName]: false });
    };
    
    handleTooltipOpen = target => {
        const targetName = `${target}TooltipOpen`;
        this.setState({ [targetName]: true });
    };

    updateFieldFromProps = (value,fieldname) => {
        const updateState = {...this.state};
        updateState.form.fields[fieldname].value = value;
        this.setState(updateState);
        return value;
    }

    intergiseArray = (holesData) => {
        //fix legacy data
        let newCard = {};
        for(let h in holesData){
            const holeNumber = h;
            const holeData = holesData[h];
            const [holePar, , holeStroke, holeYards] = holeData;
            newCard[holeNumber] = [parseInt(holePar), null, parseInt(holeStroke), parseInt(holeYards)]
        }
        return newCard;
    }

    fixLegacyData = (string) => {
        //set data
        if(!string){
            string = '[]';
        }
        return JSON.parse('{"scorecards":' + string + '}');
    }

    fixTeeScorecardData = (string) => {
        //set data
        if(!string){
            string = '[]';
        }
        
        try {
            JSON.parse('{"scorecards":' + string + '}');
        } catch (err) {
            //console.log("err: ",err);
            string = '[]';
        }
        let cardData = JSON.parse('{"scorecards":' + string + '}');
        if(cardData.scorecards.length){
            let tweakedArray = cardData.scorecards.map((card,i) => {
                let alignCard = {
                    par: card.meta.par,
                    sss: card.meta.sss,
                    yards: card.meta.yards,
                    name: card.name,
                    card: this.intergiseArray(card.meta.holedata),
                }
                return alignCard;
            });
            cardData.scorecards = tweakedArray;
        }
        return cardData;
    }

    onCourseSelect = (id,title,scorecards,teeScorecards) => {
        
        const scoreCardsData = this.fixLegacyData(scorecards);
        const teeScoreCardsData = this.fixTeeScorecardData(teeScorecards);
        //merge cards
        teeScoreCardsData.scorecards = teeScoreCardsData.scorecards.concat(scoreCardsData.scorecards);
        

        const updateState = {...this.state};
        updateState.form.fields.coursename.value = title;
        updateState.form.fields.coursename.id = id;
        this.setState(updateState);

        if(teeScoreCardsData.scorecards.length){
            this.setState({scoreCardCache:teeScoreCardsData.scorecards});
        }
    }

    closeScoreCardOverlay = () => {
        this.setState({scoreCardCache:null});
    }
    
    roundInProgress = () => {
        const checkRound = {...this.props.round};
        if(checkRound.scores){
            return checkRound.scores[0][1].length;
        } else {
            return false;
        }
    }

    editCourseHandler = () => {
        if(this.roundInProgress()) {
            const updateState = {...this.state};
            updateState.resetPageOverlay = true; //show warning if there was a previously selected course
            updateState.resetPageOverlayTitle = 'Changing Course';
            updateState.resetPageOverlayDesc = 'Changing the course will remove any previously entered scores, are you sure you want to do do this?';
            updateState.resetPageOverlayCallback = this.enableCourseField;
            this.setState(updateState);
        } else {
           this.enableCourseField(); 
        }
        
    }

    editRoundTypeHandler = type => {

        if(this.roundInProgress()) {
            const updateState = {...this.state};
            updateState.resetScoresOverlay = true;//show warning if there was a previously selected course
            updateState.resetScoresOverlayTitle = 'Changing Round Type';
            updateState.resetScoresOverlayDesc = 'Changing the round type will remove previously entered scores,  are you sure you want to do do this?';
            updateState.resetScoresOverlayCallback = () => {
                this.setRoundType(type);
            };
            this.setState(updateState);
        } else {
            this.setRoundType(type);
        }
    }

    setRoundType = type => {
        
        const updateState = {...this.state};
        
        updateState.form.fields.roundtype.value = type;

        if(updateState.form.fields.courserating.value) {
            // make sure sss fits round type, i.e 9hole or 18 hole
            const currentSSS = parseInt(updateState.form.fields.courserating.value);
            if(type === '9hole') {
                const looksRight = currentSSS < 50;
                if(!looksRight) {
                    updateState.form.fields.courserating.value = Math.round(currentSSS / 2);
                }
            } else {
                const looksRight = currentSSS > 50;
                if(!looksRight) {
                    updateState.form.fields.courserating.value = currentSSS * 2;
                }
            }
            
        }

        updateState.resetPageOverlay = false;
        this.setState(updateState);
    }

    enableCourseField = () => {
        const updateState = {...this.state};
        updateState.form.fields.coursename.value = '';
        updateState.form.fields.coursename.id = null;
        updateState.form.fields.sloperating.value = '';
        updateState.form.fields.courserating.value = '';
        updateState.resetPageOverlay = false;
        this.setState(updateState);
    }

    removeScoreFromScoreCard = (cardData) => {
        let rawHoleData = cardData.card;
        let newCard = {}
        for(let h in rawHoleData){
            let values = rawHoleData[h];
            values[1] = null;//set score to null
            newCard[h] = values;
        }
        cardData.card = newCard;
        return cardData; 
    }

    selectScorecard = (cardData) => {
        //console.log("select card: ",cardData);
        const updateState = {...this.state};
        updateState.form.fields.coursename.card = this.removeScoreFromScoreCard(cardData);
        
        const isNine = updateState.form.fields.roundtype.value === '9hole';

        updateState.form.fields.courserating.value = isNine ? Math.round(parseInt(cardData.courseRating) / 2) : cardData.courseRating;
        updateState.form.fields.sloperating.value = cardData.slopeRating;
        updateState.scoreCardCache = null;
        this.setState(updateState);
    }

    componentWillMount() {

        document.title = 'Golf Handicapp - Add Round';
        
        const cachedState = {...this.state};
        const cachedProps = {...this.props};

        //need to update data not from elements
        if(this.props.round.coursename){
            cachedState.form.fields.coursename.value = cachedProps.round.coursename;
            cachedState.form.fields.coursename.card = cachedProps.round.coursecard;
            cachedState.form.fields.coursename.id = cachedProps.round.courseid;
            cachedState.form.fields.rounddate.value = cachedProps.round.rounddate;
            cachedState.form.fields.courserating.value = cachedProps.round.courserating;
            cachedState.form.fields.roundtype.value = cachedProps.round.roundtype;
            cachedState.form.fields.sloperating.value = cachedProps.round.sloperating;
        } else {
            cachedState.form.fields.coursename.value = cachedState.form.fields.coursename.default;
            cachedState.form.fields.coursename.card = null;
            cachedState.form.fields.coursename.id = null;
            cachedState.form.fields.rounddate.value = cachedState.form.fields.rounddate.default;
            cachedState.form.fields.courserating.value = cachedState.form.fields.courserating.default;
            cachedState.form.fields.roundtype.value = cachedState.form.fields.roundtype.default;
            cachedState.form.fields.sloperating.value = cachedState.form.fields.sloperating.default;
        }
        //update date
        this.setState(cachedState);

        
        
    }

    closeResetRoundOverlay = () => {
        this.setState({
            resetPageOverlay:false,
            resetPageOverlayTitle:'',
            resetPageOverlayDesc:'',
            resetScoresOverlay:false,
            resetScoresOverlayTitle:'',
            resetScoresOverlayDesc:''
        });
    }

    render() {

        const { classes } = this.props;
        const buttonClassname = classNames({
        [classes.buttonSuccess]: true,
        });

        let redirectPage = null;
        if(this.props.round.viewname === 'addroundscores'){
            redirectPage = <Redirect to={{
                pathname: "/addroundscores"
              }}  />;
        }
        if(this.props.userMeta && this.props.userMeta.locked){
            redirectPage = <Redirect to={{
                pathname: routes.CONTENT_PREMIUM
              }}  />;
        }

        return (
            <Pux>
                {redirectPage}
                <Header />
                <div className="gh-content">
                    <Heading type="h1" variant="central">Add Round</Heading>

                    <Tabs
                        className="gh-scrolltabs__tab gh-scrolltabs__tab--form"
                        value="WHS"
                        onChange={() => {
                            if (this.roundInProgress()) {
                                console.log("yes");
                                const updateState = {...this.state};
                                updateState.resetPageOverlay = true;//show warning if there was a previously selected course
                                updateState.resetPageOverlayTitle = 'Changing System';
                                updateState.resetPageOverlayDesc = 'Changing the system will remove any previously added scores, are you sure you want to do do this?';
                                updateState.resetPageOverlayCallback = () => {
                                    this.props.history.push("/addround/");
                                };
                                this.setState(updateState);
                            } else {
                                this.props.history.push("/addround/");
                            }
                        }}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label="World Handicap System" value="WHS" />
                        <Tab label="Standard Scratch Score" value="LEGACY" />
                    </Tabs>
                    {this.props.userMeta ? 
                        <Pux>
                            <form className="form" onSubmit={this.submitHandler}>


                                <SearchField 
                                    isValid={this.state.form.fields.coursename.valid}
                                    error={!this.state.form.fields.coursename.valid && this.state.form.fields.coursename.rules[this.state.form.fields.coursename.errorType].message}
                                    fieldName="coursename"
                                    fieldLabel="Course Name"
                                    whsMode
                                    onCourseSelect={this.onCourseSelect}
                                    inputProps={{
                                        onChange:this.inputChangedHandler,
                                        onBlur:this.blurField,
                                        ref:(this.state.form.fields.coursename.ref) ? this.state.form.fields.coursename.ref : null,
                                        className:"form__input",
                                        readOnly:this.state.form.fields.coursename.id != null,
                                        id:"coursename",
                                        startAdornment:(
                                            <InputAdornment position="start">
                                                <FlagIcon />
                                            </InputAdornment>
                                        ),
                                        endAdornment:this.state.form.fields.coursename.id != null && (
                                            <InputAdornment position="end">
                                                <Button size="small" onClick={this.editCourseHandler}>Edit</Button>
                                            </InputAdornment>
                                        ),
                                        name:"coursename",
                                        value:this.state.form.fields.coursename.value,
                                        inputProps:{autoComplete:"off"}
                                    }}
                                    
                                    />
                                <FormControl margin="normal" fullWidth error={!this.state.form.fields.rounddate.valid}>
                                    <MuiPickersUtilsProvider theme={theme} utils={DateFnsUtils} >
                                        <DatePicker
                                            InputProps={{
                                                startAdornment : (
                                                    <InputAdornment position="start">
                                                        <DateIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            ref={(this.state.form.fields.rounddate.ref) ? this.state.form.fields.rounddate.ref : null}
                                            maxDate={this.state.form.fields.rounddate.rules.maxdate}
                                            className="form__input form__input--datepicker"
                                            label="Round Date"
                                            value={this.state.form.fields.rounddate.value}
                                            onChange={this.handleDateChange}
                                        />
                                    </MuiPickersUtilsProvider>
                                        {!this.state.form.fields.rounddate.valid ?
                                        <FormHelperText id="rounddate-error">{this.state.form.fields.rounddate.rules[this.state.form.fields.rounddate.errorType].message}</FormHelperText>
                                    : null }
                                </FormControl>

                                <FormControl margin="normal" fullWidth>
                                    <RadioGroup
                                        className="form__group form__group--radio"
                                        aria-label="Round Type"
                                        name="roundtype"
                                    >
                                        <FormControlLabel value="18hole" control={<Radio onChange={this.inputChangedHandler} checked={this.state.form.fields.roundtype.value === '18hole'} />} label="18 Holes" />
                                        <FormControlLabel value="9hole" control={<Radio onChange={this.inputChangedHandler} checked={this.state.form.fields.roundtype.value === '9hole'} />} label="9 Holes" />
                                        
                                    </RadioGroup>
                                </FormControl>

                                

                                <FormControl margin="normal" className="form__formcontrol" fullWidth error={!this.state.form.fields.courserating.valid}>
                                    <InputLabel htmlFor="courserating">Course Rating</InputLabel>
                                    <Input 
                                        onChange={this.inputChangedHandler}
                                        className="form__input" 
                                        id="courserating" 
                                        type="text"
                                        step="any"  
                                        ref={(this.state.form.fields.courserating.ref) ? this.state.form.fields.courserating.ref : null}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <SSSIcon />
                                            </InputAdornment>
                                        }
                                        onBlur={this.blurField}
                                        endAdornment={
                                            <InputAdornment position="start">
                                                <ClickAwayListener onClickAway={() => this.handleTooltipClose('courserating')}>
                                                    <Tooltip
                                                    PopperProps={{
                                                        disablePortal: true,
                                                    }}
                                                    placement="top-end"
                                                    classes={{ tooltip: classes.customWidth }}
                                                    onClose={() => this.handleTooltipClose('courserating')}
                                                    open={this.state.courseratingTooltipOpen}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title="A course rating represents the expected score for a scratch player. i.e 70.8"
                                                    >
                                                        <HelpIcon onClick={() => this.handleTooltipOpen('courserating')} />
                                                    </Tooltip>
                                                </ClickAwayListener>
                                            </InputAdornment>
                                        }
                                        name="courserating" 
                                        value={this.state.form.fields.courserating.value}
                                        autoComplete="courserating" 
                                         />
                                    {!this.state.form.fields.courserating.valid ?
                                        <FormHelperText id="courserating-error">{this.state.form.fields.courserating.rules[this.state.form.fields.courserating.errorType].message}</FormHelperText>
                                    : null }
                                </FormControl>

                                <FormControl margin="normal" className="form__formcontrol" fullWidth error={!this.state.form.fields.sloperating.valid}>
                                    <InputLabel htmlFor="sloperating">Slope Rating</InputLabel>
                                    <Input 
                                        onChange={this.inputChangedHandler}
                                        className="form__input" 
                                        id="sloperating" 
                                        type="text"
                                        step="any"
                                        ref={(this.state.form.fields.sloperating.ref) ? this.state.form.fields.sloperating.ref : null}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <SSSIcon />
                                            </InputAdornment>
                                        }
                                        onBlur={this.blurField}
                                        endAdornment={
                                            <InputAdornment position="start">
                                                <ClickAwayListener onClickAway={() => this.handleTooltipClose('sloperating')}>
                                                    <Tooltip
                                                    PopperProps={{
                                                        disablePortal: true,
                                                    }}
                                                    placement="top-end"
                                                    classes={{ tooltip: classes.customWidth }}
                                                    onClose={() => this.handleTooltipClose('sloperating')}
                                                    open={this.state.sloperatingTooltipOpen}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title="A slope rating is the relative difficulty of a golf course between the scratch player and all other players. i.e 118"
                                                    >
                                                        <HelpIcon onClick={() => this.handleTooltipOpen('sloperating')} />
                                                    </Tooltip>
                                                </ClickAwayListener>
                                            </InputAdornment>
                                        }
                                        name="sloperating" 
                                        value={this.state.form.fields.sloperating.value}
                                        autoComplete="sloperating" 
                                         />
                                    {!this.state.form.fields.sloperating.valid ?
                                        <FormHelperText id="roundsss-error">{this.state.form.fields.sloperating.rules[this.state.form.fields.sloperating.errorType].message}</FormHelperText>
                                    : null }
                                </FormControl>

                                <FormControl margin="normal" fullWidth>
                                    <div className={classes.wrapper}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            size="large"
                                            variant="contained"
                                            color="primary"
                                            disabled={this.state.formLoading}
                                            className={buttonClassname}
                                        >
                                            {(this.props.round && this.props.round.scores[0][1].length) ? "Resume Adding Scores" : "Start Adding Scores"}
                                        </Button>
                                        {this.state.formLoading ? <CircularProgress size={24} className={classes.buttonProgress} /> : null}
                                    </div>
                                </FormControl>
                                <div className="form__alts form__alts--clearance">
                                    {this.roundInProgress() ? <Button size="small" color="inherit" className="form__alts-button" onClick={() =>  {
                                        this.setState({
                                            resetPageOverlay:true,
                                            resetPageOverlayTitle:"Removing Scores",
                                            resetPageOverlayDesc:"We will now remove any scorecard data entered, Are you sure you want to do this?",
                                            resetPageOverlayCallback:this.enableCourseField
                                        });
                                    }}><DeleteIcon size="small" color="inherit" className="form__alts-button-icon" />Remove Scores Entered</Button>
                                    : null}
                                </div>
                            </form>
                        </Pux>    
                         : <ScreenLoading />}
                        
                        {this.state.scoreCardCache ? <ChooseScoreCardOverlay whsMode selectCard={this.selectScorecard} closeOverlay={this.closeScoreCardOverlay} scorecards={this.state.scoreCardCache} /> : null}
                        
                        {this.state.resetPageOverlay ? 
                            <ResetRoundOverlay callback={this.state.resetPageOverlayCallback} closeResetRoundOverlay={this.closeResetRoundOverlay} title={this.state.resetPageOverlayTitle} description={this.state.resetPageOverlayDesc} />
                        : null}

                        {this.state.resetScoresOverlay ? 
                            <ResetRoundOverlay scoresOnly={true} closeResetRoundOverlay={this.closeResetRoundOverlay} title={this.state.resetScoresOverlayTitle} description={this.state.resetScoresOverlayDesc} />
                        : null}
                </div>
                <AppBar selected="addround" />
            </Pux>
        );
    }
}
/*
AddRound.propTypes = {
    searchTypeHandler: PropTypes.func
}*/

const mapStateToProps = state => {
    return {
        userMeta: state.auth.userMeta,
        round: state.round,
        startLoading: state.round.loading,
        userId: state.auth.userId
    }
}

const mapDispatchToProps = dispatch =>  {
    return {
        roundStart: (fields,userId) => dispatch(actionsCreators.roundStart(fields,userId)),
        roundSave: () => dispatch(actionsCreators.roundSave()),
        roundRecover: () => dispatch(actionsCreators.roundRecover())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddRound));
