import React, { Component } from 'react';
import { renderEmail } from  '../../utils/emails';
import Header from '../../components/Header/Header';
import AppBar from '../AppBar/AppBar';
import {validateForm, scrollToRef, validateUnit } from  '../../utils/validation';
//import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Pux from '../../hoc/Pux';
import ScreenLoading from '../../components/ScreenLoading/ScreenLoading';
import Heading from '../../components/Heading/Heading';
import IconButton from '@material-ui/core/IconButton';
import firebase from '../../Firebase';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import ChatIcon from '@material-ui/icons/Chat';
import MailIcon from '@material-ui/icons/MailOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import SuccessIcon from '@material-ui/icons/CheckCircleOutlined';



const styles = theme => ({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      position: 'relative',
      margin: '8px 0'
    },
    fabProgress: {
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    customWidth: {
        width: 200,
    },
    noMaxWidth: {
        maxWidth: 'none',
    },
});


export class ContactUs extends Component {

    state = {
        showSnackBar:false,
        snackBarMessage:'Thanks we have received your Message. We hope get back to you in the next 24-48 hours',
        snackBarType: 'success',
        showPassword: false,
        sssTooltipOpen:false,
        scoreCardCache:false,
        startAdding:false,
        updatedFromStorage:false,
        resetPageOverlay:false,
        formLoading:false,
        form: {
            isValid:true,
            firstError:null,
            fields: {
                contactusname: {
                    value: '', 
                    default: '',
                    valid: true,
                    errorType: null,
                    rules: {
                        notEmpty : {
                            message: 'Required field.'
                        },
                        maxlength: {
                            message: 'Name too long.',
                            length: 100
                        }
                    }
                },
                contactusemail: {
                    value: '', 
                    default: '',
                    valid: true,
                    errorType: null,
                    rules: {
                        notEmpty : {
                            message: 'Dont forget your email.'
                        },
                        emailFormat : {
                            message: 'Oops, your email address doesn\'t look right.'
                        },
                        maxlength: {
                            message: 'Have you got a shorter email?',
                            length: 50
                        }
                    }
                },
                contactusmessage: {
                    value: '', 
                    default: '',
                    valid: true,
                    errorType: null,
                    rules: {
                        notEmpty : {
                            message: 'Required field.'
                        },
                        maxlength: {
                            message: 'Message too long.',
                            length: 1000
                        }
                    }
                }
            }
        }
    }

    submitHandler = (event) => {
        event.preventDefault();
        const validateFormState = validateForm(this.state.form);
        this.setState({form:validateFormState,snackBarOpen:true}, () => {
            if(this.state.form.isValid){
                this.setState({formLoading:true});
                this.sendMessage();
            } else {
                if(this.state.form.firstError){
                    scrollToRef(this.state.form.fields[this.state.form.firstError].ref);
                }
            }
        });
        
    }

    inputChangedHandler = (event) => {
        const inputValue = event.target.value;
        const inputName = event.target.name;
        const updateState = {...this.state};
        updateState.form.fields[inputName].value = inputValue;
        this.setState(updateState);
    }

    blurField = (event) => {
        const fieldName = event.target.name;
        if(validateUnit(fieldName)){
            const updateState = {...this.state};
            updateState.form.fields[fieldName].errorType = false;
            updateState.form.fields[fieldName].valid = true;
            this.setState(updateState);
        }
    }
    
    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        this.setState({snackBarOpen:false});
    };

    handleDateChange = date => {
        //console.log("date: ",date);
        const updateState = {...this.state};
        updateState.form.fields.rounddate.value = date;
        this.setState(updateState);
    };

    handleTooltipClose = () => {
        this.setState({ sssTooltipOpen: false });
    };
    
    handleTooltipOpen = () => {
        this.setState({ sssTooltipOpen: true });
    };

    updateFieldFromProps = (value,fieldname) => {
        const updateState = {...this.state};
        updateState.form.fields[fieldname].value = value;
        this.setState(updateState);
        return value;
    }

    sendMessage = () => {
        const sendMessage = firebase.functions().httpsCallable('sendMessage');
        const emailHTML = renderEmail({
            title: 'Contact Form Message',
            content: `
                        <span style="display:block;">Name: ${this.state.form.fields.contactusname.value} </span>
                        <span style="display:block;">Email: ${this.state.form.fields.contactusemail.value} </span>
                        <span style="display:block;">Message: ${this.state.form.fields.contactusmessage.value} </span>
                    `
            }
        );
        const emailData = {
            emailHTML : emailHTML,
            subject: 'Contact Form Message',
            fromEmail: this.state.form.fields.contactusemail.value,
            fromName: this.state.form.fields.contactusname.value
        }
        
        sendMessage(emailData,'context').then((result) => {
            // Read result of the Cloud Function.
            //console.log("result: ",result);
            const cachedState = {...this.state}
            cachedState.formLoading = false;
            cachedState.showSnackBar = true;
            cachedState.snackBarMessage = 'Thanks we have received your message. We hope to get back to you in the next 24-48 hours';
            cachedState.snackBarType = 'success';
            cachedState.form.fields['contactusname'].value = '';
            cachedState.form.fields['contactusemail'].value = '';
            cachedState.form.fields['contactusmessage'].value = '';
            this.setState(cachedState);
            
        });
    }
   

    componentWillMount() {

        
        const cachedState = {...this.state};
        const cachedProps = {...this.props};

        //need to update data not from elements
        if(cachedProps.userMeta){
            //console.log("update name and id",cachedProps);
            if(cachedProps.userMeta.displayname) {
                cachedState.form.fields.contactusname.value = cachedProps.userMeta.displayname;
            }
            this.setState(cachedState);
        }
    }

    backClick = () => {
        this.props.history.goBack();
    }

    closeSnackBar = () => {
        this.setState({showSnackBar:false});
    }

   

    render() {

        const { classes } = this.props;
        const buttonClassname = classNames({
            [classes.buttonSuccess]: true,
        });

        return (
            <Pux>
                <Header backClick={this.backClick} />
                <div className="gh-content">
                    <Heading type="h1" variant="central">Get in touch</Heading>
                    {this.props.userMeta ? 
                        <Pux>
                            <form className="form" onSubmit={this.submitHandler}>

                               <Typography>We will aim get back to you as soon as possible usually within 48 hours.</Typography>
                               
                                <FormControl margin="normal" className="form__formcontrol" fullWidth error={!this.state.form.fields.contactusname.valid}>
                                    <InputLabel htmlFor="contactusname">Your Name</InputLabel>
                                    <Input 
                                        onChange={this.inputChangedHandler}
                                        className="form__input" 
                                        id="contactusname" 
                                        ref={(this.state.form.fields.contactusname.ref) ? this.state.form.fields.contactusname.ref : null}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <EditIcon />
                                            </InputAdornment>
                                        }
                                        onBlur={this.blurField}
                                        name="contactusname" 
                                        value={this.state.form.fields.contactusname.value}
                                        autoComplete="contactusname" 
                                         />
                                    {!this.state.form.fields.contactusname.valid ?
                                        <FormHelperText id="contactusname-error">{this.state.form.fields.contactusname.rules[this.state.form.fields.contactusname.errorType].message}</FormHelperText>
                                    : null }
                                </FormControl>
                                <FormControl margin="normal" className="form__formcontrol" fullWidth error={!this.state.form.fields.contactusemail.valid}>
                                    <InputLabel htmlFor="contactusemail">Your Email</InputLabel>
                                    <Input 
                                        onChange={this.inputChangedHandler}
                                        className="form__input" 
                                        id="contactusemail" 
                                        ref={(this.state.form.fields.contactusemail.ref) ? this.state.form.fields.contactusemail.ref : null}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <MailIcon />
                                            </InputAdornment>
                                        }
                                        onBlur={this.blurField}
                                        name="contactusemail" 
                                        value={this.state.form.fields.contactusemail.value}
                                        autoComplete="contactusemail" 
                                         />
                                    {!this.state.form.fields.contactusemail.valid ?
                                        <FormHelperText id="contactusemail-error">{this.state.form.fields.contactusemail.rules[this.state.form.fields.contactusemail.errorType].message}</FormHelperText>
                                    : null }
                                </FormControl>
                                <FormControl margin="normal" fullWidth error={!this.state.form.fields.contactusmessage.valid}>
                                    <InputLabel htmlFor="contactusmessage">Your Message</InputLabel>
                                    <Input 
                                        onChange={this.inputChangedHandler}
                                        className="form__input" 
                                        id="contactusmessage" 
                                        onBlur={this.blurField}
                                        ref={(this.state.form.fields.contactusmessage.ref) ? this.state.form.fields.contactusmessage.ref : null}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <ChatIcon />
                                            </InputAdornment>
                                        }
                                        name="contactusmessage" 
                                        value={this.state.form.fields.contactusmessage.value}
                                        autoComplete="contactusmessage" 
                                         />
                                    {!this.state.form.fields.contactusmessage.valid ?
                                        <FormHelperText id="contactusmessage-error">{this.state.form.fields.contactusmessage.rules[this.state.form.fields.contactusmessage.errorType].message}</FormHelperText>
                                    : null }
                                </FormControl>
                                
                                <FormControl margin="normal" fullWidth>
                                    <div className={classes.wrapper}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            size="large"
                                            variant="contained"
                                            color="primary"
                                            disabled={this.state.formLoading}
                                            className={buttonClassname}
                                        >
                                            Send Message
                                        </Button>
                                        {this.state.formLoading ? <CircularProgress size={24} className={classes.buttonProgress} /> : null}
                                    </div>
                                </FormControl>
                                
                                <Snackbar
                                    className={(this.state.snackBarType === 'success') ? 'gh-snackBar gh-snackBar--success' : 'gh-snackBar'}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    open={this.state.showSnackBar}
                                    autoHideDuration={6000}
                                    onClose={this.closeSnackBar}
                                    ContentProps={{
                                        'aria-describedby': 'message-id',
                                    }}
                                    message={
                                        <span id="message-id" className="gh-snackBar__message">
                                            <SuccessIcon className="gh-snackBar__message-svg" />
                                            {this.state.snackBarMessage}
                                        </span>
                                    }
                                    action={[
                                        <IconButton
                                            key="close"
                                            aria-label="Close"
                                            color="inherit"
                                            onClick={this.closeSnackBar}
                                            >
                                        <CloseIcon />
                                        </IconButton>
                                    ]}
                                    />
                            </form>
                        </Pux>    
                         : <ScreenLoading />}
                </div>
                <AppBar selected="home" />
            </Pux>
        );
    }
}
/*
ContactUs.propTypes = {
    searchTypeHandler: PropTypes.func
}*/

const mapStateToProps = state => {
    return {
        userMeta: state.auth.userMeta,
        userId: state.auth.userId
    }
}

const mapDispatchToProps = dispatch =>  {
    return {
        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ContactUs));
