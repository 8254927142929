import React from 'react';

const datedisplay = (props) => {
    let dateDisplay = null;
    if(props.rawdate){
        dateDisplay = new Date(props.rawdate);
    } else {
        dateDisplay = props.date;
    }

    if(dateDisplay.seconds) {
        dateDisplay = new Date(dateDisplay.seconds * 1000);
    }
    
    const DATE_DEFAULTS = { 
        weekday: 'short', 
        year: 'numeric', 
        month: 'short', 
        day: 'numeric'
    };
    const DATE_OPTIONS = props.format ? {...DATE_DEFAULTS,...props.format} : DATE_DEFAULTS;
    
    return (
        <React.Fragment>
            {dateDisplay.toLocaleDateString('en-GB', DATE_OPTIONS)}
        </React.Fragment>
    );
};
export default datedisplay;