import React, { Component } from 'react';
import '../../assets/scss/core.scss';
import './AddRoundScores.scss';
import Header from '../../components/Header/Header';
import AppBar from '../../containers/AppBar/AppBar';
import {validateForm, scrollToRef } from  '../../utils/validation';
//import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Pux from '../../hoc/Pux';
import Heading from '../../components/Heading/Heading';
import LiveScores from '../../components/LiveScores/LiveScores';
import HoleEntry from '../../components/HoleEntry/HoleEntry';
import ScreenLoading from '../../components/ScreenLoading/ScreenLoading';
import HoleSetupOverlay from '../../containers/HoleSetupOverlay/HoleSetupOverlay';
import { getWHSPlayingHandicap } from  '../../store/actions/handicap';
//import firebase from '../../Firebase.js';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Settings';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import SecurityIcon from '@material-ui/icons/Security';
import LocationIcon from '@material-ui/icons/LocationOn';
import Chip from '@material-ui/core/Chip';
import PublicIcon from '@material-ui/icons/Public';
import * as actionsCreators from '../../store/actions/index';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Typography } from '@material-ui/core';

const styles = theme => ({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      position: 'relative',
      margin: '8px 0'
    },
    fabProgress: {
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    customWidth: {
        width: 200,
    },
    noMaxWidth: {
        maxWidth: 'none',
    },
});


export class AddRoundScores extends Component {

    state = {
        snackBarOpen: true,
        showPassword: false,
        sssTooltipOpen:false,
        scoreCardCache:false,
        holeSetupNumber:false,
        isNine: false,
        form: {
            isValid:true,
            fields: {}
        }
    }

    componentWillMount() {
        document.title = 'Golf Handicap - Adding Round Scores';
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.round.scores){
            this.updatePropsToState(nextProps.round);
        }
    }

    updatePropsToState = (round) => {
        const updateState = {...this.state};
        
        const holes = round.roundtype === '9hole' ? 9 : 18;

        [...Array(holes)].map((_, i) => {
            const holeNumber = i+1;
            const useProps = round.scores[holeNumber-1];
            let holeScore = null;
            for(let h in useProps){
                const holeValuesArray = useProps[h];
                if(holeValuesArray.length && holeValuesArray[1]){
                    holeScore = holeValuesArray[1];
                }
            }
            updateState.form.fields["hole"+holeNumber] = {
                value: holeScore,
                valid: true,
                errorType: null,
                rules: {
                    radioSelected : {
                        message: 'Score required.'
                    }
                }
            }
            return _;
        });
        this.setState(updateState);
    }

    componentDidMount() {
        //add dynamic fields
        if(this.props.round.scores){
            this.updatePropsToState(this.props.round);
        }
    }

    submitHandler = (event) => {
        event.preventDefault();
        const validateFormState = validateForm(this.state.form);
        this.setState({form:validateFormState}, () => {
            if(this.state.form.isValid){
                this.setState({loading:true});
                this.props.roundComplete();
            } else {
                if(this.state.form.firstError){
                    scrollToRef(this.state.form.fields[this.state.form.firstError].ref);
                }
            }
        });
    }

    inputChangedHandler = (event) => {
        const inputValue = event.target.value;
        const inputName = event.target.name;
        const updateState = {...this.state};
        updateState.form.fields[inputName].value = inputValue;
        this.setState(updateState);
    }
    
    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        this.setState({snackBarOpen:false});
    };

    handleDateChange = date => {
        const updateState = {...this.state};
        updateState.form.fields.rounddate.value = date;
        this.setState(updateState);
    };

    handleTooltipClose = () => {
        this.setState({ sssTooltipOpen: false });
    };
    
    handleTooltipOpen = () => {
        this.setState({ sssTooltipOpen: true });
    };

    updateFieldFromProps = (value,fieldname) => {
        const updateState = {...this.state};
        updateState.form.fields[fieldname].value = value;
        this.setState(updateState);
        return value;
    }

    fixLegacyData = (string) => {
        //set data
        if(!string){
            string = '[]';
        }
        return JSON.parse('{"scorecards":' + string + '}');
    }

    onCourseSelect = (id,title,scorecards) => {
        
        const scoreCardsData = this.fixLegacyData(scorecards);
        const updateState = {...this.state};
        updateState.form.fields.coursename.value = title;
        updateState.form.fields.coursename.id = id;
        this.setState(updateState);

        if(scoreCardsData.scorecards.length){
            this.setState({scoreCardCache:scoreCardsData.scorecards});
        }
    }

    closeScoreCardOverlay = () => {
        this.setState({scoreCardCache:null});
    }

    enableCourseField = () => {
        const updateState = {...this.state};
        updateState.form.fields.coursename.value = '';
        updateState.form.fields.coursename.id = null;
        this.setState(updateState);
    }

    selectScorecard = (cardData) => {
        const updateState = {...this.state};
        updateState.form.fields.coursename.card = cardData;
        updateState.form.fields.roundsss.value = cardData.sss;
        updateState.scoreCardCache = null;
        this.setState(updateState);
    }

    editMeta = () => {
        this.props.roundViewUpdate('addround');
    }

    scoreSelected = (holeNumber,score,par,stroke) => {
        const updateState = {...this.state};
        updateState.form.fields["hole"+holeNumber].value = score;
        updateState.form.fields["hole"+holeNumber].valid = true;
        this.setState(updateState);

        this.props.updateHoleData(holeNumber,score,par,stroke);
        this.props.roundSave();
    }

    holeSetup = (holeNumber) => {
        this.setState({holeSetupNumber:holeNumber});
    }

    closeHoleSetupOverlay = () => {
        this.setState({holeSetupNumber:false});
    }

    backClick = () => {
        this.props.roundViewUpdate('addround');
    }

    render() {

        const { classes } = this.props;
        const buttonClassname = classNames({
            [classes.buttonSuccess]: true,
        });
        const whsMode = this.props.round.system === 'whs';

        let redirectBack = null;
        if(this.props.round.viewname.indexOf("round/") !== -1 || this.props.round.viewname === 'addround') {
            let redirectPath = whsMode && this.props.round.viewname === 'addround' ? 'addroundwhs' : 'addround';
            if(this.props.round.viewname.indexOf("round/") !== -1) {
                redirectPath = this.props.round.viewname;
            }
            redirectBack = <Redirect to={{
                pathname: redirectPath
              }}  />;
        }

        let overlay = null;
        if(this.state.holeSetupNumber){
            let overlayHoleArray = null
            let cacheScores = this.props.round.scores[(this.state.holeSetupNumber-1)];
            let overlayHoleData = {...cacheScores};

            for(let d in overlayHoleData){
                if(overlayHoleData[d].length){
                    overlayHoleArray = overlayHoleData[d];
                }
            }
            if(!overlayHoleArray){
                //no hole scores so get from template
                let template = {...this.props.round.coursecard};
                if(template && template.card){
                    overlayHoleArray = template.card[(this.state.holeSetupNumber)];
                }
            }
            overlay = <HoleSetupOverlay 
                        closeOverlay={this.closeHoleSetupOverlay} 
                        holeData={overlayHoleArray} 
                        holeNumber={this.state.holeSetupNumber} />
        }

        const whindex = this.props && this.props.userMeta && this.props.userMeta.whindex;

        const playingHandicap = whindex && getWHSPlayingHandicap(whindex, this.props.round);

        const editButton = (
            <Button onClick={this.editMeta} variant="contained" size="small" variant="outlined" color="secondary" className="gh-roundmeta__emph-button">
                <EditIcon className="gh-roundmeta__emph-button-svg" fontSize="small" />
                Edit
            </Button>
        );
        
        return (
            <Pux>
                <Header backClick={this.backClick} />
                {redirectBack}
                <div className="gh-content">
                    {this.props.round.coursename && this.state.form.fields['hole1'] ? 
                        <Pux>
                            <Heading type="h1" variant="central">Adding Round Scores</Heading>
                            <div className="gh-roundmeta">
                                    
                                    <Chip
                                        className="gh-chips__chip"
                                        icon={<LocationIcon />}
                                        label={`${this.props.round.coursename} ${this.props.round.roundtype === '9hole' ? `(9 hole)` : ``}`}
                                        color="primary"
                                    />
                                    

                                    {whsMode ? (
                                        <div className="gh-chips gh-chips--whsMode">
                                            <Chip
                                                className="gh-chips__chip"
                                                label={`Course Rating: ${this.props.round.courserating}`}
                                                color="primary"
                                            />
                                            <Chip
                                                className="gh-chips__chip"
                                                label={`Slope Rating: ${this.props.round.sloperating}`}
                                                color="primary"
                                            />
                                            {!playingHandicap && whindex && (
                                                <>
                                                    <Chip
                                                        className="gh-chips__chip"
                                                        icon={<PublicIcon />}
                                                        label={`Handicap Index: ${whindex}`}
                                                        color="primary"
                                                    />
                                                </>
                                            )}
                                            {playingHandicap && whindex && (
                                                <>
                                                    <Chip
                                                        className="gh-chips__chip"
                                                        icon={<PublicIcon />}
                                                        label={`Playing Handicap: ${playingHandicap} (${whindex})`}
                                                        color="primary"
                                                    />
                                                </>
                                            )}
                                            {editButton}
                                        </div>
                                    ) : (
                                        <Typography className="gh-roundmeta__emph">
                                            <SecurityIcon className="gh-roundmeta__emph-icon" />
                                            <span className="gh-roundmeta__emph-span">{this.props.round.handicap}</span>
                                            <span className="gh-roundmeta__emph-span">{this.props.round.gender === 'male' ? 'M' : 'F'}</span>
                                            <span className="gh-roundmeta__emph-span">SSS: {this.props.round.roundsss}</span>
                                            {editButton}
                                        </Typography>
                                    )}

                                    
                                        
                                    
                                    
                                    
                                    
                                
                            </div>

                            <LiveScores points={this.props.round.points || 0} topar={this.props.round.topar || 'E'} />
                            
                            <form className="form form--reducedmargin" onSubmit={this.submitHandler}>
                                
                                {this.props.round.scores
                                    .filter((hole, i) => !this.props.round.roundtype || this.props.round.roundtype === '18hole' || (this.props.round.roundtype === '9hole' &&  i < 9))
                                    .map((hole,i) => {
                                    let holeData = null;
                                    let holeNumber = null;
                                    for(let d in hole){
                                        holeNumber = d;
                                        if(hole[d].length){
                                            holeData = hole[d];
                                        }
                                    }
                                    if (!holeData && this.props.round.coursecard && this.props.round.coursecard.card[holeNumber]){
                                        holeData = this.props.round.coursecard.card[holeNumber];
                                    }
                                    let holeError = null;
                                    if(this.state.form.fields["hole"+holeNumber] && !this.state.form.fields["hole"+holeNumber].valid) {
                                        holeError = this.state.form.fields["hole"+holeNumber].rules[this.state.form.fields["hole"+holeNumber].errorType].message;
                                    }

                                    
                                    return (
                                        <div key={i} ref={(this.state.form.fields['hole' + holeNumber].ref) ? this.state.form.fields['hole' + holeNumber].ref : null}>
                                            <HoleEntry 
                                                holeNumber={holeNumber}
                                                scoreData={hole}
                                                holeData={holeData}
                                                valid={this.state.form.fields["hole"+holeNumber] ? this.state.form.fields["hole"+holeNumber].valid : true}
                                                error={holeError}
                                                scoreSelected={this.scoreSelected}
                                                holeSetup={this.holeSetup}
                                                />
                                        </div>
                                    );
                                })}
                                
                                
                                
                                <FormControl margin="normal" className="form__action form__action--extended" fullWidth>
                                    <Typography variant="body1" className="form__action-help" align="left">
                                        Once finished entering all your scores click finish below and calculate your new handicap.
                                    </Typography>
                                    <div className={classes.wrapper}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            size="large"
                                            variant="contained"
                                            color="primary"
                                            disabled={this.state.loading}
                                            className={buttonClassname}
                                        >
                                            {this.props.round.roundid ? 'Update Round' : 'Finished Round'}
                                        </Button>
                                        {this.state.loading ? <CircularProgress size={24} className={classes.buttonProgress} /> : null}
                                    </div>
                                </FormControl>
                            </form>
                        </Pux>    
                         : <ScreenLoading />}
                         {overlay}
                        
                </div>
                <AppBar selected="addround" />
            </Pux>
        );
    }
}
/*
AddRoundScores.propTypes = {
    searchTypeHandler: PropTypes.func
}*/

const mapStateToProps = state => {
    return {
        userMeta: state.auth.userMeta,
        round: state.round,
        startLoading: state.round.loading
    }
}

const mapDispatchToProps = dispatch =>  {
    return {
        roundRecover: () => dispatch(actionsCreators.roundRecover()),
        roundViewUpdate: (viewname) => dispatch(actionsCreators.roundViewUpdate(viewname)),
        roundSave: () => dispatch(actionsCreators.roundSave()),
        roundComplete: () => dispatch(actionsCreators.roundComplete()),
        updateHoleData: (holeNumber,score,par,stroke) => dispatch(actionsCreators.updateHoleData(holeNumber,score,par,stroke))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddRoundScores));
