import React from 'react';
import ReactDOM from 'react-dom';

export const validateForm = (form) => {
    const mutForm = {...form}
    //console.log("mutForm: ",mutForm);

    //reset errs
    mutForm.isValid = true;
    let setFirstError = null;
    mutForm.firstError = null;

    for(let f in mutForm.fields){
        const fieldName = f;
        const fieldData = mutForm.fields[f];
        //reset errors
        mutForm.fields[fieldName].valid = true;
        mutForm.fields[fieldName].errorType = null;
        let foundError = false;
        for(let r in fieldData.rules){
            if(!foundError){
                const ruleName = r;
                const ruleData = fieldData.rules[r];
                const isValid = validateRule(ruleName,ruleData,fieldData.value, fieldData);
                if(!isValid) {
                    
                    mutForm.fields[fieldName].valid = false;
                    mutForm.fields[fieldName].errorType = ruleName;
                    mutForm.isValid = false;
                    if(!setFirstError){
                        mutForm.firstError = fieldName;
                        mutForm.fields[fieldName].ref = React.createRef();
                        setFirstError = true;
                    }
                    foundError = true;
                }
            }
        }
    }
    //console.log("mutForm.firstError: ",mutForm.firstError);
    return mutForm;
}
export const validateUnit = (fieldName) => {
    return true;//need to make this actually validate unit, true for now
};
const validateRule = (rule,data,val,allData) => {
    switch(rule){
        case 'notEmpty':
            return val.length || typeof val === 'object';
        case 'courseId':
            return allData.id || false;
        case 'minlength':
            return val.length >= data.length;
        case 'validHandicap':
            return validHandicap(val);
        case 'isNumeric':
            //also check for empty, so its isNumeric and not empty, maybe split up into 2 rules
            return !isNaN(val) && val !== '';
        case 'sssLooksGood':
            return sssLooksGood(val, data.roundtype);
        case 'maxlength':
            return val.length <= data.length;
        case 'emailFormat':
            const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return regex.test(val);
        case 'emailOptionalFormat':
            const optionalregex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !val.length ? true : optionalregex.test(val);
        case 'radioSelected':
            return val !== null && val !== "undefined";
        default:
            return true;
    }
}
const validHandicap = (val) => {
    return !isNaN(val) && Number(val) <= 54;
}
const sssLooksGood = (val, roundtype) => {
    return roundtype === '9hole' ? Number(val) >= 25 && Number(val) <= 40 : Number(val) >= 50 && Number(val) <= 80;
}
export const scrollToRef = (refEle) => {
    if(!ReactDOM.findDOMNode(refEle.current)){
        return;
    }
    window.scrollTo({
        top:ReactDOM.findDOMNode(refEle.current).offsetTop, 
        behavior: "smooth" // optional
    });
};
