import React from 'react';
import './ScreenLoading.scss';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    progress: {
      margin: theme.spacing(2),
    },
});

const ScreenLoading = (props) => {

    const { classes } = props;

    let spinnerClasses = classes.progress + " gh-loading__spinner";

    return (
        <div className="gh-loading">
           <CircularProgress className={spinnerClasses} />
        </div>
    );
};

ScreenLoading.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ScreenLoading);