import React, { Component } from 'react';
import '../../assets/scss/core.scss';
import './Home.scss';
import Header from '../../components/Header/Header';
import AppBar from '../AppBar/AppBar';
//import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Pux from '../../hoc/Pux';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Heading from '../../components/Heading/Heading';
import RoundWedge from '../RoundWedge/RoundWedge';
import ScreenLoading from '../../components/ScreenLoading/ScreenLoading';
import firebase from '../../Firebase';
import {Helmet} from 'react-helmet';
import {createClient} from 'contentful';
import PromoBanner from '../../components/PromoBanner/PromoBanner';
import RecommendedFriends from '../../components/RecommendedFriends/RecommendedFriends';
import Button from '@material-ui/core/Button';
import BigSmallValue from '../../components/BigSmallValue/BigSmallValue';
import * as routes from '../../utils/routes.js';

const styles = {
    card: {
      maxWidth: 345,
    },
    media: {
      height: 140,
    },
  };

  
export class Home extends Component {

    state = {
        latestRounds:[],
        latestRoundsFetched:false,
        lastMonthsBestRounds:[],
        lastMonthsBestRoundsFetched:false,
        introContent: null
    }

    componentDidMount() {

        const client = createClient({
            space: process.env.REACT_APP_CONTENTFUL_SPACE,
            accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN
          })
          
          client.getEntry('1vyHNrLqp880x4F3OlylPq')
            .then((entry) => {
                this.setState({introContent:entry.fields.content})
            })
        
        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);

        const roundsRef = db.collection("rounds");
        roundsRef.orderBy("rounddate","desc")
            .limit(5)
            .get().then((querySnapshot) => {
                //console.log("querySnapshot: ",querySnapshot.size);
                let fetchedData = querySnapshot.docs.map(function (documentSnapshot) {
                    return {
                        ...documentSnapshot.data(),
                        roundid:documentSnapshot.id//merge in id to obj
                    };
                });
                if(fetchedData){
                    this.setState({
                        latestRounds:fetchedData
                    });
                    
                    
                    db.collection("courses")
                        .where("featured","==",true)
                        .limit(3)
                        .get().then((querySnapshot) => {
                            //console.log("querySnapshot: ",querySnapshot.size);
                            let fetchedCourses = querySnapshot.docs.map(function (documentSnapshot) {
                                return {
                                    ...documentSnapshot.data()
                                };
                            });
                            if(fetchedCourses){
                                this.setState({
                                    featuredCourses:fetchedCourses,
                                    latestRoundsFetched:true
                                });
                            } else {
                                this.setState({latestRounds:[],latestRoundsFetched:true});
                            }
                        });
                    
                } else {
                    this.setState({latestRounds:[],latestRoundsFetched:true});
                }
            });

            //last months best rounds

            const lastMonthDate = new Date();
            // Set it to one month ago
            lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
            // Zero the hours
            lastMonthDate.setHours(0, 0, 0);

            roundsRef
                .where("rounddate",">=",lastMonthDate)
                .orderBy("rounddate","desc")
                .get().then((querySnapshot) => {
                    //console.log("querySnapshot: ",querySnapshot.size);
                    let fetchedData = querySnapshot.docs.map(function (documentSnapshot) {
                        return {
                            ...documentSnapshot.data(),
                            roundid:documentSnapshot.id//merge in id to obj
                        };
                    });
                    if(fetchedData){
                        fetchedData.sort(function(a,b){
                            return b.points - a.points;
                        });
                        this.setState({
                            lastMonthsBestRounds:fetchedData,
                            lastMonthsBestRoundsFetched:true
                        });
                    } else {
                        this.setState({lastMonthsBestRounds:[],lastMonthsBestRoundsFetched:true});
                    }
                });


    }

    componentWillMount() {
        
    }


    render() {

       

        return (
            <Pux>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Golf Handicap - Golf Handicap Calculator | Golf Courses | Reviews + Ratings | Tracker</title>
                    <meta name="description" content="Calculate your handicap here. Track your handicap. UK Golf Course Directory, read and review your golf club. Follow your friends Handicap." />
                </Helmet>
                <Header />
                <div className="gh-content">

                    {/*this.props.userMeta && this.props.userMeta.role !== 'premium' ?
                        <PromoBanner onClick={() => {
                            this.props.history.push(routes.CONTENT_PREMIUM);
                        }} />
                    : null*/}


                    
                    
                    {this.state.latestRoundsFetched ? 
                        <Pux>

                            {this.state.introContent && this.props.userMeta && this.props.userMeta.displayname ? 
                                <div className="gh-content-component">
                                    <Heading type="h3" variant="left">Welcome <span className="gh-brand-premium">{this.props.userMeta.displayname}</span></Heading>
                                    <Typography>Here at <span className="gh-brand-premium">Golf Handicapp</span> we hope you'll find all you need to regulate your golf handicap. We've been providing handicap regulation for years and with our plethora of UK golf courses (and some others too) we hope you'll find adding a round as easy as could be.</Typography>
                                </div>
                            : null}

                            {this.props.userMeta && <RecommendedFriends userId={this.props.userId} meta={this.props.userMeta} />}

                            
                            <div className="gh-roundwedges">
                                <Heading type="h3" variant="left">Latest <span className="gh-brand-premium">Rounds</span></Heading>
                                <Typography className="--para">Some of the most recent rounds added.</Typography>
                                {this.state.latestRounds.map((round,i) => {
                                    return (
                                        <RoundWedge 
                                            key={i} 
                                            data={round} />
                                    );
                                })}
                            </div>
                            

                            <div className="gh-roundwedges">
                                <Heading type="h3" variant="left">Best <span className="gh-brand-premium">rounds</span> of the month</Heading>
                                <Typography className="--para">Some of the highest stableford points scored this month.</Typography>
                                {this.state.lastMonthsBestRounds.filter((round,i) => {
                                        return i < 5;
                                    }).map((round,i) => {
                                    return (
                                        <RoundWedge 
                                            key={i} 
                                            data={round} />
                                    );
                                })}
                            </div>

                            

                            {this.state.featuredCourses.length ? 
                                <div className="gh-featured-content">
                                    <Heading type="h3" variant="left">Featured <span className="gh-brand-premium">Courses</span></Heading>
                                    <div className="gh-featured-content__tube">
                                        {this.state.featuredCourses.map((course,i) => {
                                            return (
                                                <Card onClick={() => {
                                                    this.props.history.push('/course/' + course.id)
                                                }} className="gh-featured-content__card" key={i}>
                                                    <CardActionArea>
                                                    <CardMedia
                                                        style={{height:'140px'}}
                                                        image={course.image}
                                                        title="Contemplative Reptile"
                                                    />
                                                    <CardContent style={{paddingBottom:'0'}}>
                                                        <Typography gutterBottom variant="h6" component="h3">
                                                        {course.title}
                                                        </Typography>
                                                        <Typography className="gh-featured-content__address" component="p">
                                                        {course.county ? course.county : course.town}
                                                        {', ' + course.country}
                                                        </Typography>
                                                    </CardContent>
                                                    </CardActionArea>
                                                    <CardActions>
                                                        <Button size="small" color="primary">
                                                            Learn More
                                                        </Button>
                                                    </CardActions>
                                                </Card>
                                            );
                                        })}
                                    </div>
                                </div>
                            : null}
                        </Pux>
                    : <ScreenLoading />}
                </div>
                <AppBar selected="home" />
            </Pux>
        );
    }
}
/*
Home.propTypes = {
    searchTypeHandler: PropTypes.func
}*/

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        userId: state.auth.userId,
        userMeta: state.auth.userMeta,
        round: state.round
    }
}

export default withRouter(connect(mapStateToProps)(Home));
