import React, { Component } from 'react';
//import axios from 'axios';
import Pux from '../../hoc/Pux';
import { withRouter, Link } from 'react-router-dom';
import LinkUi from '@material-ui/core/Link';
import './CourseDetails.scss';
import { connect } from 'react-redux';
import ScreenLoading from '../../components/ScreenLoading/ScreenLoading';
import Typography from '@material-ui/core/Typography';
import Heading from '../../components/Heading/Heading';
import Header from '../../components/Header/Header';
import AppBar from '../AppBar/AppBar';
import RatingDisplay from '../../components/RatingDisplay/RatingDisplay';
import DateDisplay from '../../components/DateDisplay/DateDisplay';
import PropTypes from 'prop-types';
import decodeHtml, {stripTags} from '../../utils/utils';
import firebase from '../../Firebase';
import AddressIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Mail';
import LinkIcon from '@material-ui/icons/Link';
import Grid from '@material-ui/core/Grid';
import RoundWedge from '../RoundWedge/RoundWedge';
import ScoreCard from  '../../components/ScoreCard/ScoreCard';
import Button from '@material-ui/core/Button';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Badge from '@material-ui/core/Badge';
import AddReviewOverlay from '../AddReviewOverlay/AddReviewOverlay';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import SuccessIcon from '@material-ui/icons/CheckCircleOutlined';
import IconButton from '@material-ui/core/IconButton';
import {Helmet} from 'react-helmet';

class CourseDetails extends Component {

    state = {
        courseData : null,
        courseDataFetched: false,
        descriptionOpen: false,
        showCard:[],
        roundsData:null,
        roundsDataFetched:false,
        maxReviews:3,
        openAddReviewOverlay: false,
        snackBarOpen: false,
        snackBarMessage: null
    }
    

    componentDidMount () {

        
        const courseId = this.props.match.params.id;
        if(!courseId){
            return;
        }

       
        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);

        const docRef = db.collection("courses").doc(courseId);

        docRef.get().then((doc) => {
            if(doc.exists){
                const fetchData = doc.data();
                //document.title = 'Golf Handicap - ' + decodeHtml(fetchData.title);
                this.setState({courseData:fetchData,courseDataFetched:true});
            } else {
                console.log("debug 1a");
                this.setState({courseData:[],courseDataFetched:true});
            }
        });

        
        const roundsRef = db.collection("rounds");
        
        //console.log("courseId: ",courseId);

        roundsRef.where("courseid","==",(isNaN(courseId)) ? courseId : parseInt(courseId))
            .orderBy("rounddate","desc")
            .limit(5)
            .get().then((querySnapshot) => {
                //console.log("querySnapshot: ",querySnapshot.size);
                let fetchedData = querySnapshot.docs.map(function (documentSnapshot) {
                    return {
                        ...documentSnapshot.data(),
                        roundid:documentSnapshot.id//merge in id to obj
                    };
                });
                if(fetchedData){
                    this.setState({
                        roundsData:fetchedData,
                        roundsDataFetched:true
                    });
                } else {
                    this.setState({roundsData:null,roundsDataFetched:true});
                }
            });
        
    }

    toggleCardHandler = (value,card) => {
        
        const updateState = {...this.state}
        //console.log("val: ",updateState.showCard[value]);
        let showCardReset = [];
        if(!updateState.showCard[value]){
            showCardReset[value] = card;
        }
        updateState.showCard = showCardReset;
        this.setState(updateState);
    }

    closeAddReviewOverlay = (newReviews) => {
        console.log("newReviews: ",newReviews);
        const cachedState = {...this.state}
        if(newReviews){
            cachedState.courseData.reviews = newReviews;
            cachedState.snackBarOpen = true;
            cachedState.snackBarType = 'success';
            cachedState.snackBarMessage = 'Thanks for your adding your review of ' + cachedState.courseData.title + '!';
        }
        cachedState.openAddReviewOverlay = false;
        this.setState(cachedState);
    }

    backClick = () => {
        this.props.history.goBack();
    }

    render() {


        let socials = [];
        let tees = null;
        
        let filteredReviews = (this.state.courseData && this.state.courseData.reviews !== null) ? this.state.courseData.reviews.filter(review => review.reviewer_name !== null && review.reviewer_name.length > 1) : null;
        if(filteredReviews !== null && filteredReviews.length){
            filteredReviews = filteredReviews.sort(function(a,b){
                //sort by date, but has legacy string dates and new timestamp dates
                const copyA = {...a}
                const copyB = {...b}
                //set up a
                if(typeof copyA.date_time === 'string') {
                    if(copyA.date_time.indexOf("0000-00") !== -1){
                        copyA.date_time = new Date("1980-01-01");
                    } else {
                        copyA.date_time = new Date(copyA.date_time);
                    }
                } else if (typeof copyA.date_time === 'object') {
                    copyA.date_time = copyA.date_time.toDate();
                }

                //set up b
                if(typeof copyB.date_time === 'string') {
                    if(copyB.date_time.indexOf("0000-00") !== -1){
                        copyB.date_time = new Date("1980-01-01");
                    } else {
                        copyB.date_time = new Date(copyB.date_time);
                    }
                } else if (typeof copyB.date_time === 'object') {
                    copyB.date_time = copyB.date_time.toDate();
                }
                return copyB.date_time - copyA.date_time;
              });
        }
        if(this.state.courseData) {
            
            if(this.state.courseData.twitter){
                socials.push(
                    <a target="_blank" rel="noopener noreferrer" href={'https://twitter.com/' + this.state.courseData.twitter} className="gh-socials-block">
                        <LinkIcon className="gh-socials-block__icon" />
                        <Typography className="gh-socials-block__text">TWITTER</Typography>
                    </a>
                );
            }
            if(this.state.courseData.facebook){
                socials.push(
                    <a target="_blank" rel="noopener noreferrer" href={this.state.courseData.facebook} className="gh-socials-block">
                        <LinkIcon className="gh-socials-block__icon" />
                        <Typography className="gh-socials-block__text">FACEBOOK</Typography>
                    </a>
                );
            }
            if(this.state.courseData.website){
                let website = this.state.courseData.website;
                if(website.indexOf("http") === -1){
                    website = 'http://' + this.state.courseData.website;
                }
                socials.push(
                    <a target="_blank" rel="noopener noreferrer" href={website} className="gh-socials-block">
                        <LinkIcon className="gh-socials-block__icon" />
                        <Typography className="gh-socials-block__text">WEBSITE</Typography>
                    </a>
                );
            }

            if(this.state.courseData.tees){
                tees = JSON.parse(this.state.courseData.tees);
            }
        }
        
        //console.log(this.props.history);
       

        return (
            <Pux>
                <Header backClick={this.backClick} loggedOut={this.props.isAuthenticated !== true} />
                {this.state.courseDataFetched ? 
                    <div className="gh-content">
                        <Helmet>
                            <meta charSet="utf-8" />
                            <title>{this.state.courseData.title} - Reviews | Rounds | Scorecards - Golf Handicap</title>
                            <meta name="description" content="Calculate your handicap here. Track your handicap. UK Golf Course Directory, read and review your golf club. Follow your friends Handicap." />
                        </Helmet>
                        <Heading type="h2" className="gh-heading--nomarginbottom" variant="left">{this.state.courseData.title}</Heading>
                        <RatingDisplay reviews={this.state.courseData.reviews} />
                        <div className="gh-breadcrumbs" aria-label="Breadcrumb">
                            <Typography className="gh-breadcrumbs__bar" color="textPrimary">
                                <Link className="gh-breadcrumbs__link" color="inherit" to="/" >
                                    Home
                                </Link>
                                /
                                <Link className="gh-breadcrumbs__link" color="inherit" to="/courses" >
                                    Courses
                                </Link>
                                /
                            <span className="gh-breadcrumbs__text">{this.state.courseData.title}</span></Typography>
                        </div>
                        {this.state.courseData.image ? 
                            <img className="gh-images" src={this.state.courseData.image} alt={this.state.courseData.title} />
                        : 
                            <div className="gh-imagehold">
                                <Typography className="gh-imagehold__text">No image available yet.</Typography>
                            </div>
                        }
                        {this.state.courseData.desc ?
                        <div className="gh-readmore">
                        <Typography className={'gh-readmore__text' + (this.state.descriptionOpen ? ' gh-readmore__text--open' : '')} >{stripTags(this.state.courseData.desc)}</Typography>
                        <LinkUi
                            className="gh-readmore__link"
                            component="button"
                            variant="body2"
                            onClick={() => {
                                console.log("click read more");
                                this.setState({descriptionOpen:!this.state.descriptionOpen});
                            }} >
                            {this.state.descriptionOpen ? 'Read less...' : 'Read more...'}
                            </LinkUi>
                        </div>
                        : null}

                        <Typography className="gh-icontext">
                            <AddressIcon className="gh-icontext__icon" />
                            {this.state.courseData.addressLine1 ? this.state.courseData.addressLine1 : ''}
                            {this.state.courseData.town ? ', ' + this.state.courseData.town : ''}
                            {this.state.courseData.county ? ', ' + this.state.courseData.county : ''}
                            {this.state.courseData.postcode ? ', ' + this.state.courseData.postcode.toUpperCase() : ''}
                            {this.state.courseData.country ? ', ' + this.state.courseData.country : ''}
                        </Typography>

                        {this.state.courseData.phone ?
                            <Typography className="gh-icontext">
                                <PhoneIcon className="gh-icontext__icon" />
                                {this.state.courseData.phone}
                            </Typography>
                        : null}

                        {this.state.courseData.email ?
                            <Typography className="gh-icontext">
                                <EmailIcon className="gh-icontext__icon" />
                                {this.state.courseData.email}
                            </Typography>
                        : null}

                        <Grid className="gh-socials" container spacing={0}>
                            {socials.map((social,i) => {
                                return (
                                    <Grid key={i} item xs={4}>
                                     {social}
                                    </Grid>
                                );
                            })}
                        </Grid>

                        
                        {this.state.roundsDataFetched ? 
                            <React.Fragment>
                                {this.state.roundsData && this.state.roundsData.length ?
                                    <React.Fragment>
                                        <div className="gh-roundwedges">
                                            <Heading type="h3" variant="left">Latest Rounds</Heading>
                                            {this.state.roundsData.map((round,i) => {
                                                return (
                                                    <RoundWedge 
                                                        key={i} 
                                                        data={round} />
                                                );
                                            })}
                                        </div>
                                    </React.Fragment>
                                : null}
                            </React.Fragment>
                        : null}

                        {tees ? 
                            <React.Fragment>
                                 <div className="gh-scorecards">
                                    <Heading type="h3" variant="left">Scorecards</Heading>
                                    {tees.map((card,i) => {
                                        if(parseInt(card.meta.par) < 1){
                                            return false;
                                        }
                                        return (
                                            <div key={i} className="gh-scorecards__card">
                                                <div key={i} className="gh-scorecards__card-meta">
                                                    <Typography className="gh-scorecards__card-text">
                                                        {card.name ? <span className={'gh-scorecards__card-icon gh-scorecards__card-icon' + card.name}></span> : null}
                                                        {card.name ? card.name + ',  ' : null}
                                                        {(card.meta.yards && parseInt(card.meta.yards) > 10) ? card.meta.yards + ' yards, ' : null}
                                                        {card.meta.par ? 'Par ' + card.meta.par : null}
                                                        {card.meta.sss && card.meta.sss !== 'not set' ? ', SSS ' + card.meta.sss : null}
                                                    </Typography>
                                                     
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        className="gh-scorecards__card-action"
                                                        onClick={() => {
                                                            this.toggleCardHandler(i,card);
                                                        }}>
                                                        {(!this.state.showCard[i]) ? <Visibility /> : <VisibilityOff />}
                                                    </Button>
                                                </div>
                                                {(this.state.showCard[i]) ?  
                                                    <ScoreCard type="tees" carddata={card} hasYards={card.meta.yards.length > 0} />
                                                : null}
                                            </div>
                                        );
                                    })}
                                </div>
                            </React.Fragment>
                        : null}
                        <div className="gh-reviews">
                            <Heading type="h3" variant="left">Reviews</Heading>
                            {this.state.courseData.reviews !== null && this.state.courseData.reviews.length ? 
                                <React.Fragment>
                                    {filteredReviews.map((review,i) => {
                                            if(i >= this.state.maxReviews){
                                                return false;
                                            }
                                        return (
                                            <Card key={i} className="gh-reviews__review">
                                                <CardContent>
                                                    <Typography color="textSecondary" gutterBottom>
                                                        {review.reviewer_name}
                                                        {typeof review.date_time === 'string' && review.date_time.indexOf("0000-00") === -1 ? 
                                                            <React.Fragment> - <DateDisplay rawdate={review.date_time} /></React.Fragment>
                                                        : null}
                                                        {typeof review.date_time === 'object' ? 
                                                            <React.Fragment> - <DateDisplay date={review.date_time} /></React.Fragment>
                                                        : null}
                                                    </Typography>
                                                    {review.review_title !== '' ? 
                                                        <Typography variant="h5" component="h2">
                                                            {review.review_title}
                                                        </Typography>
                                                    : null}                                                  
                                                    <RatingDisplay rating={4} />  
                                                    {review.review_text !== '' ?                                                 
                                                        <Typography>
                                                            {review.review_text}
                                                        </Typography>
                                                    : null} 
                                                </CardContent>
                                            </Card>
                                        );
                                    })}
                                    <React.Fragment>
                                        {!filteredReviews.length ? 
                                            <Typography>No full reviews yet.</Typography>
                                        : null}
                                    </React.Fragment>
                                    <React.Fragment>
                                            {(filteredReviews.length > this.state.maxReviews) ? 
                                                <Typography align="center" className="gh-seemore">
                                                    <Badge color="primary" badgeContent={filteredReviews.length - this.state.maxReviews} className="gh-seemore__badge">
                                                        <Button onClick={() => {
                                                            this.setState({ maxReviews: this.state.maxReviews + 100 })
                                                        }} variant="outlined">See More</Button>
                                                    </Badge>
                                                </Typography>
                                            : null}
                                            {this.props.isAuthenticated ? 
                                                 <Typography align="center" className="gh-seemore">
                                                    <Button 
                                                        onClick={() => {
                                                            this.setState({ openAddReviewOverlay: true })
                                                        }} 
                                                        size="large"
                                                        color="primary" 
                                                        variant="contained">Add Review</Button>
                                                </Typography>
                                            : null}
                                            {this.state.openAddReviewOverlay ? 
                                                <AddReviewOverlay 
                                                    currentReviews={this.state.courseData.reviews}
                                                    onClose={this.closeAddReviewOverlay} 
                                                    courseid={this.state.courseData.id} 
                                                    coursename={this.state.courseData.title} />
                                            : null}
                                            
                                            
                                            <Snackbar
                                                className={(this.state.snackBarType) ? 'gh-snackBar gh-snackBar--'+this.state.snackBarType : 'gh-snackBar'}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                                open={this.state.snackBarOpen}
                                                autoHideDuration={6000}
                                                onClose={() => {
                                                    this.setState({snackBarOpen:false})
                                                }}
                                                ContentProps={{
                                                    'aria-describedby': 'message-id',
                                                }}
                                                message={
                                                    <span id="message-id" className="gh-snackBar__message">
                                                        <SuccessIcon className="gh-snackBar__message-svg" />
                                                        {this.state.snackBarMessage}
                                                    </span>
                                                }
                                                action={[
                                                    <IconButton
                                                        key="close"
                                                        aria-label="Close"
                                                        color="inherit"
                                                        onClick={() => {
                                                            this.setState({snackBarOpen:false})
                                                        }}
                                                        >
                                                    <CloseIcon />
                                                    </IconButton>
                                                ]}
                                                />
                                       
                                    </React.Fragment>
                                </React.Fragment>
                            : <Typography>No reviews yet.</Typography>}
                        </div>                       
                        
                    </div>
                : <ScreenLoading />}
                <AppBar selected="home" />
            </Pux>
        );
    }
}

CourseDetails.propTypes = {
   courseData: PropTypes.array
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null
    }
}
  
  
export default withRouter(connect(mapStateToProps, null)(CourseDetails));
