import React from 'react';

const flagicon = (props) => {
    
    return (
        <svg className="flagsvg" viewBox="0 0 38.287 42.129">
            <g transform="translate(-3.5 -1.289)">
                <path d="M40.333,10.02,17.974,2V38.826h-5.59V35.287C7.382,36,4,37.312,4,38.826c0,2.25,7.518,4.092,16.769,4.092s16.769-1.841,16.769-4.092c0-2.025-6.037-3.7-13.974-4.03V16.28Z" transform="translate(0 0)" fill="#28844b" stroke="#fff" strokeWidth="1"/>
            </g>
        </svg>
    );
};
export default flagicon;