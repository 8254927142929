import { createMuiTheme } from '@material-ui/core/styles';

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

export default createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#28844B',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            // light: will be calculated from palette.primary.main,
            main: '#79859E',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        typography: {
            useNextVariants: true,
        }
    },
    overrides: {
        MuiPickersToolbar: {
          toolbar: {
            backgroundColor: 'transparent',
          },
        },
        MuiPickersDay: {
          day: {
            color: 'white',
          }
        },
        MuiPickersModal: {
          dialogAction: {
            color: 'white',
            
          },
          dialogRoot: {
            border: '1px solid #ffffff',
            background: 'radial-gradient(farthest-corner at 90% 0,#08A6BE 0%, #0E3676 50%,  #08193C 100%)'
          }
        }
    }
});
