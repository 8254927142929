import React, { Component } from 'react';
import '../../assets/scss/core.scss';
import Header from '../../components/Header/Header';
import AppBar from '../AppBar/AppBar';
//import PropTypes from 'prop-types';
//import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Pux from '../../hoc/Pux';
import firebase from '../../Firebase';

let globalUsers = [];
let globalUserRounds = [];

export class DataFix extends Component {

    state = {
    }

    componentDidMount() {
        
        //this.fixUserId()
        //this.initAddRounds();
        //this.initAddUsers();
        //this.grabImages();
        //this.updateRoles();
        //this.addCourse();
        //this.getLockedUsers();
        //this.mockPostRoundAdd();

        //this.fixRounds();

    }

    fixRounds = () => {
        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);


        
        const roundsRef = db.collection("rounds");
        roundsRef.where("courseid","==",NaN)
            .orderBy("rounddate","desc")
            //.limit(1)
            .get().then((querySnapshot) => {
                //console.log("querySnapshot: ",querySnapshot.size);
                let fetchedData = querySnapshot.docs.map(function (documentSnapshot) {

                    console.log("tread carefull with this: ",documentSnapshot.id);
                    /*roundsRef.doc(documentSnapshot.id).update({
                        courseid:''
                    });*/
                    return {
                        ...documentSnapshot.data(),
                        roundid:documentSnapshot.id//merge in id to obj
                    };
                });
            });
    }

    getLockedUsers = () => {

        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);

       
        db.collection("user_meta").where("locked", "==", true)
            //.limit(5)
            .get().then((querySnapshot) => {
                console.log("querySnapshot: ",querySnapshot.size);
                querySnapshot.forEach(userdoc => {
                    console.log("userdoc.id: ",userdoc.id);
                    //db.collection("rounds").doc(doc.id).update({userid: userdoc.id});
                });
            });
             

    }

    getPar = (scores) => {
        let par = 0;
        if(!scores){
            return par;
        }
        for(let h in scores){
            let holeData = scores[h];
            let values = Object.values(holeData);
            par += parseInt(values[0]);
        }
        return par;
    }

    seeIfCardExists = (oldScorecards,roundCard,roundPar,roundSSS) => {
        
        let exists = false;
        for(let c = 0;c < oldScorecards.length;c++){
            const cardData = oldScorecards[c];
            if(cardData.sss === roundSSS && cardData.par === roundPar){
                let identicalCard = true;
                for(let h in cardData.card){
                    let holeData = cardData.card[h];
                    if(holeData[0] !== roundCard[h][0]){
                        identicalCard = false;
                    }
                }
                if(identicalCard){
                    exists = true;
                }
            }
        }
        return exists;
    }

    updatedScorecards = (oldScorecards,roundCard,roundSSS) => {

        let updatedScorecards = [];
        if(!oldScorecards || oldScorecards.length === ''){
            updatedScorecards = [];
        } else {
            oldScorecards = JSON.parse(oldScorecards);
            updatedScorecards = oldScorecards;
        }
        const newCard = {}
        for(let h = 0;h < roundCard.length;h++){
            for(let v in roundCard[h]){
                newCard[(h+1).toString()] = roundCard[h][v];
            }
        }

        let roundPar = this.getPar(roundCard);
        let cardExists = this.seeIfCardExists(updatedScorecards,newCard,roundPar.toString(),roundSSS.toString());
        if(cardExists || updatedScorecards.length > 10) {
            return false;
        }
        
          
        updatedScorecards.push({
            sss:roundSSS.toString(),
            par:roundPar.toString(),
            card:newCard
        });
        return updatedScorecards;
    }

    mockPostRoundAdd = () => {
        const roundId = 'kX9obgdH8cNT5qM1WUut';
        const courseid = '1640';

        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);

        

        db.collection("rounds").doc(roundId)
            .get().then((doc) => {
                if (doc.exists) {
                    console.log("Round:", doc.data());
                    db.collection("courses").doc(courseid)
                        .get().then((course) => {
                            if (course.exists) {
                                console.log("Course:", course.data());
                                let newScorecards = this.updatedScorecards(course.data().scorecards,doc.data().scores,doc.data().roundsss);
                                console.log("[newScorecards]",newScorecards);
                                if(newScorecards){
                                    //update db
                                    console.log("need to update course with cards");
                                    /*db.collection("courses").doc(courseid).update({
                                        scorecards:JSON.stringify(newScorecards)
                                    });*/
                                } else {
                                    console.log("dont update course");
                                }

                            }
                        });
                }
            }).catch((error) => {
                console.log("Error getting document:", error);
            });;
       
        
    }

    addCourse = () => {
        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);

        const courseData = {
            searchTitle: 'wareham golf club',
            title: 'Wareham Golf Club',
            addressLine1: ' Sandford Road',
            town: 'Wareham',
            county: 'Dorset',
            postcode: 'BH20 4DH',
            country: 'United kingdom',
            phone: '01929 554147',
            email: 'secretary@warehamgolfclub.com',
            desc: `
            Wareham Golf Club in Dorset is an established eighteen hole golf course in the Isle of Purbeck in the heart of Dorset. On the edge of Wareham Forest, the golf club provides a tranquil setting with picturesque views over the Purbeck Hills, Poole Harbour and over to Corfe Castle. 
            `,
            id: '',
            image: '',
            twitter: 'WarehamGolf',
            facebook: 'https://www.facebook.com/warehamgolfclub?fref=ts',
            website: 'https://www.warehamgolfclub.com/',
            reviews: null,
            rounds:'',
            scorecards:'',
            lat: '',
            long: '',
            tees: ''
        }


        console.log("[replace above data before removing this return]",courseData);
        return;


        db.collection("courses").add(courseData)
        .then(function(docRef) {
            
            db.collection("courses").doc(docRef.id).update({
                id:docRef.id
            });
            console.log("Course Added with ID, updated ID: ", docRef.id);
            
        })
        .catch(function(error) {
            console.error("Error adding document: ", error);
        });
        

        //const usersRef = db.collection("courses").where("rounddate", ">=", fromDate);

    }

    fixUserId = () => {
        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);

        let fromDate = new Date('2019-04-14');

        const usersRef = db.collection("rounds").where("rounddate", ">=", fromDate);
        usersRef
            //.limit(1)
            .get().then((querySnapshot) => {
                console.log("querySnapshot: ",querySnapshot.size);
                querySnapshot.forEach(doc => {
                    console.log(doc.id, '=>', doc.data());
                    db.collection("user_meta").where("legacyid", ">=", doc.data().userid)
                        .limit(1)
                        .get().then((querySnapshot) => {
                            console.log("querySnapshot: ",querySnapshot.size);
                            querySnapshot.forEach(userdoc => {
                                db.collection("rounds").doc(doc.id).update({userid: userdoc.id});
                            });
                        });
                });
            });
    }

    grabImages = () => {
        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);
        const coursesRef = db.collection("courses");
        coursesRef
            .where("image",">","")
            .limit(1)
            .get().then((querySnapshot) => {
                console.log("querySnapshot: ",querySnapshot.size);
                querySnapshot.forEach(doc => {
                    console.log(doc.id, '=>', doc.data());

                    //const storage = firebase.storage();
                    //console.log("image: ",doc.data().image);
                    
                    
                    // This can be downloaded directly:
                    var xhr = new XMLHttpRequest();
                    //xhr.dataType = 'jsonp';
                    xhr.responseType = 'blob';
                    xhr.onload = function(event) {
                        var blob = xhr.response;
                        console.log("blob: ",blob);
                    };
                    xhr.open('GET', doc.data().image);
                    xhr.send();

                    /*const storageRef = storage.ref();
                    const spaceRef = storageRef.child('courses/' + doc.id + '.jpg');
                    spaceRef.put(file).then((snapshot) => {
                        console.log("image uploaded:");
                    });*/
                    
                });
            });
    }

    updateRoles = () => {
        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);
        const usersRef = db.collection("user_meta");
        usersRef
            //.limit(10)
            .get().then((querySnapshot) => {
                console.log("querySnapshot: ",querySnapshot.size);
                querySnapshot.forEach(doc => {
                    console.log(doc.id, '=>', doc.data());
                    db.collection("user_meta").doc(doc.id).update({role: "standard"});
                });
            });
    }

    
    initAddRounds = () => {
        const rtdb = firebase.database();
        const rtref = rtdb.ref("legacyrounds");
        
        rtref.orderByKey()
            //.limitToFirst(1)
            .startAt("0")
            .once("value")
            .then(((snapshot) => {
                console.log("snapshot: ",snapshot.val());
                
                globalUserRounds = snapshot.val();

                for(let u in globalUserRounds) {
                    const round = globalUserRounds[u];
                    
                    const legacyUserId = round.legacyUserId;
                        
                    this.addRounds(legacyUserId,round);
                }
                
                
                
            }))
            .catch(error => {
                
                console.log("error happened: ",error);
                
            }); 
    }

    initAddUsers = () => {
        const rtdb = firebase.database();
        const rtref = rtdb.ref("legacyusers");
        
        rtref.orderByKey()
            //.limitToFirst(10)
            .startAt("0")
            .once("value")
            .then(((snapshot) => {
                console.log("snapshot: ",snapshot.val());
                
                const users = snapshot.val();

                globalUsers = Object.keys(users).map((k) => users[k]);
            
                //addFirstUser
                //console.log("globalUsers: ",globalUsers);
                if(globalUsers.length){
                    this.addUser();//then incrementally add till no more
                }
                
                
            }))
            .catch(error => {
                
                console.log("error happened: ",error);
                
            }); 
    }



    addUser = () => {

        

        if(!globalUsers.length){
            //firebase.auth().signOut();
            return; //no more users to add
        }


        //code for adding user
        //console.log("add this user: ",globalUsers[0]); //add next in array

        const userMeta = globalUsers[0];
        
        if(!userMeta) {
            console.log("already removed");
            //move on to next one
            globalUsers.shift();
            this.addUser();
            return;
        }
        
       // console.log("userMeta: ",userMeta);
        if(!userMeta.handicap){
            userMeta.handicap = 28;
        }

        
        

        const pw = this.createRandomPassword();
        //console.log("pw: ",pw);

        firebase.auth().createUserWithEmailAndPassword(userMeta.email, pw).then((user) => {
            this.importUserData(user.user.uid, userMeta);
            console.log("added: ",userMeta.email);
        }, (error) => {
            const newdb = firebase.database();
            const newref = newdb.ref("legacyusers");
            console.log("already done: ",userMeta.email);
            newref.orderByChild("email").equalTo(userMeta.email).on("child_added", function(snapshot) {
                firebase.database().ref('legacyusers/' + snapshot.key).remove();
            });

            //move on to next one
            globalUsers.shift();
            this.addUser();
           
        });
    }

    importUserData = (uid, data) => {
        
        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);

       
        db.collection("user_meta").doc(uid).set({
            displayname: data.displayName,
            handicap: data.handicap,
            legacyid: data.ID,
            createdTime: new Date(data.creationDate),
            role: 'standard',
            receiveemails: true
        })
        .then(() => {
            //now add rounds
            //console.log("user meta added: ",data.email);
            //check if need to fire agin
            globalUsers.shift();
            this.addUser();
        })
        .catch((error) => {
            //not updated
        });

    }

    addRounds = (uid, round) => {
        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);

        

        

        
            let rawScore = Object.assign({},round.scores);
            round.scores = Object.keys(rawScore).map(function(key) {
                let myo = {}
                myo[Number(key)] = rawScore[key];
                return myo;
            });
            round.userid = uid;
            round.rounddate = new Date(round.rounddate);
            if(!round.gender){
                round.gender = 'male';
            }
            
            db.collection("rounds").add(round)
                .then((doc) => {
                    if (doc.id) {
                        console.log("round added: ",doc.id);
                    }
                })
                .catch((error) => {
                    console.log("Not added");
                });
       
    }

    

    createRandomPassword = () => {
        var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
        var string_length = 8;
        var randomstring = '';
        for (var i=0; i<string_length; i++) {
            var rnum = Math.floor(Math.random() * chars.length);
            randomstring += chars.substring(rnum,rnum+1);
        }
        return randomstring;
    }


    render() {
        return (
            <Pux>
                <Header />
                <div className="gh-content">
                    <h3>Import</h3>
                    
                </div>
                <AppBar selected="home" />
            </Pux>
        );
    }
}
/*
DataFix.propTypes = {
    searchTypeHandler: PropTypes.func
}*/

const mapStateToProps = state => {
    return {
        searchesmade: state.searchesmade,
        isAuthenticated: state.auth.token !== null,
        userId: state.auth.userId,
        userMeta: state.auth.userMeta,
        round: state.round
    }
}

const mapDispatchToProps = dispatch =>  {
    return {
        onSearchMade: () => dispatch({type: 'INC_SEARCHES'})
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(DataFix);
