import React from 'react';
//import './ProfilePic.scss';
import Avatar from '@material-ui/core/Avatar';

const ProfilePic = (props) => {

    let mainClasses = ['gh-avatar'];
    if(props.className){
        mainClasses.push(props.className);
    }
    if(props.premium){
        mainClasses.push('--premium');
    }
    
    let avatarInitials = 'U';
    if(!props.profilepic && props.displayname) {
        avatarInitials = props.displayname.substring(0,1);
        const initailArray = props.displayname.split(' ');
        if(initailArray.length > 1){
            avatarInitials = '';
            for(let a = 0; a < 2; a++){//max of 2 initials
                avatarInitials += initailArray[a].substring(0,1);
            }
        }
    } else if(props.profilepic) {
        mainClasses.push('gh-avatar--image');
    }

    return (
        <React.Fragment>
            {props.profilepic ? 
                <Avatar onClick={props.onClick} className={mainClasses.join(' ')} alt={props.displayname} src={props.profilepic} />
            : <Avatar onClick={props.onClick} className={mainClasses.join(' ')}>{avatarInitials}</Avatar>}
        </React.Fragment>

    );
};
export default ProfilePic;