import React, { Component } from 'react';
//import axios from 'axios';
import Pux from '../../hoc/Pux';
import { withRouter } from 'react-router-dom';
import decodeHtml from '../../utils/utils';
import './Profile.scss';
import PropTypes from 'prop-types';
import firebase from '../../Firebase';
import ScreenLoading from '../../components/ScreenLoading/ScreenLoading';
import ProfilePic from '../../components/ProfilePic/ProfilePic';
import DateDisplay from '../../components/DateDisplay/DateDisplay';
import Heading from '../../components/Heading/Heading';
import Header from '../../components/Header/Header';
import AppBar from '../AppBar/AppBar';
import RoundWedge from '../RoundWedge/RoundWedge';
import SecurityIcon from '@material-ui/icons/Security';
import PublicIcon from '@material-ui/icons/Public';
import TimeIcon from '@material-ui/icons/AccessTime';
import BigSmallValue from '../../components/BigSmallValue/BigSmallValue';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import MaterialAppBar from '@material-ui/core/AppBar';
import { connect } from 'react-redux';
import {BarChart} from 'react-easy-chart';
import EditIcon from '@material-ui/icons/Settings';
import ConnectionBar from '../ConnectionBar/ConnectionBar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';



class Profile extends Component {

    state = {
        profileData : [],
        profileRounds: [],
        profileDetailsFetched:false,
        filterYear: ''
    }
    

    componentDidMount () {

        
        const profileId = this.props.match.params.id;
        if(!profileId){
            return;
        }
        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);

        
        const profileDocRef = db.collection("user_meta").doc(profileId);
        profileDocRef.get().then((profiledoc) => {
            if(profiledoc.exists){

                this.setState({profileData:profiledoc.data()});

                document.title = 'Golf Handicap - Profile - ' + decodeHtml(profiledoc.data().displayname);

                const storage = firebase.storage();
                const storageRef = storage.ref();
                storageRef.child('userpics/' + profileId + '-pp.jpg').getDownloadURL().then((url) => {
                    const cachedState = {...this.state}
                    cachedState.profileData.profilepic = url;
                    this.setState(cachedState);
                }).catch((error) => {
                    //error method
                });
                
                //now get rounds data
                const roundsRef = db.collection("rounds");
                db.settings(settings);
                

                roundsRef.where("userid","==",profileId)
                    .get().then((querySnapshot) => {
                        //console.log("querySnapshot: ",querySnapshot.size);
                        let fetchedData = querySnapshot.docs.map(function (documentSnapshot) {
                            return {
                                ...documentSnapshot.data(),
                                roundid:documentSnapshot.id//merge in id to obj
                            };
                        });
                        if(fetchedData){
                            //sort fetched before storing:
                            fetchedData.sort(function(a,b){
                                return b.rounddate.toDate() - a.rounddate.toDate();
                            });
                            
                            this.setState({
                                profileRounds:fetchedData,
                                profileDetailsFetched:true,
                                profileRoundsLength:querySnapshot.size
                            });
                        } else {
                            this.setState({profileRounds:[],profileDetailsFetched:true,profileRoundsLength:0});
                        }
                    });
                
            } else {
                this.setState({profileDetailsFetched:true});
            }
        }).catch((error) => {
            this.setState({profileDetailsFetched:true});
        });
        
        
    }

    filterStats = (event, value) => {
        this.setState({filterYear:value});
    }

    analyseData = () => {
        const rawData = this.state.profileRounds;
        let courseRegister = [];
        let yearArray = []
        let joinData = {
            '-4' : 'condors',
            '-3' : 'albatros',
            '-2' : 'eagles',
            '-1' : 'birdies',
            '0' : 'pars',
            '1' : 'bogies',
            '2' : 'dbogies',
            '3' : 'other',
        }
        let scoreNameTally = {
            'condors' : {
                tally: 0
            },
            'albatros' : {
                tally: 0
            },
            'eagles' : {
                tally: 0
            },
            'birdies' : {
                tally: 0
            },
            'pars' : {
                tally: 0
            },
            'bogies' : {
                tally: 0
            },
            'dbogies' : {
                tally: 0
            },
            'other' : {
                tally: 0
            },
        }
        let partotal = {
            3: {
                tally:0,
                total:0
            },
            4: {
                tally:0,
                total:0
            },
            5: {
                tally:0,
                total:0
            },
            6: {
                tally:0,
                total:0
            }
        };
        let bestRound = 0;
        let distinctCourses = 0;
        let holesInOne = 0;
        let holesPlayed = 0;
        let shotTotal = 0;
        let totalRounds = 0;
        let holeStrokeData = []

        //loop the rounds
        for (let r = 0; r < rawData.length; r++) {
            const roundData = rawData[r];
            let roundShots = 0;

            //assess years first
            let roundDate = new Date(roundData.rounddate.seconds * 1000);
            if(roundData.rounddate && yearArray.indexOf(roundDate.getFullYear()) === -1){
                yearArray.push(roundDate.getFullYear());
            }

            if(this.state.filterYear !== '' && roundDate.getFullYear() !== this.state.filterYear){
                continue;
            }

            //assess courses
            if(roundData.courseid && courseRegister.indexOf(roundData.courseid) === -1){
                distinctCourses++;
                courseRegister.push(roundData.courseid);
            }

            

            //loop the scores
            for (let h = 0; h < roundData.scores.length; h++) {
                let holeData = roundData.scores[h];
                holeData = Object.values(holeData)[0];
                if(!holeData[0]) {
                    continue;
                }
                partotal[holeData[0]].tally = partotal[holeData[0]].tally + 1;
                partotal[holeData[0]].total = partotal[holeData[0]].total + holeData[1];
                if(holeData[1] === 1){
                    holesInOne++;
                }
                shotTotal += holeData[1];//add shots
                roundShots += holeData[1];
                const net = holeData[1] - holeData[0];
                let tallyName = joinData[net];//get join score data
                //console.log("tallyName: ",tallyName);
                if(!tallyName) {
                    tallyName = 'other';
                }

                //add to hole stoke data
                //console.log("holeData[2]: ",holeData[2]);
                const holeRef = parseInt(holeData[2]) - 1;
                if(!holeStrokeData[holeRef]){
                    holeStrokeData[holeRef] = {
                        stroke: holeData[2],
                        scores : 0,
                        count: 0,
                        avg: 0
                    }
                }
                holeStrokeData[holeRef].scores += parseInt(holeData[1] - holeData[0]);
                holeStrokeData[holeRef].count += 1;
                holeStrokeData[holeRef].avg = (holeStrokeData[holeRef].scores / holeStrokeData[holeRef].count).toFixed(2);

                scoreNameTally[tallyName].tally++; // update score totals
                holesPlayed++;
            }
            if(bestRound === 0 || roundShots < bestRound){
                bestRound = roundShots;
            }
            totalRounds++;
        }

        holeStrokeData = holeStrokeData.sort(function(a,b){
            return b.avg - a.avg;
        });

        const bestRoundsData = rawData.sort(function(a,b){
            return b.points - a.points;
        }).filter((round) => {
            //filter year selected
            let roundDate = new Date(round.rounddate.seconds * 1000);
            return this.state.filterYear === '' || roundDate.getFullYear() === this.state.filterYear;
        });

        //order years
        yearArray.sort(function(a, b){return b - a});

        return {
            par3avg: partotal[3].total > 0 ? (partotal[3].total / partotal[3].tally).toFixed(1) : 0,
            par4avg: partotal[4].total > 0 ? (partotal[4].total / partotal[4].tally).toFixed(1) : 0,
            par5avg: partotal[5].total > 0 ? (partotal[5].total / partotal[5].tally).toFixed(1) : 0,
            holesInOne: holesInOne,
            distinctCourses : distinctCourses,
            distinctYears:yearArray,
            shotAvg: shotTotal > 0 ? (shotTotal / totalRounds).toFixed(1) : 0,
            bestRound: bestRound,
            condors: scoreNameTally['condors'].tally,
            eagles: scoreNameTally['eagles'].tally,
            birdies: scoreNameTally['birdies'].tally,
            pars: scoreNameTally['pars'].tally,
            bogies: scoreNameTally['bogies'].tally,
            dbogies: scoreNameTally['dbogies'].tally,
            other: scoreNameTally['other'].tally,
            holesPlayed:holesPlayed,
            totalRounds: totalRounds,
            bestRoundsData: bestRoundsData,
            holeStrokeData: holeStrokeData
        }
    }

    loadEditView = () => {
        let path = '/editprofile';
        this.props.history.push(path);
    }

    render() {


        //analyse data
        let par3avg, par4avg, par5avg, holesInOne, distinctCourses, shotAvg, bestRound, 
            condors, albatros, eagles, birdies, pars, bogies, dbogies, other, distinctYears, 
            holesPlayed, totalRounds, bestRoundsData, holeStrokeData;

        if(this.state.profileDetailsFetched){
            ({ par3avg, par4avg, par5avg, holesInOne, distinctCourses, shotAvg, bestRound,
                condors, albatros, eagles, birdies, pars, bogies, dbogies, other, distinctYears,
                holesPlayed, totalRounds, bestRoundsData, holeStrokeData} = this.analyseData());
        }

        return (
            <Pux>
                <Header />
                {this.state.profileDetailsFetched && this.props.userId ? 
                    <Pux>
                        {this.state.profileData ? 
                            <div className="gh-content">
                            
                                <div className="gh-detailsMeta gh-detailsMeta--profile">
                                    <ProfilePic 
                                        premium={this.state.profileData.role === 'premium'}
                                        displayname={this.state.profileData.displayname}
                                        profilepic={this.state.profileData.profilepic}
                                        className="gh-detailsMeta__avatar" />
                                    <Typography className="gh-detailsMeta__name">
                                        {this.state.profileData.displayname} 
                                    </Typography>
                                    {this.state.profileData.homecourse ? 
                                        <Typography className="gh-detailsMeta__metaline">{this.state.profileData.homecourse}</Typography>
                                    : null}
                                   
                                    <Typography className="gh-detailsMeta__metaline gh-detailsMeta__metaline--lowkey">
                                        <TimeIcon className="gh-detailsMeta__metaline-icon" /> Joined: <DateDisplay date={this.state.profileData.createdTime} />
                                    </Typography>

                                    {this.state.profileData.whmode ? (
                                        <>
                                            {this.state.profileData.whindex ? 
                                                <BigSmallValue
                                                    value={this.state.profileData.whindex}
                                                    label="WHI"
                                                    className="gh-detailsMeta__bigsmall"
                                                    icon={
                                                        <PublicIcon />
                                                    }
                                                    />
                                            : null}
                                        </>
                                    ) : (
                                        <>
                                            {this.state.profileData.handicap ? 
                                                <BigSmallValue
                                                    value={this.state.profileData.handicap}
                                                    label="HCP"
                                                    className="gh-detailsMeta__bigsmall"
                                                    icon={
                                                        <SecurityIcon />
                                                    }
                                                    />
                                            : null}
                                        </>
                                    )}
                                    
                                    

                                    {this.props.userId === this.props.match.params.id ?
                                        <EditIcon onClick={() =>  {
                                            this.loadEditView();
                                        }} size="small" color="inherit" className="gh-detailsMeta__editicon">
                                        </EditIcon>
                                    : null}
                                </div>
                                
                                <ConnectionBar 
                                    currentConnection={this.state.profileData.connections}
                                    to={this.props.match.params.id}
                                    from={this.props.userId} />


                                <Heading type="h3" variant="left">Statistics</Heading>
                                
                                {totalRounds > 0 ? 
                                    <Pux>
                                        <MaterialAppBar position="static" color="default" className="gh-scrolltabs">
                                            <Tabs
                                                className="gh-scrolltabs__tab"
                                                value={this.state.filterYear}
                                                onChange={this.filterStats}
                                                indicatorColor="primary"
                                                textColor="primary"
                                                variant="scrollable"
                                                scrollButtons="auto"
                                            >
                                                <Tab label="all" value="" />
                                                {distinctYears.map((year,i) => {
                                                    return (
                                                        <Tab key={i} label={year} value={year} />
                                                    );
                                                })}
                                            </Tabs>
                                        </MaterialAppBar>
                                        <div className="gh-stats">
                                            <Grid className="gh-stats__grid" container spacing={0}>
                                                <Grid item xs={3}>
                                                    <BigSmallValue
                                                        value={totalRounds}
                                                        label="ROUNDS"
                                                        />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <BigSmallValue
                                                        value={distinctCourses}
                                                        label="COURSES"
                                                        />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <BigSmallValue
                                                        value={shotAvg}
                                                        label="AVG SHOTS"
                                                        />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <BigSmallValue
                                                        value={bestRound}
                                                        label="BEST ROUND"
                                                        />
                                                </Grid>
                                            </Grid>
                                            <Grid className="gh-stats__grid" container spacing={0}>
                                                <Grid item xs={3}>
                                                    <BigSmallValue
                                                        value={par3avg}
                                                        label="PAR 3 AVG"
                                                        />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <BigSmallValue
                                                        value={par4avg}
                                                        label="PAR 4 AVG"
                                                        />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <BigSmallValue
                                                        value={par5avg}
                                                        label="PA5 5 AVG"
                                                        />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <BigSmallValue
                                                        value={holesInOne}
                                                        label="HOLES IN ONE"
                                                        />
                                                </Grid>
                                            </Grid>
                                    
                                            <div className="gh-charts">
                                                {/*<PieChart
                                                    labels
                                                    size={300}
                                                    innerHoleSize={100}
                                                    styles={{
                                                        '.chart_lines': {
                                                            strokeWidth: 1
                                                        },
                                                        '.chart_text': {
                                                            fontSize: '1rem',
                                                            fill: '#fff'
                                                        }
                                                    }}
                                                    data={[
                                                        {key: 'Eagles', value: eagles, color: '#E09D30'},
                                                        {key: 'Pars', value: pars, color: '#468847'},
                                                        {key: 'Other', value: other, color: '#304630'},
                                                        {key: 'Birdies', value: birdies, color: '#C24442'},
                                                        {key: 'Bogies', value: bogies, color: '#415CBB'},
                                                        {key: 'D Bogies', value: dbogies, color: '#30468E'}
                                                    ]}
                                                />*/}
                                                <BarChart
                                                    axes
                                                    colorBars
                                                    height={250}
                                                    width={350}
                                                    margin={{top: 0, right: 0, bottom: 30, left: -10}}
                                                    data={[
                                                        {x: 'Eagles', y: eagles, color: '#E09D30'},
                                                        {x: 'Birdies', y: birdies, color: '#C24442'},
                                                        {x: 'Pars', y: pars, color: '#468847'},
                                                        {x: 'Bogies', y: bogies, color: '#415CBB'},
                                                        {x: 'D Bogies', y: dbogies, color: '#30468E'},
                                                        {x: 'Other', y: other, color: '#304630'}
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </Pux>
                                : <Typography className="gh-paraEnd">No rounds added yet.</Typography>}

                                

                                <Heading type="h3" variant="left">Best Rounds</Heading>
                                
                                {totalRounds > 0 ? 
                                    <Pux>
                                        <MaterialAppBar position="static" color="default" className="gh-scrolltabs gh-scrolltabs--extramargin">
                                            <Tabs
                                                className="gh-scrolltabs__tab"
                                                value={this.state.filterYear}
                                                onChange={this.filterStats}
                                                indicatorColor="primary"
                                                textColor="primary"
                                                variant="scrollable"
                                                scrollButtons="auto"
                                            >
                                                <Tab label="all" value="" />
                                                {distinctYears.map((year,i) => {
                                                    return (
                                                        <Tab key={i} label={year} value={year} />
                                                    );
                                                })}
                                            </Tabs>
                                        </MaterialAppBar>
                                        <div className="gh-roundwedges" 
                                                style={{
                                                    height: (122 * bestRoundsData.length) + 'px',
                                                    overflow: 'hidden'
                                                }}>
                                            {bestRoundsData.map((round,i) => {
                                                return (
                                                    <RoundWedge 
                                                        key={i} 
                                                        data={round} />
                                                );
                                            })}
                                        </div>
                                    </Pux>
                                : <Typography>No rounds added yet.</Typography>}

                                <Heading type="h3" variant="left">Best Stroke Score</Heading>

<MaterialAppBar position="static" color="default" className="gh-scrolltabs">
    <Tabs
        className="gh-scrolltabs__tab"
        value={this.state.filterYear}
        onChange={this.filterStats}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
    >
        <Tab label="all" value="" />
        {distinctYears.map((year,i) => {
            return (
                <Tab key={i} label={year} value={year} />
            );
        })}
    </Tabs>
</MaterialAppBar>

{totalRounds > 0 ? 
<Table className="gh-stroketable">
    <TableHead>
        <TableRow>
            <TableCell>Stroke Index</TableCell>
            <TableCell align="right">Avg to Par</TableCell>
        </TableRow>
    </TableHead>
    <TableBody>
        {holeStrokeData.map(data => (
        <TableRow key={data.stroke}>
            <TableCell component="th" scope="row">
                {data.stroke}
            </TableCell>
            <TableCell align="right">{data.avg}</TableCell>
        </TableRow>
        ))}
    </TableBody>
</Table>
: <Typography>No rounds added yet.</Typography> }
                                
                                
                            </div>
                        : <div className="gh-content"><Typography>Oops something went wrong.</Typography></div>}
                    </Pux>
                : <ScreenLoading />}
                <AppBar selected="home" />
            </Pux>
        );
    }
}

Profile.propTypes = {
   profileData: PropTypes.object
}

const mapStateToProps = state => {
    return {
        userId: state.auth.userId,
        userMeta: state.auth.userMeta
    }
}


export default withRouter(connect(mapStateToProps, null)(Profile));
