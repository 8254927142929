import React, {Component} from 'react';
import { Link, withRouter} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TimeIcon from '@material-ui/icons/AccessTime';
import DateDisplay from '../../components/DateDisplay/DateDisplay';
import Typography from '@material-ui/core/Typography';
import BigSmallValue from '../../components/BigSmallValue/BigSmallValue';
import SecurityIcon from '@material-ui/icons/Security';
import ProfilePic from '../../components/ProfilePic/ProfilePic';
import firebase from '../../Firebase';
import Pux from '../../hoc/Pux';




class Golfer extends Component {

    state = {
        golferMeta:null,
        golferMetaFetched:false
    }

    componentDidMount () {

        
        const userId = this.props.uid;
        if(!userId){
            return;
        }
        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);

        
        const userDocRef = db.collection("user_meta").doc(userId);
        userDocRef.get().then((docRef) => {
            if(docRef.exists){

                this.setState({golferMeta:docRef.data()});

                const storage = firebase.storage();
                const storageRef = storage.ref();
                storageRef.child('userpics/' + userId + '-pp.jpg').getDownloadURL().then((url) => {
                    const cachedState = {...this.state}
                    cachedState.golferMeta.profilepic = url;
                    cachedState.golferMetaFetched = true;
                    this.setState(cachedState);
                }).catch((error) => {
                    this.setState({golferMetaFetched:true});
                });
            }
        });

    }

    render() {

        
       

        
        return (
            <Pux>
                {this.state.golferMetaFetched ? 
                    <div className="gh-roundwedge">
                        <div className="gh-detailsMeta gh-roundwedge__meta">
                            <ProfilePic 
                                onClick={() => {
                                    this.props.history.push('/profile/' + this.props.uid);
                                }}
                                displayname={this.state.golferMeta.displayname}
                                profilepic={this.state.golferMeta.profilepic}
                                className="gh-detailsMeta__avatar" />
                            <Typography className="gh-detailsMeta__name" onClick={() => {
                                    this.props.history.push('/profile/' + this.props.uid);
                                }}>
                                {this.state.golferMeta.displayname} <SecurityIcon className="gh-detailsMeta__name-icon" /> {this.state.golferMeta.handicap}
                            </Typography>
                            {this.state.golferMeta.homecourse && this.state.golferMeta.homecourse.length ? 
                                <Typography className="gh-detailsMeta__metaline">{this.state.golferMeta.homecourse}</Typography>
                            : null}
                        </div>
                    </div>
                : null}
            </Pux>
        );
    }
};

export default withRouter(Golfer);