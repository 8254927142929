import React, { Component } from 'react';
import Header from '../../components/Header/Header';
import PayPalDonate from '../../components/PayPalDonate/PayPalDonate';
import AppBar from '../AppBar/AppBar';
//import PropTypes from 'prop-types';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Pux from '../../hoc/Pux';
import Typography from '@material-ui/core/Typography';


  
export class Donate extends Component {

    state = {
        content:null,
        title:null,
        native: false
    }
    

    componentDidMount() {
        
        if(typeof globalNative !== 'undefined'){
            this.setState({native:true});
        }

    }

    componentWillMount() {
        document.title = 'Golf Handicap - Donate to Golf Handicapp - Very Kind!';
    }


    render() {


        return (
            <Pux>
                <Header noads={true} loggedOut={!this.props.isAuthenticated} />
                
                <div className="gh-content gh-content--contentful">

                    

                    <h1 className="gh-heading gh-heading--h1">Donating to <span className="gh-brand-premium">Golf Handicapp</span></h1>

                    <Typography>We hope you have been enjoying our app, as you know it's free to use for unlimited rounds, if you do feel you would like to contribute towards the app then please donate a little to help us cover some basic costs and maybe some new features, any contribution would be greatly appreciated. </Typography>

                    
                    
                        <PayPalDonate />
                    


                    
                    
                    
                            
                       
                </div>
                {this.props.isAuthenticated ? <AppBar /> : null}
            </Pux>
        );
    }
}


const mapStateToProps = state => {
    return {
      isAuthenticated:state.auth.token !== null,
      userMeta: state.auth.userMeta,
    }
}
  
export default withRouter(connect(mapStateToProps)(Donate));
