import * as actionTypes from  '../actions/actionsTypes';
import { updateObject } from '../utility';

const intialState = {
    loginAttempts:0
}
const reducer = (state = intialState,action) => {   
    switch ( action.type ) {
        case actionTypes.INC_ATTEMPTS:
            return updateObject(state, {loginAttempts:state.loginAttempts + 1})
        default:
            return state;
    } 
}

export default reducer;