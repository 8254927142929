import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import './RoundWedge.scss';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import TimeIcon from '@material-ui/icons/AccessTime';
import DateDisplay from '../../components/DateDisplay/DateDisplay';
import Typography from '@material-ui/core/Typography';
import BigSmallValue from '../../components/BigSmallValue/BigSmallValue';
import SecurityIcon from '@material-ui/icons/Security';
import PublicIcon from '@material-ui/icons/Public';
import ProfilePic from '../../components/ProfilePic/ProfilePic';
import { withRouter } from 'react-router-dom';
import firebase from '../../Firebase';
import Pux from '../../hoc/Pux';




class RoundWedge extends Component {

    state = {
        redirect: null,
        roundMeta:null,
        roundMetaFetched:false
    }

    setRedirect = () => {
        this.setState({ redirect: false });
    };

    loadRoundView = (roundid) => {
        let path = '/round/' + roundid;
        this.props.history.push(path);
    }

    componentDidMount () {

        
        const roundUserId = this.props.data.userid;
        if(!roundUserId){
            return;
        }
        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);

        
        const roundMetaDocRef = db.collection("user_meta").doc(roundUserId);
        roundMetaDocRef.get().then((roundMetaDocRef) => {
            if(roundMetaDocRef.exists){

                this.setState({roundMeta:roundMetaDocRef.data()});

                const storage = firebase.storage();
                const storageRef = storage.ref();
                storageRef.child('userpics/' + roundUserId + '-pp.jpg').getDownloadURL().then((url) => {
                    const cachedState = {...this.state}
                    cachedState.roundMeta.profilepic = url;
                    cachedState.roundMetaFetched = true;
                    this.setState(cachedState);
                }).catch((error) => {
                    this.setState({roundMetaFetched:true});
                });
            }
        });

    }

    render() {

        
        let diff = parseFloat(this.props.data.newHandicap - this.props.data.handicap).toFixed(1);
        let prefix = (diff > 0) ? '+' : '';
        const isNine = this.props.data.roundtype && this.props.data.roundtype === '9hole';
        const wedgeClassName = isNine ? 'gh-roundwedge gh-roundwedge--isNine' : 'gh-roundwedge';
        const whsMode = this.props.data && this.props.data.system === 'whs';

        return (
            <Pux>
                {this.state.roundMetaFetched ? 
                <div className={wedgeClassName}>
                    <div className="gh-detailsMeta gh-roundwedge__meta">
                        <ProfilePic 
                            premium={this.state.roundMeta && this.state.roundMeta.role === 'premium'}
                            displayname={this.state.roundMeta.displayname}
                            profilepic={this.state.roundMeta.profilepic}
                            className="gh-detailsMeta__avatar"
                            onClick={() => {
                                this.props.history.push('/profile/' + this.props.data.userid);
                            }} />
                            {whsMode ? (
                                <Typography className="gh-detailsMeta__name" onClick={() => {
                                    this.props.history.push('/profile/' + this.props.data.userid);
                                }}>
                                    {this.state.roundMeta.displayname} <PublicIcon style={{color: 'gold'}} className="gh-detailsMeta__name-icon" />
                                </Typography>
                            ) : (
                                <Typography className="gh-detailsMeta__name" onClick={() => {
                                    this.props.history.push('/profile/' + this.props.data.userid);
                                }}>
                                    {this.state.roundMeta.displayname} <SecurityIcon className="gh-detailsMeta__name-icon" /> {this.props.data.newHandicap} ({prefix + diff})
                                </Typography>
                            )}
                        
                        <Typography className="gh-detailsMeta__metaline --indent">{this.props.data.courseid ? <Link to={'/course/' + this.props.data.courseid}>{this.props.data.coursename}</Link> : this.props.data.coursename}</Typography>
                        
                        <BigSmallValue
                            value={this.props.data.points}
                            label="POINTS"
                            className="gh-detailsMeta__bigsmall gh-roundwedge__meta-bigsmall"
                            />
                        {isNine && (
                            <>
                                <Badge 
                                    badgeContent={`9h`} 
                                    className="gh-roundwedge__9hole"
                                    color="secondary"
                                    >
                                        {` `}
                                    </Badge>
                            </>
                        )}
                            
                    </div>
                    <div className="gh-roundwedge__base">
                        <Typography className="gh-roundwedge__base-extra">   
                            <TimeIcon className="gh-roundwedge__base-extra-icon" /><DateDisplay date={this.props.data.rounddate} />
                        </Typography>
                        <Button className="gh-roundwedge__base-action" variant="contained" onClick={() => this.loadRoundView(this.props.data.roundid)} color="primary">
                            View
                        </Button>
                    </div>
                </div>
                : 
                <div className="gh-roundwedge gh-roundwedge--hold">
                    
                </div>
                }
            </Pux>
        );
    }
};

export default withRouter(RoundWedge);