import React, { Component } from 'react';
import Header from '../../components/Header/Header';
import AppBar from '../../containers/AppBar/AppBar';
//import PropTypes from 'prop-types';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Pux from '../../hoc/Pux';
import Typography from '@material-ui/core/Typography';
import Heading from '../../components/Heading/Heading';
import IAPButtons from '../../containers/IAPButtons/IAPButtons';
import Button from '@material-ui/core/Button';
import * as routes from '../../utils/routes.js';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';

  
export class PremiumDetails extends Component {

    state = {
        content:null,
        title:null,
        native: false
    }
    

    componentDidMount() {
        
        if(typeof globalNative !== 'undefined'){
            this.setState({native:true});
        }

    }

    componentWillMount() {
        document.title = 'Golf Handicap - Upgrade to Premium - Making handicap easy';
    }


    render() {

        let redirect = null;
        if(this.props.userMeta && this.props.userMeta.role === 'premium') {
            redirect = <Redirect to="/" />
        }

        let actions = null;
        if(!this.props.isAuthenticated) {
            actions = (
                <Typography className="gh-actionline">
                    <Button
                        className="gh-actionline__button"
                        type="submit"
                        fullWidth
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            this.props.history.push('/signin');
                        }}
                    >
                        Sign In
                    </Button>
                    <Button
                        className="gh-actionline__button"
                        fullWidth
                        type="submit"
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            this.props.history.push('/signup');
                        }}
                    >
                        Sign Up FREE
                    </Button>
                </Typography>
            );
        }
        


        return (
            <Pux>
                <Header noads={true} loggedOut={!this.props.isAuthenticated} />
                {redirect}
                <div className="gh-content gh-content--contentful">

                    {this.state.native && this.props.isAuthenticated ? <IAPButtons /> : null}

                    {!this.state.native ? 
                        <React.Fragment>
                            <div className="gh-offer gh-offer--reducedbottom" onClick={this.purchase}>
                                <Typography className="gh-offer__text">Apple iOs App
                                </Typography>
                                <Link 
                                    to={routes.APPLE_STORE_APP}
                                    className="gh-offer__price gh-offer__price--image"
                                    >
                                    <img 
                                        src="https://firebasestorage.googleapis.com/v0/b/golf-handicapp.appspot.com/o/assets%2Fapp-store-logo.png?alt=media&token=866144a4-822a-4522-a0cf-01f1e7d2b155"
                                        alt="Download on the App Store" />
                                </Link>
                            </div>
                            <div className="gh-offer gh-offer--reducedbottom">
                                <Typography className="gh-offer__text">Android App
                                </Typography>
                                <Link 
                                    to={routes.GOOGLE_PLAY_APP}
                                    className="gh-offer__price gh-offer__price--image"
                                    >
                                    <img 
                                        src="https://firebasestorage.googleapis.com/v0/b/golf-handicapp.appspot.com/o/assets%2Fgoogle-play-logo-new.png?alt=media&token=c4941689-c7d5-442f-a425-b51b5684665e"
                                        alt="Get it on Google play" />
                                </Link>
                            </div>
                            <Typography className="gh-disclaimer">You will need to download the app to upgrade to premium.</Typography>
                        </React.Fragment>
                    : null}
                    
                    <div className="gh-herotext">

                        <div className="gh-banner gh-herotext__hero">
                            <img 
                                className="gh-banner__background"
                                src="https://firebasestorage.googleapis.com/v0/b/golf-handicapp.appspot.com/o/assets%2Fpromo%2Faugusta.jpg?alt=media&token=d33b64f7-b272-4a18-a1bd-96ad44716b6c"
                                alt="Golf Handicapp" />
                            <img 
                                className="gh-banner__feature"
                                src="https://firebasestorage.googleapis.com/v0/b/golf-handicapp.appspot.com/o/assets%2Fpromo%2Fscorecard-iphone.png?alt=media&token=5776b05f-0fcf-42f2-9bec-f7e6305afba3"
                                alt="Golf Handicapp" />
                            <Heading variant="h1">Upgrade to <span className="gh-premium">Premium</span></Heading>
                        </div>
                        <div className="gh-banner gh-herotext__text">
                            {actions}
                            
                            <div className="gh-content__block gh-herotext__text">
                                <Heading type="h2" variant="h2">Why <span className="gh-premium">Premium</span>?</Heading>
                                <List className="gh-lists">
                                    <ListItem className="gh-lists__item gh-lists__item--promo">
                                        <Avatar className="gh-lists__item-avatar">
                                            <CheckIcon className="gh-lists__item-avatar-icon" />
                                        </Avatar>
                                        <ListItemText primary="No Ads" secondary="Ad free use" />
                                    </ListItem>
                                    <ListItem className="gh-lists__item gh-lists__item--promo">
                                        <Avatar className="gh-lists__item-avatar">
                                            <CheckIcon className="gh-lists__item-avatar-icon" />
                                        </Avatar>
                                        <ListItemText primary="Unlimited" secondary="Add as many rounds as you want!" />
                                    </ListItem>
                                    <ListItem className="gh-lists__item gh-lists__item--promo">
                                        <Avatar className="gh-lists__item-avatar">
                                            <CheckIcon className="gh-lists__item-avatar-icon" />
                                        </Avatar>
                                        <ListItemText primary="Stats" secondary="A great visual way to view your stats" />
                                    </ListItem>
                                    <ListItem className="gh-lists__item gh-lists__item--promo">
                                        <Avatar className="gh-lists__item-avatar">
                                            <CheckIcon className="gh-lists__item-avatar-icon" />
                                        </Avatar>
                                        <ListItemText primary="Handicap History" secondary="A complete list of all your previously added round." />
                                    </ListItem>
                                </List>
                            </div>
                        </div>
                    </div>

                    

                    <div className="gh-herotext">
                        <div className="gh-herotext__hero">
                            <img 
                                src="https://firebasestorage.googleapis.com/v0/b/golf-handicapp.appspot.com/o/assets%2Fpromo%2Fstats-hold.jpg?alt=media&token=79770de3-5c17-4e72-93d3-f7a8115e5047"
                                alt="Using Golf Handicapp" />
                        </div>
                        <div className="gh-banner gh-herotext__text">
                            <Typography className="gh-content__para">
                                Not a member of a club? This app is perfect for the golfer who isn't a member of a club and wants to work out your handicap adjustment.   
                            </Typography>

                            {actions}
                        </div>
                    </div>
                            
                       
                </div>
                {this.props.isAuthenticated ? <AppBar /> : null}
            </Pux>
        );
    }
}
/*
PremiumDetails.propTypes = {
    searchTypeHandler: PropTypes.func
}*/

const mapStateToProps = state => {
    return {
      isAuthenticated:state.auth.token !== null,
      userMeta: state.auth.userMeta,
    }
}
  
export default withRouter(connect(mapStateToProps)(PremiumDetails));
