import React, { Component } from 'react';
import { renderEmail } from  '../../utils/emails';
import '../../assets/scss/core.scss';
import Header from '../../components/Header/Header';
import AppBar from '../AppBar/AppBar';
import {validateForm, scrollToRef, validateUnit } from  '../../utils/validation';
//import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Pux from '../../hoc/Pux';
import ScreenLoading from '../../components/ScreenLoading/ScreenLoading';
import Heading from '../../components/Heading/Heading';
import IconButton from '@material-ui/core/IconButton';
import firebase from '../../Firebase';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import MailIcon from '@material-ui/icons/MailOutline';
import FlagIcon from '@material-ui/icons/GolfCourse';
import AddressIcon from '@material-ui/icons/LocationOn';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import SuccessIcon from '@material-ui/icons/CheckCircleOutlined';



const styles = theme => ({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      position: 'relative',
      margin: '8px 0'
    },
    fabProgress: {
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    customWidth: {
        width: 200,
    },
    noMaxWidth: {
        maxWidth: 'none',
    },
});


export class RequestCourse extends Component {

    state = {
        showSnackBar:false,
        snackBarMessage:'Thanks we have received your request to a new course. We hope to add your course in the next 24-48 hours',
        snackBarType: 'success',
        showPassword: false,
        sssTooltipOpen:false,
        scoreCardCache:false,
        startAdding:false,
        updatedFromStorage:false,
        resetPageOverlay:false,
        formLoading:false,
        form: {
            isValid:true,
            firstError:null,
            fields: {
                requestername: {
                    value: '', 
                    default: '',
                    valid: true,
                    errorType: null,
                    rules: {
                        notEmpty : {
                            message: 'Required field.'
                        },
                        maxlength: {
                            message: 'Name too long.',
                            length: 100
                        }
                    }
                },
                requesteremail: {
                    value: '', 
                    default: '',
                    valid: true,
                    errorType: null,
                    rules: {
                        notEmpty : {
                            message: 'Dont forget your email.'
                        },
                        emailFormat : {
                            message: 'Oops, your email address doesn\'t look right.'
                        },
                        maxlength: {
                            message: 'Have you got a shorter email?',
                            length: 50
                        }
                    }
                },
                coursename: {
                    value: '', 
                    default: '',
                    valid: true,
                    errorType: null,
                    rules: {
                        notEmpty : {
                            message: 'Required field.'
                        },
                        maxlength: {
                            message: 'Course too long.',
                            length: 100
                        }
                    }
                },
                address: {
                    value: '', 
                    default: '',
                    valid: true,
                    errorType: null,
                    rules: {
                        notEmpty : {
                            message: 'Required field.'
                        },
                        maxlength: {
                            message: 'Address too long.',
                            length: 1000
                        }
                    }
                }
            }
        }
    }

    submitHandler = (event) => {
        event.preventDefault();
        const validateFormState = validateForm(this.state.form);
        this.setState({form:validateFormState,snackBarOpen:true}, () => {
            if(this.state.form.isValid){
                this.setState({formLoading:true});
                this.sendCourseRequest();
            } else {
                if(this.state.form.firstError){
                    scrollToRef(this.state.form.fields[this.state.form.firstError].ref);
                }
            }
        });
        
    }

    inputChangedHandler = (event) => {
        const inputValue = event.target.value;
        const inputName = event.target.name;
        const updateState = {...this.state};
        updateState.form.fields[inputName].value = inputValue;
        this.setState(updateState);
    }

    blurField = (event) => {
        const fieldName = event.target.name;
        if(validateUnit(fieldName)){
            const updateState = {...this.state};
            updateState.form.fields[fieldName].errorType = false;
            updateState.form.fields[fieldName].valid = true;
            this.setState(updateState);
        }
    }
    
    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        this.setState({snackBarOpen:false});
    };

    handleDateChange = date => {
        //console.log("date: ",date);
        const updateState = {...this.state};
        updateState.form.fields.rounddate.value = date;
        this.setState(updateState);
    };

    handleTooltipClose = () => {
        this.setState({ sssTooltipOpen: false });
    };
    
    handleTooltipOpen = () => {
        this.setState({ sssTooltipOpen: true });
    };

    updateFieldFromProps = (value,fieldname) => {
        const updateState = {...this.state};
        updateState.form.fields[fieldname].value = value;
        this.setState(updateState);
        return value;
    }

    sendCourseRequest = () => {
        const requestGolfCourse = firebase.functions().httpsCallable('requestGolfCourse');
        const emailHTML = renderEmail({
            title: 'Request New Course',
            content: `
                        <span style="display:block;">Name: ${this.state.form.fields.requestername.value} </span>
                        <span style="display:block;">Email: ${this.state.form.fields.requesteremail.value} </span>
                        <span style="display:block;">Course name: ${this.state.form.fields.coursename.value} </span>
                        <span style="display:block;">Address: ${this.state.form.fields.address.value} </span>
                    `
            }
        );
        const emailData = {
            emailHTML : emailHTML,
            subject: 'Request New Course'
        }
        
        requestGolfCourse(emailData,'context').then((result) => {
            // Read result of the Cloud Function.
            //console.log("result: ",result);
            const cachedState = {...this.state}
            cachedState.formLoading = false;
            cachedState.showSnackBar = true;
            cachedState.snackBarMessage = 'Thanks we have received your request for a new course to be added. We hope to add your course in the next 3 - 4 days';
            cachedState.snackBarType = 'success';
            cachedState.form.fields['requestername'].value = '';
            cachedState.form.fields['requesteremail'].value = '';
            cachedState.form.fields['coursename'].value = '';
            this.setState(cachedState);
            
        });
    }
   

    componentWillMount() {

        
        const cachedState = {...this.state};
        const cachedProps = {...this.props};

        //need to update data not from elements
        if(cachedProps.userMeta){
            //console.log("update name and id",cachedProps);
            if(cachedProps.userMeta.displayname) {
                cachedState.form.fields.requestername.value = cachedProps.userMeta.displayname;
            }
            this.setState(cachedState);
        }
    }

    backClick = () => {
        this.props.history.goBack();
    }

    closeSnackBar = () => {
        this.setState({showSnackBar:false});
    }

   

    render() {

        const { classes } = this.props;
        const buttonClassname = classNames({
        [classes.buttonSuccess]: true,
        });

       


        return (
            <Pux>
                <Header backClick={this.backClick} />
                <div className="gh-content">
                    <Heading type="h1" variant="central">Request New Course</Heading>
                    {this.props.userMeta ? 
                        <Pux>
                            <form className="form" onSubmit={this.submitHandler}>

                               <Typography>We will aim to add all requested courses as soon as possible usually within 3 - 4 days.</Typography>
                               
                                <FormControl margin="normal" className="form__formcontrol" fullWidth error={!this.state.form.fields.requestername.valid}>
                                    <InputLabel htmlFor="requestername">Your Name</InputLabel>
                                    <Input 
                                        onChange={this.inputChangedHandler}
                                        className="form__input" 
                                        id="requestername" 
                                        ref={(this.state.form.fields.requestername.ref) ? this.state.form.fields.requestername.ref : null}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <EditIcon />
                                            </InputAdornment>
                                        }
                                        onBlur={this.blurField}
                                        name="requestername" 
                                        value={this.state.form.fields.requestername.value}
                                        autoComplete="requestername" 
                                         />
                                    {!this.state.form.fields.requestername.valid ?
                                        <FormHelperText id="requestername-error">{this.state.form.fields.requestername.rules[this.state.form.fields.requestername.errorType].message}</FormHelperText>
                                    : null }
                                </FormControl>
                                <FormControl margin="normal" className="form__formcontrol" fullWidth error={!this.state.form.fields.requesteremail.valid}>
                                    <InputLabel htmlFor="requesteremail">Your Email</InputLabel>
                                    <Input 
                                        onChange={this.inputChangedHandler}
                                        className="form__input" 
                                        id="requesteremail" 
                                        ref={(this.state.form.fields.requesteremail.ref) ? this.state.form.fields.requesteremail.ref : null}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <MailIcon />
                                            </InputAdornment>
                                        }
                                        onBlur={this.blurField}
                                        name="requesteremail" 
                                        value={this.state.form.fields.requesteremail.value}
                                        autoComplete="requesteremail" 
                                         />
                                    {!this.state.form.fields.requesteremail.valid ?
                                        <FormHelperText id="requesteremail-error">{this.state.form.fields.requesteremail.rules[this.state.form.fields.requesteremail.errorType].message}</FormHelperText>
                                    : null }
                                </FormControl>
                                <FormControl margin="normal" fullWidth error={!this.state.form.fields.coursename.valid}>
                                    <InputLabel htmlFor="coursename">Course Name</InputLabel>
                                    <Input 
                                        onChange={this.inputChangedHandler}
                                        className="form__input" 
                                        id="coursename" 
                                        onBlur={this.blurField}
                                        ref={(this.state.form.fields.coursename.ref) ? this.state.form.fields.coursename.ref : null}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <FlagIcon />
                                            </InputAdornment>
                                        }
                                        name="coursename" 
                                        value={this.state.form.fields.coursename.value}
                                        autoComplete="coursename" 
                                         />
                                    {!this.state.form.fields.coursename.valid ?
                                        <FormHelperText id="coursename-error">{this.state.form.fields.coursename.rules[this.state.form.fields.coursename.errorType].message}</FormHelperText>
                                    : null }
                                </FormControl>

                                <FormControl margin="normal" fullWidth error={!this.state.form.fields.address.valid}>
                                    <InputLabel htmlFor="address">Address</InputLabel>
                                    <Input 
                                        onChange={this.inputChangedHandler}
                                        className="form__input" 
                                        id="address" 
                                        onBlur={this.blurField}
                                        ref={(this.state.form.fields.address.ref) ? this.state.form.fields.address.ref : null}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <AddressIcon />
                                            </InputAdornment>
                                        }
                                        name="address" 
                                        value={this.state.form.fields.address.value}
                                        autoComplete="address" 
                                         />
                                    {!this.state.form.fields.address.valid ?
                                        <FormHelperText id="address-error">{this.state.form.fields.address.rules[this.state.form.fields.address.errorType].message}</FormHelperText>
                                    : null }
                                </FormControl>
                                
                                <FormControl margin="normal" fullWidth>
                                    <div className={classes.wrapper}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            size="large"
                                            variant="contained"
                                            color="primary"
                                            disabled={this.state.formLoading}
                                            className={buttonClassname}
                                        >
                                            Request Course
                                        </Button>
                                        {this.state.formLoading ? <CircularProgress size={24} className={classes.buttonProgress} /> : null}
                                    </div>
                                </FormControl>
                                
                                <Snackbar
                                    className={(this.state.snackBarType === 'success') ? 'gh-snackBar gh-snackBar--success' : 'gh-snackBar'}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    open={this.state.showSnackBar}
                                    autoHideDuration={6000}
                                    onClose={this.closeSnackBar}
                                    ContentProps={{
                                        'aria-describedby': 'message-id',
                                    }}
                                    message={
                                        <span id="message-id" className="gh-snackBar__message">
                                            <SuccessIcon className="gh-snackBar__message-svg" />
                                            {this.state.snackBarMessage}
                                        </span>
                                    }
                                    action={[
                                        <IconButton
                                            key="close"
                                            aria-label="Close"
                                            color="inherit"
                                            onClick={this.closeSnackBar}
                                            >
                                        <CloseIcon />
                                        </IconButton>
                                    ]}
                                    />
                            </form>
                        </Pux>    
                         : <ScreenLoading />}
                </div>
                <AppBar selected="home" />
            </Pux>
        );
    }
}
/*
RequestCourse.propTypes = {
    searchTypeHandler: PropTypes.func
}*/

const mapStateToProps = state => {
    return {
        userMeta: state.auth.userMeta,
        userId: state.auth.userId
    }
}

const mapDispatchToProps = dispatch =>  {
    return {
        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RequestCourse));
