import React from 'react';
import './Logo.scss';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

const logo = (props) => {

    
    let height = '36px';
    if(props.type === 'header'){
        height = '26px';
    }
    const parentClasses = ['gh-logo',props.bemClass];
    
    return (
        <div className={parentClasses.join(' ')}>
            <Link to="/" className="gh-logo__link">
                <svg className="gh-logo__svg" height={height} viewBox="0 0 305.75 41">
                    <g transform="translate(-39.75 -308.5)">
                        <g transform="translate(36 307.572)">
                            <path id="Path_105" data-name="Path 105" d="M36.03,9.07,16.319,2V34.465H11.392v-3.12C6.981,31.976,4,33.13,4,34.465c0,1.984,6.628,3.607,14.783,3.607s14.783-1.623,14.783-3.607c0-1.786-5.322-3.265-12.319-3.553V14.589Z" transform="translate(0 0)" fill="#28844b" stroke="#fff" strokeWidth="0.5"/>
                        </g>
                        <text transform="translate(85 309)" fill="#fff" fontSize="36" fontFamily="oregonregular"><tspan x="0" y="33">GOLF</tspan></text>
                        <text transform="translate(171 309)" fill="#fff" stroke="#fff" strokeWidth="0.5" fontSize="36" fontFamily="oregonregular"><tspan x="0" y="33">HANDIC</tspan><tspan y="33" fill="#28844b">APP</tspan></text>
                    </g>
                </svg>
                {props.tagline ? <Typography variant="body1" className="gh-logo__tagline">Making golf handicap easy.</Typography> : null}
            </Link>
        </div>
    );
};
export default logo;