export const HANDICAP_CONTS = {
    maxIndexHistory: 20,
    minIndexHistory: 3,
}


export const getCalcs = (state) => {
    let calcs = {
        points:0,
        topar:0
    };
    const currentRound = {...state.round}
    const userMeta = {...state.auth.userMeta}
    let handicap = currentRound.handicap;
    // console.log("currentRound: ", currentRound);
    if(currentRound.system === 'whs' && userMeta.whindex) {
        handicap = getWHSPlayingHandicap(userMeta.whindex, currentRound);
    }
    
    currentRound.scores.map((hole,i) => {
        let holeData = Object.values(hole);
        if(holeData[0].length){
            const [holePar, holeScore, holeStroke] = holeData[0];
            if(holePar && holeScore && holeStroke){ //make sure all exist to get points
                const holeScoreData = getHoleScore(holePar, holeScore, holeStroke, handicap);
                calcs.points += holeScoreData.points;
                calcs.topar += holeScoreData.topar;
            }
        }
        return false;
    });
    return calcs;
}

export const getAdjustedScoreAndPar = (scores, handicap, cutoff) => {
    let adjustedGross = 0;
    let coursePar = 0;
    scores.forEach((hole,i) => {
        let holeData = Object.values(hole);
        if(holeData[0].length){
            const [holePar, holeScore, holeStroke] = holeData[0];
            const shots = hasShots(holeStroke,handicap);
            coursePar += holePar;
            if(holeScore > (holeScore + shots + cutoff)){
				adjustedGross += (holeScore + shots + cutoff);	
			} else {
                adjustedGross += holeScore;
            }
            
        }
    });
    return { adjustedGross, coursePar }
}

export const getRoundIndex = (adjusted, courseRating, slopeRating, isNineHole) => {
    // =SUM((adj - course rating) * 113 / slope rating)
    const roundIndex = parseFloat(((adjusted - courseRating) * 113 / slopeRating).toFixed(1));
    
    return isNineHole ? (roundIndex || 0) * 2 : (roundIndex || 0);
}

export const getContributingRoundsLimit = total => {

    if(total === 20) return 8;
    if(total >= 19) return 7;
    if(total >= 17) return 6;
    if(total >= 15) return 5;
    if(total >= 12) return 4;
    if(total >= 9) return 3;
    if(total >= 6) return 2;

    // for all else
    return 1;
}

export const getWHIndexAdjustments = total => {

    if(total === 3) return 2;
    if(total === 4) return 1;
    if(total === 6) return 1;
    // for all else
    return 0;
}

export const calculateSlopeRating = () => {
    // maybe need to tweak
    return 113;
}

export const calculateCourseRating = roundsss => {
    // maybe need to tweak
    return roundsss;
}

export const calculateAdjustedScore = (scores, handicap) => {
    const { adjustedGross } = getAdjustedScoreAndPar(scores, handicap, 2);
    return adjustedGross;
}

export const extractIndexData = data => {
    return data.filter((round, i) => i < HANDICAP_CONTS.maxIndexHistory && round.system === 'whs').map((round) => {
        const { coursename, points, rounddate, roundIndex, adjustedGross, roundid } = round;
        const roundDate = new Date(rounddate.seconds * 1000);
        return { id: roundid, course: coursename, adj: adjustedGross, index: roundIndex, points, date: roundDate };
    });
}

export const getIndexBasedOnLatestRounds = (rounds, adjustments, totalrounds) => {

    if(totalrounds < HANDICAP_CONTS.minIndexHistory) {
        return null;
    }

    const handicapIndexTally = rounds.reduce((accu, current) => accu + current.index, 0);
    let indexedHandicap = handicapIndexTally / rounds.length;
    if(totalrounds === 3 || totalrounds === 4 || totalrounds === 5) {
        console.log("rounds: ", rounds);
        console.log("rounds[0]: ", rounds[0]);
        indexedHandicap = rounds[0].index;
    }
    return (indexedHandicap - adjustments).toFixed(1);
}

export const getFinishedStats = state => {
    const stats = {
        newHandicap:null,
        coursePar:null
    };
    
    const currentRound = {...state.round}
    const userMeta = {...state.auth.userMeta}
    const isWHS = currentRound.system === 'whs';
    const isNineHole = currentRound.roundtype && currentRound.roundtype === '9hole';
    let handicap = parseFloat(currentRound.handicap);
    if(currentRound.system === 'whs' && userMeta.whindex) {
        handicap = getWHSPlayingHandicap(userMeta.whindex, currentRound);
    }
    const gender = currentRound.gender;
    const roundsss = currentRound.roundsss;
    const cutoff = (gender === 'male') ? 2 : 3; //most amount of shots you can go over
    const { adjustedGross, coursePar } = getAdjustedScoreAndPar(currentRound.scores, handicap, cutoff);

    if(!isWHS) {
        stats.newHandicap = getNewHandicap(handicap,roundsss,adjustedGross, isNineHole);
    }

    if(isWHS) {
        stats.whs = true;
        stats.recordedHandicapIndex = userMeta.whindex;
        stats.recordedPlayingHandicap = handicap;
        stats.roundIndex = getRoundIndex(adjustedGross, currentRound.courserating, currentRound.sloperating, isNineHole);
        stats.adjustedGross = adjustedGross;
    }
    
    stats.coursePar = coursePar;
    return stats;
}

const getNewHandicap = (handicap, roundsss, adjustedGross, isNineHole) => {
    
    let newHandicap = handicap;//lets work out the new handicap!
    // console.log("[old handicap]: ",handicap);
    let playingHandicap = getPlayingHandicap(handicap);
    if(isNineHole) {
        playingHandicap = Math.ceil(playingHandicap / 2);
    }

    // console.log("[playingHandicap]",playingHandicap);
    let adjustments = adjustedGross - roundsss - playingHandicap;
    if(isNineHole) {
        adjustments = adjustments * 2;
    }
    const category = getCategory(handicap > 0 ? handicap : 0);
    
    if(adjustments >= aMatrix[category].buffer){
        newHandicap = handicap + 0.1; //add the standrd 0.1 
    } else if (adjustments < 0) {
        const adjumentsLength = adjustments * -1;//positive up the adjustments
        newHandicap = handicap;
        //console.log("first set handicap to old handicap");
        for(var i = 1; i <= adjumentsLength; i++){
            const loopCat = getCategory(newHandicap);
            const loopAdjustment = aMatrix[loopCat].adj;
            //console.log("loopCat: ",loopCat);
            //console.log("loopAdjustment: ",loopAdjustment);
            newHandicap += loopAdjustment;
        }
    }
    //return either plus 0.1 or correctly adjusted handicap   
    return newHandicap.toFixed(1);
}

const getCategory = (handicap) => {
    //find appropriate category
    let cat = '';
    for(let x in aMatrix){
        if(handicap <= aMatrix[x].max && handicap >= aMatrix[x].min){
            cat = x;
        }			
    }
    return cat;
}

const getHoleScore = (holePar, holeScore, holeStroke, handicap) => {
    let points = 0;
    const shots = hasShots(holeStroke,handicap);
    const net = (holeScore-(holePar+shots));
    switch (net) {
        case 1:
            points = 1;
            break;
        case 0:
            points = 2;
            break;
        case -1:
            points = 3;
            break;
        case -2:
            points = 4;
            break;
        case -3:
            points = 5;
            break;
        case -4:
            points = 6;
            break;
        case -5:
            points = 7;
            break;
        case -6:
            points = 8;
            break;
        default:
            points = 0;
            break;
    }
    return {
        points:points,
        topar:net
    };
}

const hasShots = (holeStroke,handicap) => {
    const playingHandicap = getPlayingHandicap(handicap);
    let shots = 0;
    if(playingHandicap >= 18) {
        shots++;
    }
    let getShotsOnRangeMax = (playingHandicap < 18) ? playingHandicap : (playingHandicap % 18);
    //allowance for plus golfers
    if(getShotsOnRangeMax < 0) {
        getShotsOnRangeMax = 18 + getShotsOnRangeMax;
        shots--;
    }

    //see if they get a shot
    if(holeStroke <= getShotsOnRangeMax){
        shots++;
    }
    
    return shots;		
}

export const getPlayingHandicap = (handicap) => {
    const shift = handicap-Math.floor(handicap);
    return shift >= 0.49 ? (Math.floor(handicap)+1) : (Math.floor(handicap));
}

const getCoursePar = (card, isNine) => {
    if(!card) {
        return false;
    }
    let holesData = Object.values(card);
    if(holesData.length !== 18 || (isNine && (holesData.length === 9 || holesData.length === 18))){
        return false;
    }
    
    let coursePar = 0;
    holesData.map((hole,i) => {
        if(hole[0]){
            coursePar += hole[0];
        }
    });
    return coursePar;
}

export const getWHSPlayingHandicap = (handicap, round) => {

    // Before 2020, the calculation of course handicap was done using this formula: 
    // Handicap Index X Slope rating / 113
    let prePlayingHandicap = handicap * (round.sloperating / 113);

    // The 2020 calculation is done using this new formula: 
    // Handicap Index X (Slope rating/113) + (Course Rating - Par)
    // const coursecard = round.coursecard ? round.coursecard.card : null;
    // const coursePar = coursecard ? getCoursePar(round.coursecard.card, round.roundtype === '9hole') : round.coursePar;
    // if(coursePar){
    //     prePlayingHandicap = (handicap * (round.sloperating / 113)) + (round.courserating - coursePar);
    // } 

    return getPlayingHandicap(prePlayingHandicap);
}

export const aMatrix = {
    cat1 : {
        max : 5.4,
        min : 0,
        buffer : 1,
        adj : -0.1
    },
    cat2 : {
        max : 12.4,
        min : 5.5,
        buffer : 2,
        adj : -0.2
    },
    cat3 : {
        max : 20.4,
        min : 12.5,
        buffer : 3,
        adj : -0.3
    },
    cat4 : {
        max : 28.4,
        min : 20.5,
        buffer : 4,
        adj : -0.4
    },
    cat5 : {
        max : 36.4,
        min : 28.5,
        buffer : 5,
        adj : -0.5
    },
    cat6 : {
        max : 54,
        min : 36.5,
        buffer : 6,
        adj : -0.6
    }
}