//import axios from 'axios';
import firebase from '../../Firebase.js';
import * as actionTypes from  './actionsTypes.js';
import {getCalcs, getFinishedStats} from  './handicap.js';
import {authUpdateMeta, reindexHandicap} from  './auth.js';
import * as constants from  '../../utils/constants.js';

export const roundStart = (roundData, userId) => {

    let courseid = '';
    if(parseInt(roundData.courseid)){
        courseid = parseInt(roundData.courseid);
    } else if(roundData.courseid.length > 1) {
        courseid = roundData.courseid;
    }
    

    return {
        type: actionTypes.ROUND_START,
        userid:userId,
        coursename:roundData.coursename,
        courseid:courseid,
        coursecard:roundData.coursecard,
        rounddate:roundData.rounddate,
        roundsss:parseInt(roundData.roundsss),
        roundtype:roundData.roundtype,
        handicap:parseFloat(roundData.handicap),
        viewname:roundData.viewname,
        gender:roundData.gender,
        sloperating:parseFloat(roundData.sloperating),
        courserating:parseFloat(roundData.courserating),
        system: roundData.system,
        loading:false
    };
}

export const roundUpdate = (roundData, userId) => {
    return {
        type: actionTypes.ROUND_UPDATE,
        userid:userId,
        coursename:roundData.coursename,
        courseid:roundData.courseid,
        coursecard:roundData.coursecard,
        rounddate:new Date(roundData.rounddate),
        roundsss:roundData.roundsss,
        roundtype:roundData.roundtype,
        handicap:roundData.handicap,
        gender:roundData.gender,
        viewname:roundData.viewname,
        points:roundData.points,
        topar:roundData.topar,
        scores:roundData.scores,
        system: roundData.system,
        sloperating: roundData.sloperating,
        courserating: roundData.courserating,
        updated:true,
        loading:false
    };
}

export const roundViewUpdate = (viewname) => {
    return {
        type: actionTypes.ROUND_VIEW_UPDATE,
        viewname:viewname
    };
}

export const updateHoleData = (holeNumber,score,par,stroke) => {
    return {
        type: actionTypes.ROUND_HOLE_UPDATE,
        holeNumber:holeNumber,
        score:score,
        par:par,
        stroke:stroke
    };
}

export const addHoleData = (holeNumber,par,stroke) => {
    return {
        type: actionTypes.ROUND_HOLE_ADD,
        holeNumber:holeNumber,
        par:par,
        stroke:stroke
    };
}

export const preRoundScoreReset = scoresOnly => {
    return (dispatch) => {
        dispatch(roundScoreReset());
        dispatch(storeRound());
    }
}

export const roundScoreReset = scoresOnly => {

    if(scoresOnly) {
        return {
            type: actionTypes.ROUND_SCORE_RESET,
            scores: [
                {1: []}, {2: []}, {3: []}, {4: []}, {5: []}, {6: []}, {7: []}, {8: []}, {9: []}, {10: []}, {11: []}, {12: []}, {13: []}, {14: []}, {15: []}, {16: []}, {17: []}, {18: []}, 
            ],
            viewname:'addround',
            points:0,
            topar:0, 
        }
    } else {
        return {
            type: actionTypes.ROUND_SCORE_RESET,
            coursename:null,
            courseid:null,
            coursecard:null,
            scores: [
                {1: []}, {2: []}, {3: []}, {4: []}, {5: []}, {6: []}, {7: []}, {8: []}, {9: []}, {10: []}, {11: []}, {12: []}, {13: []}, {14: []}, {15: []}, {16: []}, {17: []}, {18: []}, 
            ],
            viewname:'addround',
            points:0,
            topar:0,
        };
    }
    
}

export const roundCompleteReset = (id,type) => {
    return {
        type: actionTypes.ROUND_COMPLETE_RESET,
        coursename:null,
        courseid:null,
        rounddate:null,
        roundsss:null,
        roundtype:null,
        handicap:null,
        roundtype:null,
        gender:null,
        scores: [
            {1: []}, {2: []}, {3: []}, {4: []}, {5: []}, {6: []}, {7: []}, {8: []}, {9: []}, {10: []}, {11: []}, {12: []}, {13: []}, {14: []}, {15: []}, {16: []}, {17: []}, {18: []}, 
        ],
        viewname:'/round/' + id + '/' + type,
        points:0,
        topar:0,
        roundid:null,
        loading:false,
        courserating: null,
        sloperating: null,
    };
}


export const roundRecover = (userId) => {
    return dispatch => {
        //console.log("action roundRecover?",userId);
        let tempRound = localStorage.getItem(userId+'_temp_round');
        if(tempRound) {
            //console.log("found a round, rather than recover round, we should create an overlay here to give the user the option of accept or delete: ",tempRound);
            let roundData = JSON.parse(tempRound);
            dispatch(roundUpdate(roundData, userId));
        }
    };
};

export const roundSave = () => {
    //console.log("[roundSave] state:",state);
    return (dispatch, getState) => {
        dispatch(roundCalculate(getState()));
        dispatch(storeRound());
    }
};

export const storeRound = () => {
    return (dispatch, getState) => {
        const currentState = getState();
        if(!currentState.round.roundid) {
            localStorage.setItem(currentState.auth.userId+'_temp_round',JSON.stringify(currentState.round));
        }
        
    }
}

export const cleanRound = (roundid,type) => {
    return (dispatch, getState) => {
        const currentState = getState();
        localStorage.removeItem(currentState.auth.userId+'_temp_round');
        dispatch(roundCompleteReset(roundid,type));
    }
}

export const roundCalculate = (state) => {
    const calcs = getCalcs(state);
    return {
        type: actionTypes.ROUND_CALCULATE,
        points:calcs.points,
        topar:calcs.topar
    };
}

export const roundComplete = () => {
    return (dispatch, getState) => {
        console.log("[roundComplete]");
        const userId = getState().auth.userId;
        dispatch(addRoundHandicap(getState()));
        dispatch(addRoundToDB());
        dispatch(reindexHandicap(userId));
    }
};

export const addRoundToDB = () => {

    return (dispatch, getState) => {
        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);

        const roundUserId = getState().round.userid;
        const userId = getState().auth.userId;
        const newHandicap = getState().round.newHandicap;
        const cacheUserMeta = getState().auth.userMeta;

        if(userId !== roundUserId){ //change this to not the same
            return;//stop for 
        }
        //const newTrialInteractionCount = cacheUserMeta.trialInterActionCount ? cacheUserMeta.trialInterActionCount + 1 : 1;
        const newTrialInteractionCount = 0;
        const updatedMeta = {
            handicap:newHandicap,
            trialInterActionCount: newTrialInteractionCount
        }
        //do we need to lock them out
        if(newTrialInteractionCount >= constants.MAX_ROUNDS_AS_STANDARD && cacheUserMeta.role !== 'premium'){
            //updatedMeta.locked = true;
        }
        
        if(!getState().round.coursecard) {
            getState().round.coursecard = {}
        }

        if(getState().round.system === 'whs') {
            getState().round.gender = 'M';
        }

        if(getState().round.roundid){

            //existing so update old id
            db.collection("rounds").doc(getState().round.roundid)
                .update(getState().round)
                .then((doc) => {
                    
                    postRoundUpdate(updatedMeta, userId, () => {
                        //cacheUserMeta.trialInterActionCount = newTrialInteractionCount;
                        if(newHandicap) {
                            cacheUserMeta.handicap = newHandicap;
                        }
                        dispatch(authUpdateMeta(cacheUserMeta));
                        dispatch(cleanRound(getState().round.roundid,"updated"));
                    });
                })
                .catch((error) => {
                    console.log("Not updated");
                });
        } else {
            //add new
            const cacheRound = {...getState().round};
            cacheRound.coursecard = null; //  clear course card as bug with 9 hole
            if(cacheRound.system === 'whs') {
                cacheRound.newHandicap = 0;
                cacheRound.handicap = 0;
                cacheRound.roundsss = 0;
                cacheRound.gender = 'na';
            } else {
                delete cacheRound.recordedHandicapIndex;
                delete cacheRound.recordedPlayingHandicap;
                delete cacheRound.roundIndex;
                delete cacheRound.sloperating;
                delete cacheRound.courserating;
                delete cacheRound.system;
                delete cacheRound.adjustedGross;
            }
            

            db.collection("rounds").add(cacheRound)
                .then((doc) => {
                    if (doc.id) {
                        postRoundUpdate(updatedMeta, userId, () => {
                            //cacheUserMeta.trialInterActionCount = newTrialInteractionCount;
                            if(newHandicap) {
                                cacheUserMeta.handicap = newHandicap;
                            }
                            dispatch(authUpdateMeta(cacheUserMeta));
                            dispatch(cleanRound(doc.id,"added"));
                        });
                    }
                })
                .catch((error) => {
                    console.log("Not added");
                });
        }
    }
}

const postRoundUpdate = (updatedMeta, userId, callback) => {
    const db = firebase.firestore();
    const settings = {};
    db.settings(settings);

    db.collection("user_meta").doc(userId)
        .update(updatedMeta)
        .then((doc) => {
            
            //handicap updated
            callback();
            
        })
        .catch((error) => {
            console.log("Not updated handicap");
        });
      

    
}

export const addRoundHandicap = (state) => {
    const finishedStats = getFinishedStats(state);
    const handicapRelatedProps = {
        coursePar:finishedStats.coursePar,
        coursecard:null
    }
    if(finishedStats.whs) {
        handicapRelatedProps.newHandicap = 0;
        handicapRelatedProps.recordedHandicapIndex = finishedStats.recordedHandicapIndex;
        handicapRelatedProps.recordedPlayingHandicap = finishedStats.recordedPlayingHandicap;
        handicapRelatedProps.roundIndex = finishedStats.roundIndex;
        handicapRelatedProps.adjustedGross = finishedStats.adjustedGross;
    } else {
        handicapRelatedProps.newHandicap = parseFloat(finishedStats.newHandicap);
    }
    return {
        type: actionTypes.ROUND_FINISH,
        ...handicapRelatedProps
    };
}

export const editRound = (roundData) => {
    //console.log("roundData: [action]",roundData);
    return {
        type: actionTypes.ROUND_EDIT,
        userid:roundData.userid,
        coursename:roundData.coursename,
        courseid:roundData.courseid,
        coursecard:roundData.coursecard,
        rounddate:roundData.rounddate.toDate(),
        roundsss:roundData.roundsss,
        roundtype:roundData.roundtype,
        handicap:roundData.handicap,
        gender:roundData.gender,
        viewname:'addroundscores',
        points:roundData.points,
        topar:roundData.topar,
        scores:roundData.scores,
        roundid:roundData.roundid,
        system: roundData.system,
        sloperating: roundData.sloperating,
        courserating: roundData.courserating,
        updated:true,
        loading:false
    };
}

