import * as actionTypes from  '../actions/actionsTypes';
import { updateObject } from '../utility';

const intialState = {
    token:null,
    userId:null,
    error:null,
    loading:false,
    userMeta:null,
    userMetaFetched:false,
    triedAuthentication:false
}

const authStart = (state,action) => {
    return updateObject(state, {error:null, loading:true});
}
const authSuccess = (state,action) => {
    return updateObject(state, {
        token: action.idToken,
        userId: action.userId,
        uid: action.userId,
        error:null, 
        loading:false,
        triedAuthentication:true
    });
}
const authFail = (state,action) => {
    return updateObject(state, {error:action.error, loading:false,triedAuthentication:true});
}
const authUserMeta = (state,action) => {
    return updateObject(state, {
        userMeta: action.userMeta,
        userMetaFetched: action.userMetaFetched
    });
}

const authUpdateMeta = (state,action) => {
    return updateObject(state, {
        userMeta: action.userMeta
    });
}

const authLogout = (state,action) => {
    return updateObject(state, {token:null, userId:null,triedAuthentication:true, userMeta: null, userMetaFetched: false});
}

const reducer = (state = intialState,action) => {   
    switch ( action.type ) {
        case actionTypes.AUTH_START: return authStart(state,action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state,action);
        case actionTypes.AUTH_FAIL: return authFail(state,action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state,action);
        case actionTypes.AUTH_USER_META: return authUserMeta(state,action);
        case actionTypes.AUTH_UPDATE_META: return authUpdateMeta(state,action);
        default:
            return state;
    } 
}

export default reducer;