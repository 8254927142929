export const INC_SEARCHES = 'INC_SEARCHES';
export const INC_ATTEMPTS = 'INC_ATTEMPTS';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_USER_META = 'AUTH_USER_META';
export const AUTH_UPDATE_META = 'AUTH_UPDATE_META';

export const ROUND_START = 'ROUND_START';
export const ROUND_RECOVER = 'ROUND_RECOVER';
export const ROUND_UPDATE = 'ROUND_UPDATE';
export const ROUND_VIEW_UPDATE = 'ROUND_VIEW_UPDATE';
export const ROUND_HOLE_UPDATE = 'ROUND_HOLE_UPDATE';
export const ROUND_HOLE_ADD = 'ROUND_HOLE_ADD';
export const ROUND_CALCULATE = 'ROUND_CALCULATE';
export const ROUND_SCORE_RESET = 'ROUND_SCORE_RESET';
export const ROUND_FINISH = 'ROUND_FINISH';
export const ROUND_COMPLETE_RESET = 'ROUND_COMPLETE_RESET';
export const ROUND_EDIT = 'ROUND_EDIT';
