import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pux from '../../hoc/Pux';
import './SearchField.scss';
import SecurityIcon from '@material-ui/icons/Security';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FlagIcon from '@material-ui/icons/GolfCourse';
import firebase from '../../Firebase.js';

class SearchUsersField extends Component {

    state = {
        users: [],
        showResults: false,
        fetchingData: false,
        fetchTimout: null,
        hoveredResultIndex: -1,
        searchesmade : 0
    }  

    getUsers = (term) => {
        this.setState({fetchingData: true});
        const db = firebase.firestore();
        db.settings({
            
        });
        const ref = db.collection("user_meta");
        let results = [];
        ref.where("displayname", ">=", term)
            .limit(3)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((user) => {
                    const userData = {
                        ...user.data(),
                        id:user.id
                    }
                    results.push(userData);
                });
                if(results.length){
                    this.setState({
                        users:results,
                        fetchingData: false,
                        showResults:true
                    });
                } else {
                    this.setState({
                        users:[],
                        showResults:true,
                        fetchingData: false,
                        hoveredResultIndex: -1
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    users:[],
                    showResults:false,
                    fetchingData: false,
                    hoveredResultIndex: -1
                });
            });
            
    }

    searchTypeHandler = (event) => {
        const term = event.target.value;
        const keyCode = event.keyCode;

        if(keyCode === 38){
            this.selectNextResultUp();
            return;
        } else if(keyCode === 40) {
            this.selectNextResultDown();
            return;
        } else if(keyCode === 13) {
            if(this.state.hoveredResultIndex >= 0 && this.state.users.length > 0){
                if(this.props.returnHitHandler){
                    const resultData = this.state.users[this.state.hoveredResultIndex];
                    this.props.returnHitHandler(resultData.title,resultData.id);
                }
            }
            return;
        }

        if(term.length > 2) {
            //set timeout so doesn't call multiples
            if(this.state.fetchTimout){
                clearTimeout(this.state.fetchTimout);
            }
            this.setState({
                fetchTimout:setTimeout(() => {
                    this.getUsers(term.toLowerCase());
                },500)
            });
        } else {
            this.closeResults();
        }
    }

    selectNextResultUp = () => {
        const currentState = {...this.state};
        const newIndex = currentState.hoveredResultIndex-1;
        if(newIndex < 0){
            return;
        }
        currentState.hoveredResultIndex = newIndex;
        this.setState(currentState);
    }

    selectNextResultDown = () => {
        const currentState = {...this.state};
        const newIndex = currentState.hoveredResultIndex+1;
        if(newIndex >= currentState.users.length){
            return;
        }
        currentState.hoveredResultIndex = newIndex;
        this.setState(currentState);
    }

    closeResults = () => this.setState({
        users:[],
        showResults:false,
        hoveredResultIndex: -1
    });

    resultHovered = (i) => {
        this.setState({hoveredResultIndex:i});
    }

    formSubmission = (event) => {
        //stop sumission
        event.preventDefault();
        event.stopPropagation();
        return false;
    }

    handleClickAway = () => {
        this.setState({
          showResults: false
        });
    };


  render() {
    
    

    let usersHtml = null;
    if(!this.state.users.length){
        usersHtml = (
            <ListItem 
                className="gh-searchfield__results-listitem gh-searchfield__results-listitem--noresults" 
                >
                <ListItemText
                    className="gh-searchfield__results-listlink"
                    primary="No Users Found"
                />
            </ListItem>
        );
    } else {
        usersHtml = this.state.users.map((user,i) => {
            
            let usable = true;

           
            
            return (
                (!usable) ? null : 
                <ListItem 
                    onMouseEnter={li => {
                        this.resultHovered(i)
                    }} 
                    onMouseLeave={li => {
                        this.resultHovered(-1)
                    }} 
                    className="gh-searchfield__results-listitem" 
                    key={i}
                    onClick={() => {
                        this.props.onUserSelect(user.id);
                        this.setState({
                            users:[],
                            showResults:false,
                            fetchingData: false,
                            hoveredResultIndex: -1
                        });
                    }}
                    >
                    <ListItemText
                        className={(i === this.state.hoveredResultIndex) ? 'gh-searchfield__results-listlink gh-searchfield__results-listlink--active' : 'gh-searchfield__results-listlink'}
                        primary={user.displayname}
                        secondary={user.handicap ? 
                                    <Pux>
                                        <SecurityIcon className="gh-searchfield__results-icon" />{user.handicap}
                                        {user.homecourse ? 
                                            <Pux>
                                                <FlagIcon className="gh-searchfield__results-icon gh-searchfield__results-icon--second" />{user.homecourse}
                                            </Pux>
                                        : null}
                                    </Pux>
                            : null}
                    />
                </ListItem>
            )
        });
    }
    
    const courseslist = (this.state.showResults) ? 
        <ClickAwayListener onClickAway={this.handleClickAway}><List dense={true} className="gh-searchfield__results">{usersHtml}</List></ClickAwayListener> : null;
    

    return (
      <Pux>
        <FormControl className="gh-searchfield" margin="normal" fullWidth error={!this.props.isValid}>
            <InputLabel htmlFor={this.props.fieldName}>{this.props.fieldLabel}</InputLabel>
            <Input 
                {...this.props.inputProps}
                onKeyUp={this.searchTypeHandler}
                
                    />

                {courseslist}
            
            {!this.props.isValid ?
                <FormHelperText id={this.props.fieldName + '-error'}>{this.props.error}</FormHelperText>
            : null }
        </FormControl>
      </Pux>
    );
  }
}

SearchUsersField.propTypes = {
  toggleDrawer: PropTypes.func
};

export default SearchUsersField;