import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import * as actionsCreators from '../../store/actions/index';

const paperStyle = {
    background: 'radial-gradient(farthest-corner at 90% 0,#08A6BE 0%, #0E3676 50%,  #08193C 100%)',
    borderRadius: 3,
    border:'1px solid #ffffff',
    margin: '24px'
};


class ResetRoundOverlay extends Component {

    state = {
        forceClose:null
    }

    confirmReset = () => {
        this.props.preRoundScoreReset(this.props.scoresOnly);
        if(this.props.callback){
            this.props.callback();
        }
        this.setState({forceClose:true});
    }

    render() {

        return (
            <Dialog
                open={!this.state.forceClose}
                onClose={this.props.closeResetRoundOverlay}
                className="gh-overlays"
                aria-labelledby="resetround-dialog-title"
                aria-describedby="resetround-dialog-description"
                PaperProps={{className:'gh-overlays__paper',style:paperStyle}}
                >
                <DialogTitle className="gh-overlays__title" id="resetround-dialog-title">
                    {this.props.title}
                    <CloseIcon onClick={this.props.closeResetRoundOverlay} className="gh-overlays__title-close" />
                </DialogTitle>
                <DialogContent className="gh-overlays__content">
                    <DialogContentText id="resetround-dialog-description">
                        {this.props.description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.closeResetRoundOverlay} color="primary">
                    Cancel
                    </Button>
                    <Button onClick={this.confirmReset} color="primary" autoFocus>
                    Remove
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
};
const mapStateToProps = state => {
    return {
        userMeta: state.auth.userMeta,
        round: state.round
    }
}

const mapDispatchToProps = dispatch =>  {
    return {
        preRoundScoreReset: scoresOnly => dispatch(actionsCreators.preRoundScoreReset(scoresOnly))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetRoundOverlay);