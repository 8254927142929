import React, {Component} from 'react';
import './ChooseScoreCardOverlay.scss';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {validateForm } from  '../../utils/validation';
import Pux from  '../../hoc/Pux';
import ScoreCard from  '../../components/ScoreCard/ScoreCard';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const paperStyle = {
    background: 'radial-gradient(farthest-corner at 90% 0,#08A6BE 0%, #0E3676 50%,  #08193C 100%)',
    borderRadius: 3,
    border:'1px solid #ffffff',
    margin: '24px'
};

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    fabProgress: {
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

class ChooseScoreCardOverlay extends Component {

    state = {
        requesting:false,
        showCard:[],
        form: {
            isValid:true,
            fields: {
                scorecard: {
                    value: '0',//select first card
                    valid: true,
                    errorType: null,
                    rules: {
                        notEmpty : {
                            message: 'Required field.'
                        }
                    }
                }
            }
        }
    }

    submitHandler = (event) => {
        event.preventDefault();
        const validateFormState = validateForm(this.state.form);
        this.setState({form:validateFormState});
        if(validateFormState.isValid){
            //add card to store
            this.props.selectCard(this.props.scorecards[this.state.form.fields.scorecard.value]);
        }
    }

    inputChangedHandler = (event) => {
        const inputValue = event.target.value;
        const updateState = {...this.state};
        updateState.form.fields.scorecard.value = inputValue;
        updateState.showCard = [];
        updateState.selectedCardIndex = inputValue;
        this.setState(updateState);
    }

    showCardHandler = (value,card) => {
        const updateState = {...this.state}
        let showCardReset = [];
        showCardReset[value] = card;
        updateState.showCard = showCardReset;
        this.setState(updateState);
    }
    

    render() {

        const { classes } = this.props;
        const buttonClassname = classNames({
            [classes.buttonSuccess]: true,
        });
        const { whsMode = false } = this.props;
        
        let cards = this.props.scorecards.filter(card => whsMode || (!whsMode && !card.courseRating)).map((card,i) => {
            let hasYards = false;
            if(card.yards) {
                hasYards = true;
            }
            
            return (
                <Pux key={i}>
                        <FormControlLabel 
                            value={i.toString()} 
                            carddata={card}
                            className="gh-choosecard__label"
                            control={<Radio onChange={this.inputChangedHandler} checked={this.state.form.fields.scorecard.value === i.toString()} />} 
                            label={<React.Fragment>Par {card.par} {whsMode && card.courseRating ? " | CR: " + card.courseRating : null} {whsMode && card.slopeRating ? " | SR: " + card.slopeRating : null}  {!card.slopeRating && card.sss !== 'not set' ? " | SSS: " + card.sss : null} <Button size="small" className="gh-choosecard__link" onClick={() => this.showCardHandler(i,card)}>View</Button></React.Fragment>} />
                            {(this.state.showCard[i]) ? 
                                <ScoreCard type="preview" carddata={card} hasYards={hasYards} />
                            : null}
                </Pux>
            );
        });


        return (
            <Dialog
                open={true}
                onClose={this.props.closeOverlay}
                aria-labelledby="form-dialog-title"
                className="gh-overlays"
                PaperProps={{className:'gh-overlays__paper',style:paperStyle}}
                >
                <DialogTitle className="gh-overlays__title" id="form-dialog-title">Choose Scorecard</DialogTitle>
                <DialogContent className="gh-overlays__content">
                    <form className="form form--overlayed" onSubmit={this.submitHandler}>
                        <FormControl margin="normal" fullWidth>
                            <RadioGroup
                                className="form__group"
                                aria-label="Scorecard"
                                name="scorecard"
                            >
                                {cards}
                            
                            </RadioGroup>
                        </FormControl>
                        
                        
                        <FormControl margin="normal" fullWidth>
                            <div className={classes.wrapper}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    disabled={this.state.requesting}
                                    className={buttonClassname}
                                >
                                    Use Selected Card
                                </Button>
                                {this.state.requesting ? <CircularProgress size={24} className={classes.buttonProgress} /> : null}
                            </div>
                        </FormControl>
                        <div className="form__alts">
                            <Typography variant="body1" className="form__alts-para" align="right">
                                <span className="form__alts-para-link" onClick={this.props.closeOverlay}>I'll add my own</span>
                            </Typography>
                        </div>
                    </form>
                </DialogContent>
                </Dialog>
        );
    }
};

export default withStyles(styles)(ChooseScoreCardOverlay);