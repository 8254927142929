import React from 'react';
import './Heading.scss';
import Pux from '../../hoc/Pux';

const heading = (props) => {
    let heading = null;
    let classes = ['gh-heading','gh-heading--'+props.type];
    if(props.variant) {
        classes.push('gh-heading--'+props.variant);
    }
    
    switch ( props.type ) {
        case( 'h1' ):
            heading = <h1 className={classes.join(' ')} {...props}>{props.children}</h1>
            break;
        case ( 'h2' ):
            heading = <h2 className={classes.join(' ')} {...props}>{props.children}</h2>
            break;
        case ( 'h3' ):
            heading = <h3 className={classes.join(' ')} {...props}>{props.children}</h3>
            break;
        case ( 'h4' ):
            heading = <h4 className={classes.join(' ')} {...props}>{props.children}</h4>
            break;
        case ( 'h5' ):
            heading = <h5 className={classes.join(' ')} {...props}>{props.children}</h5>
            break;
        case ( 'h6' ):
            heading = <h6 className={classes.join(' ')} {...props}>{props.children}</h6>
            break;
        default:
            heading = <h1 className={classes.join(' ')} {...props}>{props.children}</h1>
    }

    return (
        <Pux>
            {heading}
        </Pux>
    );
}

export default heading;