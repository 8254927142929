import React, {Component} from 'react';
import './HoleSetupOverlay.scss';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import HoleEntryField from '../../components/HoleEntryField/HoleEntryField';
import {validateForm } from  '../../utils/validation';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import * as actionsCreators from '../../store/actions/index';

const paperStyle = {
    background: 'radial-gradient(farthest-corner at 90% 0,#08A6BE 0%, #0E3676 50%,  #08193C 100%)',
    borderRadius: 3,
    border:'1px solid #ffffff',
    margin: '24px',
    width: '100%'
};

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        position: 'relative',
        margin: '8px 0'
    },
    fabProgress: {
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

class HoleSetupOverlay extends Component {

    state = {
        requesting:false,
        overlayOpen: true,
        form: {
            isValid:true,
            fields: {
                par: {
                    value: null,
                    valid: true,
                    errorType: null,
                    rules: {
                        radioSelected : {
                            message: 'Please select a hole par.'
                        }
                    }
                },
                strokeindex: {
                    value: null,
                    valid: true,
                    errorType: null,
                    exists:null,
                    rules: {
                        radioSelected : {
                            message: 'Please select a hole stroke index.'
                        }
                    }
                }
            }
        }
    }

    componentWillMount() {
        
        const cachedState = {...this.state};

        //need to update data not from elements
        if(this.props.round.scores && this.props.holeNumber){
            const cachedScores = [...this.props.round.scores];
            const holeRawData = cachedScores[this.props.holeNumber-1];
            let holePar, holeStroke;
            for(let h in holeRawData){
                let holeValuesArray = holeRawData[h];
                if(holeValuesArray.length) {
                    holePar = holeValuesArray[0];
                    holeStroke = holeValuesArray[2];
                }
            }
            cachedState.form.fields.par.value = holePar;
            cachedState.form.fields.strokeindex.value = holeStroke;
            if(holeStroke){
                cachedState.form.fields.strokeindex.exists = this.checkStrokeValueExists(holeStroke,this.props.holeNumber);
            }
            
        }

        //update date
        this.setState(cachedState);

        
    }

    submitHandler = (event) => {
        event.preventDefault();
        const validateFormState = validateForm(this.state.form);
        this.setState({form:validateFormState});
        if(validateFormState.isValid){
            this.setState({requesting:true},() => {
                this.props.addHoleData(this.props.holeNumber,this.state.form.fields.par.value,this.state.form.fields.strokeindex.value);
                this.props.roundSave();
                this.setState({overlayOpen:false},() => {
                    this.props.closeOverlay();
                });
            });
        }
    }

    parSelected = (holeNumber, valueSelected) => {
        const updateState = {...this.state};
        updateState.form.fields.par.value = valueSelected;
        this.setState(updateState);
    }

    checkStrokeValueExists = (val,holeNumber) => {
        let strokeInUse = false;
        const roundData = {...this.props.round}
            
        //need to check round scores
        roundData.scores.map((holeData,i) => {
            const scoreHoleNumber = i+1;
            const stroke = this.getStrokeFromRound(holeData);
            if(stroke === val && scoreHoleNumber !== parseInt(holeNumber)){
                strokeInUse = true;
            }
            return holeData;
        });
        
        //need to check template scorecard
        if(!strokeInUse && roundData.coursecard){
            //need to check round scores
            for(let h in roundData.coursecard.card){
                const holeData = roundData.coursecard.card[h];
                const scoreHoleNumber = h;
                const stroke = holeData[2];
                if(stroke === val && scoreHoleNumber !== holeNumber){
                    strokeInUse = true;
                }
            }
        }
        
        return strokeInUse;
    }

    getStrokeFromRound = (holeData) => {
        let holeValuesArray = null;
        for(let d in holeData){
            if(holeData[d].length){
                holeValuesArray = holeData[d];
            }
        } 
        return (holeValuesArray) ? holeValuesArray[2] : null;
    }

    strokeSelected = (holeNumber, valueSelected) => {
        const updateState = {...this.state};
        const exists = this.checkStrokeValueExists(valueSelected,holeNumber);
        updateState.form.fields.strokeindex.value = valueSelected;
        updateState.form.fields.strokeindex.exists = exists;
        this.setState(updateState);
    }
    

    render() {

        const { classes } = this.props;
        const buttonClassname = classNames({
            [classes.buttonSuccess]: true,
        });

        return (
            <Dialog
                open={this.state.overlayOpen}
                onClose={this.props.closeOverlay}
                aria-labelledby="form-dialog-title"
                className="gh-overlays"
                PaperProps={{className:'gh-overlays__paper',style:paperStyle}}
                >
                <DialogTitle className="gh-overlays__title gh-overlays__title--bordered" id="form-dialog-title">
                    Setup Hole {this.props.holeNumber}
                    <CloseIcon onClick={this.props.closeOverlay} className="gh-overlays__title-close" />
                </DialogTitle>
                <DialogContent className="gh-overlays__content">
                    <form className="form form--overlayed" onSubmit={this.submitHandler}>
                       
                       <HoleEntryField
                            groupLabel="Par"
                            holeNumber={this.props.holeNumber}
                            holeData={this.props.holeData}
                            entries={4}
                            startValue={3}
                            selectedIndex={0}
                            error={!this.state.form.fields.par.valid ? this.state.form.fields.par.rules[this.state.form.fields.par.errorType].message : null}
                            valid={this.state.form.fields.par.valid}
                            selectedValue={this.state.form.fields.par.value}
                            valueSelected={this.parSelected}
                            />

                        <HoleEntryField
                            groupLabel="Stroke Index"
                            holeNumber={this.props.holeNumber}
                            holeData={this.props.holeData}
                            entries={18}
                            warning={this.state.form.fields.strokeindex.exists ? "Stroke index is used on another hole." : null}
                            error={!this.state.form.fields.strokeindex.valid ? this.state.form.fields.strokeindex.rules[this.state.form.fields.strokeindex.errorType].message : null}
                            valid={this.state.form.fields.strokeindex.valid}
                            selectedIndex={2}
                            selectedValue={this.state.form.fields.strokeindex.value}
                            valueSelected={this.strokeSelected}
                            />
                        
                        
                        <FormControl margin="normal" fullWidth>
                            <Typography variant="body1" className="form__action-help" align="left">
                                Saving this data will only affect this hole.
                            </Typography>
                            <div className={classes.wrapper}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    disabled={this.state.requesting}
                                    className={buttonClassname}
                                >
                                    Save Setup
                                </Button>
                                {this.state.requesting ? <CircularProgress size={24} className={classes.buttonProgress} /> : null}
                            </div>
                        </FormControl>
                        
                    </form>
                </DialogContent>
                </Dialog>
        );
    }
};
const mapStateToProps = state => {
    return {
        userMeta: state.auth.userMeta,
        round: state.round
    }
}

const mapDispatchToProps = dispatch =>  {
    return {
        roundSave: () => dispatch(actionsCreators.roundSave()),
        addHoleData: (holeNumber,par,stroke) => dispatch(actionsCreators.addHoleData(holeNumber,par,stroke)),
        updateHoleData: (holeNumber,score,par,stroke) => dispatch(actionsCreators.updateHoleData(holeNumber,score,par,stroke))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HoleSetupOverlay));