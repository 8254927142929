import React, { Component } from 'react';
//import axios from 'axios';
import Pux from '../../hoc/Pux';
import './RoundDetails.scss';
import PropTypes from 'prop-types';
import firebase from '../../Firebase';
import * as actionsCreators from '../../store/actions';
import ScreenLoading from '../../components/ScreenLoading/ScreenLoading';
import DateDisplay from '../../components/DateDisplay/DateDisplay';
import Heading from '../../components/Heading/Heading';
import ScoreCard from  '../../components/ScoreCard/ScoreCard';
import ScoreCardAlt from  '../../components/ScoreCard/ScoreCardAlt';
import Header from '../../components/Header/Header';
import AppBar from '../AppBar/AppBar';
//import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import BigSmallValue from '../../components/BigSmallValue/BigSmallValue';
import PayPalImg from '../../assets/images/paypal.png';
import Button from '@material-ui/core/Button';
import SecurityIcon from '@material-ui/icons/Security';
import PublicIcon from '@material-ui/icons/Public';
import TimeIcon from '@material-ui/icons/AccessTime';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import SuccessIcon from '@material-ui/icons/CheckCircleOutlined';
import IconButton from '@material-ui/core/IconButton';
import ConfirmOverlay from '../../components/ConfirmOverlay/ConfirmOverlay';
import ProfilePic from '../../components/ProfilePic/ProfilePic';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import ChatIcon from '@material-ui/icons/Chat';
import AddIcon from '@material-ui/icons/AddBox';
import FormHelperText from '@material-ui/core/FormHelperText';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import {Helmet} from 'react-helmet';



class RoundDetails extends Component {

    state = {
        roundData : null,
        roundId : null,
        fromPage: '',
        roundDetailsFetched:false,
        roundMeta: null,
        openSnackBar: true,
        confirmEditRound: false,
        redirectToEdit: false,
        commentvalue: '',
        commentSending:false,
        commenterror:false,
        commenterrormessage: ''
    }
    

    componentDidMount () {

        
        const roundId = this.props.match.params.id;
        if(!roundId){
            return;
        }
        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);

        const docRef = db.collection("rounds").doc(roundId);

        docRef.get().then((doc) => {
            if(doc.exists){
                const fetchData = doc.data();
                this.setState({roundData:fetchData});
                if(fetchData.userid){
                    const userDocRef = db.collection("user_meta").doc(fetchData.userid);
                    userDocRef.get().then((userdoc) => {
                        if(userdoc.exists){
                            this.setState({roundMeta:userdoc.data(),roundDetailsFetched:true});

                            //now get user prifle pic
                            const storage = firebase.storage();
                            const storageRef = storage.ref();
                            storageRef.child('userpics/' + fetchData.userid + '-pp.jpg').getDownloadURL().then((url) => {
                                const cachedState = {...this.state}
                                cachedState.roundMeta.profilepic = url;
                                this.setState(cachedState);
                            }).catch((error) => {
                                //error method
                            });

                        } else {
                            this.setState({roundDetailsFetched:true});
                        }
                    }).catch((error) => {
                        this.setState({roundDetailsFetched:true});
                    });
                }
            } else {
                this.setState({roundData:null,roundDetailsFetched:true});
            }
        }).catch((error) => {
            this.setState({roundData:null,roundDetailsFetched:true});
        });
        
        
    }

    closeSnackBar = () => {
        this.setState({openSnackBar:false});
    }

    editRoundHandler = (round) => {
        this.setState({confirmEditRound:round});
    }

    closeEditOverlay = () => {
        this.setState({confirmEditRound:null});
    }

    editRoundConfirmHandler = (round) => {
        this.props.editRound(round);
        this.setState({redirectToEdit:true});
    }

    addComment = () => {
        
        if(this.validateComment(this.state.commentvalue)) {
            this.setState({ 
                commentSending: true
            }, () => {
                const cachedState = {...this.state}
                //console.log("previous commnets: ",cachedState.roundData.comments);
                if(!cachedState.roundData.comments){
                    cachedState.roundData.comments = [];
                }
                let avatarInitials = 'U';
                if(this.props.userMeta.displayname) {
                    avatarInitials = this.props.userMeta.displayname.substring(0,1);
                    const initailArray = this.props.userMeta.displayname.split(' ');
                    if(initailArray.length > 1){
                        avatarInitials = '';
                        for(let a = 0; a < 2; a++){//max of 2 initials
                            avatarInitials += initailArray[a].substring(0,1);
                        }
                    }
                } 

                cachedState.roundData.comments.push({
                    date: new Date(),
                    uid: this.props.userid,
                    initials:avatarInitials,
                    comment: this.state.commentvalue
                });
                
                cachedState.commentSending = false;
                cachedState.commentvalue = '';
                //update data
                const db = firebase.firestore();
                const settings = {};
                db.settings(settings);
                db.collection("rounds").doc(this.props.match.params.id).update({
                    comments:cachedState.roundData.comments
                }).then(() => {
                        //now get latest
                        db.collection("rounds").doc(this.props.match.params.id).get().then((roundDoc) => {
                            console.log("roundDoc.data() ",roundDoc.data());
                            cachedState.roundData = roundDoc.data();
                            this.setState(cachedState);
                        });
                    })
                    .catch((error) => {
                        this.setState({ 
                            commentSending: false
                        });
                    });
            });
            
        }
    }

    validateComment = (comment) => {
        if(comment.length > 140) {
            this.setState({
                commenterror:true,
                commenterrormessage: 'Shorten it up chatty.'
            });
            return false;
        } else if(!comment.length) {
            this.setState({
                commenterror:true,
                commenterrormessage: 'Add something then.'
            });
            return false;
        } else if(/<[a-z/][\s\S]*>/i.test(comment)){
            this.setState({
                commenterror:true,
                commenterrormessage: 'Please remove any special characters.'
            });
            return false;
        }
        return true;
    }

    backClick = () => {
        this.props.history.goBack();
    }

    render() {

        

        let redirect = null;
        if(this.state.redirectToEdit){
            redirect = <Redirect to={{
                pathname: "/addroundscores"
              }}  />;
        };

        const whsMode = this.state.roundData && this.state.roundData.system === 'whs';
        
        let diff, prefix;
        let totalPar = 0;
        let totalScore = 0;
        if(this.state.roundDetailsFetched && this.state.roundMeta) {
            const cacheState = {...this.state}
            diff = parseFloat(cacheState.roundData.newHandicap - cacheState.roundData.handicap).toFixed(1);
            prefix = (diff > 0) ? '+' : '';

            //get totals
            for(let a = 0; a < cacheState.roundData.scores.length; a++){//max of 2 initials
                const [holeData] = Object.values(cacheState.roundData.scores[a]);
                if(holeData.length > 1){
                    const [holePar, holeScore] = holeData;
                    totalPar += holePar;
                    totalScore += holeScore;
                }
            }
            
        }
        let action;
        let actionMessage = "";
        if(this.props.match.params.type && this.state.roundData && this.props.userMeta){
            action = this.props.match.params.type;
            if(action === 'updated'){
                actionMessage = "We have updated your round with your new details, we hope they're genuine!";
            } else if(action === 'added'){
                if(this.state.roundData.system === 'whs') {
                    actionMessage = "Great news! We've added your round and this page shows your details. your new handicap index is " + this.props.userMeta.whindex;
                } else {
                    actionMessage = "Great news! We've added your round and this page shows your details. your new handicap is " + this.state.roundData.newHandicap;
                }
                
            }
        }

        if(this.state.roundDetailsFetched && this.state.roundData.comments && this.state.roundData.comments.length) {
            this.state.roundData.comments.sort(function(a,b){
                return b.date.toDate() - a.date.toDate();
            });
        }

        
        const gridCols = whsMode ? 4 : 3;

        return (
            <Pux>
                <Header backClick={this.backClick} />
                {redirect}
                {this.props.userMeta && this.state.roundDetailsFetched ? 
                    <Pux>
                        {this.state.roundData && this.state.roundMeta ? 
                            <div className="gh-content">

                                <Helmet>
                                    <meta charSet="utf-8" />
                                    <title>{this.state.roundMeta.displayname} | {this.state.roundData.coursename} | Digital Scorecard | Golf Handicap</title>
                                    <meta name="description" content="Calculate your handicap here. Track your handicap. UK Golf Course Directory, read and review your golf club. Follow your friends Handicap." />
                                </Helmet>
                            
                                <div className="gh-detailsMeta">
                                    <ProfilePic 
                                        onClick={() => {
                                            this.props.history.push('/profile/' + this.state.roundData.userid);
                                        }}
                                        displayname={this.state.roundMeta.displayname}
                                        profilepic={this.state.roundMeta.profilepic}
                                        className="gh-detailsMeta__avatar" />
                                    <Typography className="gh-detailsMeta__name"
                                        onClick={() => {
                                            this.props.history.push('/profile/' + this.state.roundData.userid);
                                        }}>
                                        {this.state.roundMeta.displayname} 
                                    </Typography>
                                    <Typography 
                                        onClick={this.state.roundData.courseid ? () => {
                                            this.props.history.push('/course/' + this.state.roundData.courseid);
                                        } : null}
                                        className="gh-detailsMeta__metaline">{this.state.roundData.coursename}</Typography>
                                    
                                    <BigSmallValue
                                        value={this.state.roundData.points}
                                        label="POINTS"
                                        className="gh-detailsMeta__bigsmall"
                                        />
                                </div>

                                <div className="gh-chips">
                                    {whsMode ? (
                                        <>
                                            <Chip
                                                className="gh-chips__chip"
                                                icon={<PublicIcon />}
                                                label={`Playing Handicap: ${this.state.roundData.recordedPlayingHandicap} (${this.state.roundData.recordedHandicapIndex})`}
                                                color="primary"
                                            />
                                            <Chip
                                                className="gh-chips__chip"
                                                label={`Slope Rating: ${this.state.roundData.sloperating}`}
                                                color="primary"
                                            />
                                            <Chip
                                                className="gh-chips__chip"
                                                label={`Course Rating: ${this.state.roundData.courserating}`}
                                                color="primary"
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <Chip
                                                className="gh-chips__chip"
                                                icon={<SecurityIcon />}
                                                label={"Handicap: " + this.state.roundData.newHandicap + " (" + prefix + diff + ")"}
                                                color="primary"
                                            />
                                        </>
                                    )}
                                    
                                    <Chip
                                        className="gh-chips__chip"
                                            icon={<TimeIcon />}
                                            label={<DateDisplay date={this.state.roundData.rounddate} />}
                                        color="primary"
                                    />
                                </div>
                
                                <Heading type="h3" variant="left">Scorecard</Heading>

                                
                                {this.props.userMeta.front9back9layout ? <ScoreCardAlt type="scores" carddata={this.state.roundData.scores} /> : <ScoreCard type="scores" carddata={this.state.roundData.scores} />}

                                <Grid className="gh-roundStats" container spacing={0}>
                                    <Grid item xs={gridCols}>
                                        <BigSmallValue
                                            value={totalPar}
                                            label="PAR"
                                            />
                                    </Grid>
                                    {!whsMode && (
                                        <Grid item xs={gridCols}>
                                            <BigSmallValue
                                                value={this.state.roundData.roundsss}
                                                label="SSS"
                                                />
                                        </Grid>
                                    )}
                                    <Grid item xs={gridCols}>
                                        <BigSmallValue
                                            value={totalScore}
                                            label="SHOTS"
                                            />
                                    </Grid>
                                    <Grid item xs={gridCols}>
                                        <BigSmallValue
                                            value={(this.state.roundData.topar > 0) ? '+' + this.state.roundData.topar : this.state.roundData.topar}
                                            label="TO PAR"
                                            />
                                    </Grid>
                                </Grid>

                                <div className="gh-comments">
                                    <Heading type="h3" variant="left">Comments</Heading>
                                    
                                    <form className="form" onSubmit={(event) => {
                                        event.preventDefault();
                                    }}>
                                        <FormControl margin="normal" fullwidth="true" className="form__fullwidth" error={this.state.commenterror}>
                                            <InputLabel htmlFor="addcomment">Something to say?</InputLabel>
                                            <Input
                                                disabled={this.state.commentSending}
                                                id="addcomment"
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <ChatIcon />
                                                    </InputAdornment>
                                                }
                                                onChange={(event) => {
                                                    this.setState({commentvalue:event.target.value});
                                                }}
                                                value={this.state.commentvalue}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <AddIcon onClick={this.addComment} color="primary" />
                                                    </InputAdornment>
                                                }
                                                />
                                            {this.state.commenterror ?
                                                <FormHelperText id="displayname-error">{this.state.commenterrormessage}</FormHelperText>
                                            : null }
                                        </FormControl>
                                        
                                    </form>
                                    {this.state.roundData.comments && this.state.roundData.comments.length ? 
                                        <React.Fragment>
                                            {this.state.roundData.comments.map((comment,i) => {
                                                return (
                                                    <Card key={i} className="gh-comments__comment">
                                                        <CardHeader
                                                        avatar={
                                                            <Link to={'/profile/' + comment.uid}>
                                                                <Avatar aria-label="Avatar" className="gh-comments__comment-avatar">
                                                                    {comment.initials}
                                                                </Avatar>
                                                            </Link>
                                                        }
                                                        title={comment.comment}
                                                        subheader={
                                                            <span className="gh-comments__comment-date"><DateDisplay date={comment.date} /></span>
                                                        }
                                                        />
                                                    </Card>
                                                );
                                            })}
                                        </React.Fragment>
                                    : <Typography>No comments yet.</Typography>}
                                </div>

                                {(this.state.roundData.userid === this.props.userid && (this.props.userMeta && !this.props.userMeta.locked)) ? 
                                <Typography align="right" style={{marginTop:'36px'}}>
                                    <Fab size="small" color="secondary" aria-label="Add" onClick={() => {
                                        console.log("this.state.roundData: ",this.state.roundData);
                                        this.editRoundHandler(this.state.roundData);
                                        }}>
                                        <EditIcon />
                                    </Fab>
                                </Typography>
                                : null}

                                {this.state.confirmEditRound ? 
                                    <ConfirmOverlay 
                                        closeOverlay={this.closeEditOverlay}
                                        open={this.state.confirmEditRound !== null}
                                        title="Edit Round"
                                        description="You are about to edit this round, are you sure you want to do this?"
                                        confirmOverlay={() => {
                                            this.editRoundConfirmHandler({
                                                ...this.state.confirmEditRound,
                                                roundid:this.props.match.params.id
                                            });
                                        }} /> 
                                : null }

                                <Snackbar
                                    className={(this.props.match.params.type) ? 'gh-snackBar gh-snackBar--'+this.props.match.params.type : 'gh-snackBar'}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    open={typeof this.props.match.params.type !== 'undefined' && this.state.roundData !== null && this.state.openSnackBar}
                                    autoHideDuration={6000}
                                    onClose={this.closeSnackBar}
                                    ContentProps={{
                                        'aria-describedby': 'message-id',
                                    }}
                                    message={
                                        <>
                                            <span id="message-id" className="gh-snackBar__message">
                                                <SuccessIcon className="gh-snackBar__message-svg" />
                                                {actionMessage}
                                            </span>
                                            <div className="gh-snackBar__message-promo">
                                                <Button 
                                                    size="medium"
                                                    fullWidth
                                                    variant="contained"
                                                    onClick={() => {
                                                        this.props.history.push('/donate');
                                                    }}>Kindly Donate <img className="gh-button-img" src={PayPalImg} alt="Donate Securely through Paypal" /></Button>

                                            </div>

                                            
                                        </>
                                    }
                                    action={[
                                        <IconButton
                                            key="close"
                                            aria-label="Close"
                                            color="inherit"
                                            onClick={this.closeSnackBar}
                                            >
                                            <CloseIcon />
                                        </IconButton>
                                    ]}
                                    />
                            </div>
                        : <Typography>Round no longer exists.</Typography>}
                    </Pux>
                : <ScreenLoading />}
                <AppBar selected="home" />
            </Pux>
        );
    }
}

RoundDetails.propTypes = {
   roundData: PropTypes.object
}

const mapStateToProps = state => {
    return {
        userid: state.auth.userId,
        userMeta: state.auth.userMeta
    }
}

const mapDispatchToProps = dispatch =>  {
    return {
        editRound: (round) => dispatch(actionsCreators.editRound(round))
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(RoundDetails);
