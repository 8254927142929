import React from 'react';
import './ScoreCard.scss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Pux from '../../hoc/Pux';
import Heading from '../Heading/Heading';

const netClassMatrix = {
    '-3' : 'albatros',
    '-2' : 'eagle',
    '-1' : 'birdie',
    '0' : 'par',
    '1' : 'bogie',
    '2' : 'dbogie',
    'over' : 'tbogie'
}

const ScoreCardAlt = (props) => {
    let holeData = (props.type === 'preview') ? Object.values(props.carddata.card) : props.carddata;
    if(props.type === 'tees') {
        holeData = Object.values(props.carddata.meta.holedata);
    }
   
    const isNine = holeData && holeData[10] && Object.values(holeData[10])[0].length < 1;

    let totalPar = 0;
    let totalScore = 0;
    let totalYards = 0;
    let totalBPar = 0;
    let totalBScore = 0;
    let totalBYards = 0;

    return (
        <div className="gh-scorecard">
            <Heading type="h4" variant="left" className="gh-scorecard__header">Front 9</Heading>

            <Table className="gh-scorecard__table">
                <TableBody>
                    <TableRow className="gh-scorecard__row">
                        <TableCell className="gh-scorecard__cell" component="th" scope="row">
                            Hole
                        </TableCell>
                        {holeData.map((hole,i) => {
                            
                            const holeNumber = i+1;
                            if(props.type === 'scores'){
                                hole = Object.values(hole)[0];
                            }
                            return (
                                <Pux key={i}>
                                    <TableCell className="gh-scorecard__cell" >{holeNumber}</TableCell>
                                </Pux>
                            );
                        }).filter((hole,i) => {
                            return i < 9;
                        })}
                        {props.type === 'scores' ? 
                            <TableCell className="gh-scorecard__cell gh-scorecard__cell--totals" ></TableCell>
                        : null}
                    </TableRow>

                    
                    <TableRow className="gh-scorecard__row">
                        <TableCell className="gh-scorecard__cell" component="th" scope="row">
                            Par
                        </TableCell>
                        {holeData.map((hole,i) => {
                            if(props.type === 'scores'){
                                hole = Object.values(hole)[0];
                            }
                            const [holePar, , ] = hole;
                            if(i < 9) {
                                totalPar += holePar;
                            }
                            return (
                                <Pux key={i}>
                                    <TableCell className="gh-scorecard__cell" >{holePar}</TableCell>
                                </Pux>
                            );
                        }).filter((hole,i) => {
                            return i < 9;
                        })}
                        {props.type === 'scores' ? 
                            <TableCell className="gh-scorecard__cell gh-scorecard__cell--totals" >{totalPar}</TableCell>
                        : null}
                    </TableRow>
                    <TableRow className="gh-scorecard__row">
                        <TableCell className="gh-scorecard__cell" component="th" scope="row">
                            S.I
                        </TableCell>
                        {holeData.map((hole,i) => {
                            if(props.type === 'scores'){
                                hole = Object.values(hole)[0];
                            }
                            const [, , holeStroke] = hole;
                            return (
                                <Pux key={i}>
                                    <TableCell className="gh-scorecard__cell" >{holeStroke}</TableCell>
                                </Pux>
                            );
                        }).filter((hole,i) => {
                            return i < 9;
                        })}
                        {props.type === 'scores' ? 
                            <TableCell className="gh-scorecard__cell gh-scorecard__cell--totals" ></TableCell>
                        : null}
                    </TableRow>
                    {props.hasYards ? 
                        <TableRow className="gh-scorecard__row">
                            <TableCell className="gh-scorecard__cell" component="th" scope="row">
                                Yards
                            </TableCell>
                            {holeData.map((hole,i) => {
                                if(props.type === 'scores'){
                                    hole = Object.values(hole)[0];
                                }
                                const [, , , holeYards] = hole;
                                if(i < 9) {
                                    totalYards += holeYards;
                                }
                                return (
                                    <Pux key={i}>
                                        <TableCell className="gh-scorecard__cell" >{holeYards}</TableCell>
                                    </Pux>
                                );
                            }).filter((hole,i) => {
                                return i < 9;
                            })}
                            {props.type === 'scores' ? 
                                <TableCell className="gh-scorecard__cell gh-scorecard__cell--totals" >{totalYards}</TableCell>
                            : null}
                        </TableRow>
                    : null}
                    {props.type === 'scores' ? 
                        <TableRow className="gh-scorecard__row">
                            <TableCell className="gh-scorecard__cell" component="th" scope="row">
                                Score
                            </TableCell>
                            {holeData.map((hole,i) => {
                                if(props.type === 'scores'){
                                    hole = Object.values(hole)[0];
                                }
                                const [holePar, holeScore, , ] = hole;
                                if(i < 9){
                                    totalScore += holeScore;
                                }
                                let diff = (holeScore - holePar);
                                if(diff > 2) {
                                    diff = 'over';
                                }
                                const netClassName = netClassMatrix[diff];
                                return (
                                    <Pux key={i}>
                                        <TableCell className="gh-scorecard__cell gh-scorecard__cell--score" ><span className={"gh-scorecard__cell-score gh-scorecard__cell-score--"+netClassName}>{holeScore}</span></TableCell>
                                    </Pux>
                                );
                            }).filter((hole,i) => {
                                return i < 9;
                            })}
                            <TableCell className="gh-scorecard__cell gh-scorecard__cell--totals gh-scorecard__cell--emp" >{totalScore}</TableCell>
                        </TableRow>
                    : null}
                </TableBody>
            </Table>
                        
            {!isNine && 
                <>
                    <Heading type="h4" variant="left" className="gh-scorecard__header --back">Back 9</Heading>
                    <Table className="gh-scorecard__table">
                        <TableBody>
                            <TableRow className="gh-scorecard__row">
                                <TableCell className="gh-scorecard__cell" component="th" scope="row">
                                    Hole
                                </TableCell>
                                {holeData.map((hole,i) => {
                                    
                                    const holeNumber = i+1;
                                    if(props.type === 'scores'){
                                        hole = Object.values(hole)[0];
                                    }
                                    return (
                                        <Pux key={i}>
                                            <TableCell className="gh-scorecard__cell" >{holeNumber}</TableCell>
                                        </Pux>
                                    );
                                }).filter((hole,i) => {
                                    return i >= 9;
                                })}
                                {props.type === 'scores' ? 
                                    <TableCell className="gh-scorecard__cell gh-scorecard__cell--totals" ></TableCell>
                                : null}
                            </TableRow>


                            <TableRow className="gh-scorecard__row">
                                <TableCell className="gh-scorecard__cell" component="th" scope="row">
                                    Par
                                </TableCell>
                                {holeData.map((hole,i) => {
                                    if(props.type === 'scores'){
                                        hole = Object.values(hole)[0];
                                    }
                                    const [holePar, , ] = hole;
                                    if(i >= 9) {
                                        totalBPar += holePar;
                                    }
                                    return (
                                        <Pux key={i}>
                                            <TableCell className="gh-scorecard__cell" >{holePar}</TableCell>
                                        </Pux>
                                    );
                                }).filter((hole,i) => {
                                    return i >= 9;
                                })}
                                {props.type === 'scores' ? 
                                    <TableCell className="gh-scorecard__cell gh-scorecard__cell--totals" >{totalBPar}</TableCell>
                                : null}
                            </TableRow>
                            <TableRow className="gh-scorecard__row">
                                <TableCell className="gh-scorecard__cell" component="th" scope="row">
                                    S.I
                                </TableCell>
                                {holeData.map((hole,i) => {
                                    if(props.type === 'scores'){
                                        hole = Object.values(hole)[0];
                                    }
                                    const [, , holeStroke] = hole;
                                    return (
                                        <Pux key={i}>
                                            <TableCell className="gh-scorecard__cell" >{holeStroke}</TableCell>
                                        </Pux>
                                    );
                                }).filter((hole,i) => {
                                    return i >= 9;
                                })}
                                {props.type === 'scores' ? 
                                    <TableCell className="gh-scorecard__cell gh-scorecard__cell--totals" ></TableCell>
                                : null}
                            </TableRow>
                            {props.hasYards ? 
                                <TableRow className="gh-scorecard__row">
                                    <TableCell className="gh-scorecard__cell" component="th" scope="row">
                                        Yards
                                    </TableCell>
                                    {holeData.map((hole,i) => {
                                        if(props.type === 'scores'){
                                            hole = Object.values(hole)[0];
                                        }
                                        const [, , , holeYards] = hole;
                                        if(i >= 9) {
                                            totalBYards += holeYards;
                                        }
                                        return (
                                            <Pux key={i}>
                                                <TableCell className="gh-scorecard__cell" >{holeYards}</TableCell>
                                            </Pux>
                                        );
                                    }).filter((hole,i) => {
                                        return i >= 9;
                                    })}
                                    {props.type === 'scores' ? 
                                        <TableCell className="gh-scorecard__cell gh-scorecard__cell--totals" >{totalBYards}</TableCell>
                                    : null}
                                </TableRow>
                            : null}
                            {props.type === 'scores' ? 
                                <TableRow className="gh-scorecard__row">
                                    <TableCell className="gh-scorecard__cell" component="th" scope="row">
                                        Score
                                    </TableCell>
                                    {holeData.map((hole,i) => {
                                        if(props.type === 'scores'){
                                            hole = Object.values(hole)[0];
                                        }
                                        const [holePar, holeScore, , ] = hole;
                                        if(i >= 9) {
                                            totalBScore += holeScore;
                                        }
                                        let diff = (holeScore - holePar);
                                        if(diff > 2) {
                                            diff = 'over';
                                        }
                                        const netClassName = netClassMatrix[diff];
                                        return (
                                            <Pux key={i}>
                                                <TableCell className="gh-scorecard__cell gh-scorecard__cell--score" ><span className={"gh-scorecard__cell-score gh-scorecard__cell-score--"+netClassName}>{holeScore}</span></TableCell>
                                            </Pux>
                                        );
                                    }).filter((hole,i) => {
                                        return i >= 9;
                                    })}
                                    <TableCell className="gh-scorecard__cell gh-scorecard__cell--totals gh-scorecard__cell--emp" >{totalBScore}</TableCell>
                                </TableRow>
                            : null}
                        </TableBody>
                    </Table>
                </>
            }

            
        </div>
    );
};
export default ScoreCardAlt;