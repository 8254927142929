import React, { Component } from 'react';
import '../../../assets/scss/forms.scss';
import '../../../assets/scss/snackbar.scss';
import './SignIn.scss';
//import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {validateForm, validateUnit } from  '../../../utils/validation';
import storeText from  '../../../utils/storeText';
//import firebase from '../../Firebase.js';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Logo from '../../../components/Logo/Logo';
import Heading from '../../../components/Heading/Heading';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import MailIcon from '@material-ui/icons/MailOutline';
import LockIcon from '@material-ui/icons/LockOutlined';
import ErrorIcon from '@material-ui/icons/Error';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import * as actionsCreators from '../../../store/actions/index';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = theme => ({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    fabProgress: {
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  });

export class SignIn extends Component {

    state = {
        snackBarOpen: true,
        //snackBarMessage: '',
        showPassword: false,
        form: {
            isValid:true,
            fields: {
                email: {
                    value: '',
                    valid: true,
                    errorType: null,
                    rules: {
                        notEmpty : {
                            message: 'Dont forget your email.'
                        },
                        emailFormat : {
                            message: 'Oops, your email address doesn\'t look right.'
                        },
                        maxlength: {
                            message: 'Have you got a shorter email?',
                            length: 50
                        }
                    }
                },
                password: {
                    value: '',
                    valid: true,
                    errorType: null,
                    rules: {
                        notEmpty : {
                            message: 'Dont forget your password.'
                        },
                        maxlength: {
                            message: 'Password needs to be less than 50 characters.',
                            length: 50
                        },
                        minlength: {
                            message: 'Password needs to be more than 6 characters.',
                            length: 6
                        }
                    }
                }
            }
        },
        loginAttempts:0
    }

    componentWillMount() {
        document.title = 'Golf Handicap - Sign In';
    }

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };

    submitHandler = (event) => {
        event.preventDefault();
        const validateFormState = validateForm(this.state.form);
        this.setState({form:validateFormState,snackBarOpen:true});
        if(validateFormState.isValid){
            this.props.onAuth(this.state.form.fields.email.value, this.state.form.fields.password.value);
            this.props.loginAttempt();
        }
    }

    inputChangedHandler = (event) => {
        const inputValue = event.target.value;
        const inputName = event.target.name;
        const updateState = {...this.state};
        updateState.form.fields[inputName].value = inputValue;
        this.setState(updateState);
    }

    blurField = (event) => {
        const fieldName = event.target.name;
        if(validateUnit(fieldName)){
            const updateState = {...this.state};
            updateState.form.fields[fieldName].errorType = false;
            updateState.form.fields[fieldName].valid = true;
            this.setState(updateState);
        }
    }
    
    handleClose = (event, reason) => {
        console.log("need to close snackbar: ",event);
        if (reason === 'clickaway') {
          return;
        }
        this.setState({snackBarOpen:false});
    };

    render() {


        const { classes } = this.props;
        const buttonClassname = classNames({
        [classes.buttonSuccess]: true,
        });

        return (
            <main className="fullpage">
               
                <Logo bemClass="gh-logo--alone" />
                    
                <Heading type="h1" variant="central">Sign In</Heading>
                
                <form className="form" onSubmit={this.submitHandler}>
                    <FormControl margin="normal" fullWidth error={this.state.form.fields.email.valid ? false : true}>
                        <InputLabel htmlFor="email">Email Address</InputLabel>
                        <Input 
                            onChange={this.inputChangedHandler}
                            onBlur={this.blurField}
                            className="form__input" 
                            id="email" 
                            startAdornment={
                                <InputAdornment position="start">
                                <MailIcon />
                                </InputAdornment>
                            }
                            name="email" 
                            value={this.state.email}
                            autoComplete="email" 
                             />
                        {!this.state.form.fields.email.valid ?
                            <FormHelperText id="email-error">{this.state.form.fields.email.rules[this.state.form.fields.email.errorType].message}</FormHelperText>
                        : null }
                        
                    </FormControl>
                    <FormControl margin="normal" fullWidth error={this.state.form.fields.password.valid ? false : true}>
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <Input 
                            className="form__input" 
                            name="password"
                            onChange={this.inputChangedHandler}
                            onBlur={this.blurField}
                            value={this.state.password}
                            type={this.state.showPassword ? 'text' : 'password'} 
                            id="password" 
                            startAdornment={
                                <InputAdornment position="start">
                                <LockIcon />
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                <IconButton
                                    aria-label="Toggle Password Visibility"
                                    onClick={this.handleClickShowPassword}
                                >
                                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                                </InputAdornment>
                            }
                            autoComplete="current-password" />
                            <FormHelperText id="helper">Your Golf Handicap existing users will need to reset their password first time they login.</FormHelperText>
                            {!this.state.form.fields.password.valid ?
                            <FormHelperText id="email-error">{this.state.form.fields.password.rules[this.state.form.fields.password.errorType].message}</FormHelperText>
                        : null }
                    </FormControl>
                    {/*<FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />*/}
                    <FormControl margin="normal" fullWidth>
                        <div className={classes.wrapper}>
                            <Button
                                type="submit"
                                fullWidth
                                size="large"
                                variant="contained"
                                color="primary"
                                disabled={this.props.loading}
                                className={buttonClassname}
                            >
                                Sign In
                            </Button>
                            {this.props.loading ? <CircularProgress size={24} className={classes.buttonProgress} /> : null}
                        </div>
                    </FormControl>
                    <div className="form__alts">
                        <Typography variant="body1" className="form__alts-para" align="right">
                            <Link to="/resetpassword">Forgot Password</Link>
                            <Link to="/signup">Sign Up</Link>
                        </Typography>
                    </div>
                    
                    <Snackbar
                        className='gh-snackBar'
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        open={this.props.error !== null && this.state.snackBarOpen}
                        autoHideDuration={6000}
                        onClose={this.handleClose}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={
                            <span id="message-id" className="gh-snackBar__message">
                              <ErrorIcon className="gh-snackBar__message-svg" />
                              {this.props.error ? storeText(this.props.error.code) : null}
                            </span>
                        }
                        action={[
                            <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={this.handleClose}
                            >
                            <CloseIcon />
                            </IconButton>
                        ]}
                        />
                       
                    
                </form>
            
            </main>
        );
    }
}
/*
SignIn.propTypes = {
    searchTypeHandler: PropTypes.func
}*/

const mapStateToProps = state => {
    return {
        loginAttempts: state.sin.loginAttempts,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null,
        loading: state.auth.loading
    }
}

const mapDispatchToProps = dispatch =>  {
    return {
        onAuth: (email, password) => dispatch(actionsCreators.auth(email, password)),
        loginAttempt: () => dispatch(actionsCreators.incAttempts())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SignIn));