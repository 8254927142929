import React from 'react';
import Button from '@material-ui/core/Button';
import PayPalImg from '../../assets/images/paypal.png';

const payPalDonate = () => {

    const paypalUrl = 'https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=WRWPUA7N8UC4U&source=url';
    
    return (
      <Button
              type="submit"
              fullWidth
              size="large"
              variant="contained"
              color="secondary"
              href={paypalUrl}
              className="gh-paypal-button"
          >
              Donate Via  <img className="gh-button-img" src={PayPalImg} alt="Donate Securely through Paypal" />
      </Button>
    );
};

export default payPalDonate;