import React, { Component } from 'react';
import Header from '../../components/Header/Header';
import AppBar from '../AppBar/AppBar';
//import PropTypes from 'prop-types';
//import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Pux from '../../hoc/Pux';
import Heading from '../../components/Heading/Heading';
import {createClient} from 'contentful';
import ReactMarkdown from 'react-markdown';


export class Content extends Component {

    state = {
        content: null,
        title: null
    }

    componentWillUnmount() {
        console.log("[componentWillUnmount]");
    }

    componentWillReceiveProps() {
        console.log("[componentWillReceiveProps] content.js");
    }

    componentDidMount() {
        const pageId = this.props.match.params.id;
        if(!pageId){
            return;
        }
        
        const client = createClient({
            space: process.env.REACT_APP_CONTENTFUL_SPACE,
            accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN
          })
          
          client.getEntry(pageId)
            .then((entry) => {
                // logs the entry metadata
                //console.log(entry)

                // logs the field with ID title
                document.title = 'Golf Handicap - ' + entry.fields.title;
                this.setState({content:entry.fields.content, title:entry.fields.title})
            })
    }

    
    render() {

        return (
            <Pux>
                <Header loggedOut={!this.props.isAuthenticated} />
                <div className="gh-content gh-content--contentful">
                
                    {this.state.content ? 
                        <Pux>
                            {this.state.title ? <Heading variant="h1">{this.state.title}</Heading> : null}
                            <ReactMarkdown source={this.state.content} />
                        </Pux>
                    : null}
                
                </div>
                <AppBar selected="home" />
            </Pux>
        );
    }
}
/*
Help.propTypes = {
    searchTypeHandler: PropTypes.func
}*/

const mapStateToProps = state => {
    return {
        searchesmade: state.searchesmade,
        isAuthenticated: state.auth.token !== null,
        userId: state.auth.userId,
        userMeta: state.auth.userMeta,
        round: state.round
    }
}

export default connect(mapStateToProps)(Content);
