import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

const paperStyle = {
    background: 'radial-gradient(farthest-corner at 90% 0,#08A6BE 0%, #0E3676 50%,  #08193C 100%)',
    borderRadius: 3,
    border:'1px solid #ffffff',
    margin: '24px'
};


const ConfirmOverlay = (props) => {

    console.log("confirm overlay open");

    return (
        <Dialog
            open={props.open}
            onClose={props.closeOverlay}
            className="gh-overlays"
            aria-labelledby="resetround-dialog-title"
            aria-describedby="resetround-dialog-description"
            PaperProps={{className:'gh-overlays__paper',style:paperStyle}}
            >
            <DialogTitle className="gh-overlays__title" id="resetround-dialog-title">
                {props.title}
                <CloseIcon onClick={props.closeOverlay} className="gh-overlays__title-close" />
            </DialogTitle>
            <DialogContent className="gh-overlays__content">
                <DialogContentText id="resetround-dialog-description">
                    {props.description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.closeOverlay} color="secondary">
                Cancel
                </Button>
                <Button onClick={props.confirmOverlay} color="primary" autoFocus>
                Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
    
};

export default ConfirmOverlay;