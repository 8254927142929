import React from 'react';
import './MainMenu.scss';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SettingsIcon from '@material-ui/icons/Settings';
import LocationCity from '@material-ui/icons/LocationOn';
import SignInIcon from '@material-ui/icons/Person';
import SignUpIcon from '@material-ui/icons/PersonAdd';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PrivacyIcon from '@material-ui/icons/PhonelinkLock';
import SecurityIcon from '@material-ui/icons/Security';
import HelpIcon from '@material-ui/icons/HelpOutline';
import PersonIcon from '@material-ui/icons/Person';
import ChatIcon from '@material-ui/icons/Chat';
import * as routes from '../../utils/routes.js';

const MainMenu = (props) => {

    
    return (
        <List component="nav" className="gh-mainmenu">
            {!props.loggedOut ?
            <React.Fragment>    
                <ListItem button component={Link} to="/editprofile" className="gh-mainmenu__item">
                    <ListItemIcon className="gh-mainmenu__item-icon">
                        <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="My Settings" className="gh-mainmenu__item-text" />
                    <ListItemIcon className="gh-mainmenu__item-next">
                        <ChevronRightIcon className="gh-mainmenu__item-next-svg" />
                    </ListItemIcon>
                </ListItem>
                <ListItem button component={Link} to="/courses" className="gh-mainmenu__item">
                    <ListItemIcon className="gh-mainmenu__item-icon">
                        <LocationCity />
                    </ListItemIcon>
                    <ListItemText primary="Find Courses" className="gh-mainmenu__item-text" />
                    <ListItemIcon className="gh-mainmenu__item-next">
                        <ChevronRightIcon className="gh-mainmenu__item-next-svg" />
                    </ListItemIcon>
                </ListItem>
                <ListItem button component={Link} to="/users" className="gh-mainmenu__item">
                    <ListItemIcon className="gh-mainmenu__item-icon">
                        <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="Find Users" className="gh-mainmenu__item-text" />
                    <ListItemIcon className="gh-mainmenu__item-next">
                        <ChevronRightIcon className="gh-mainmenu__item-next-svg" />
                    </ListItemIcon>
                </ListItem>
                <ListItem button component={Link} to={routes.CONTENT_DONATE} className="gh-mainmenu__item gh-mainmenu__item--gold">
                    <ListItemIcon className="gh-mainmenu__item-icon">
                        <SecurityIcon />
                    </ListItemIcon>
                    <ListItemText className="gh-mainmenu__item-text gh-mainmenu__item-text--desc">
                        Donate
                        <span className="gh-mainmenu__item-text-sub">Kindly help us</span>
                    </ListItemText>
                    <ListItemIcon className="gh-mainmenu__item-next">
                        <ChevronRightIcon className="gh-mainmenu__item-next-svg" />
                    </ListItemIcon>
                </ListItem>
                {/*!props.premium ? 
                <ListItem button component={Link} to={routes.CONTENT_PREMIUM} className="gh-mainmenu__item gh-mainmenu__item--gold">
                    <ListItemIcon className="gh-mainmenu__item-icon">
                        <SecurityIcon />
                    </ListItemIcon>
                    <ListItemText className="gh-mainmenu__item-text gh-mainmenu__item-text--desc">
                        Golf Handicapp Premium
                        <span className="gh-mainmenu__item-text-sub">Upgrade for more features</span>
                    </ListItemText>
                    <ListItemIcon className="gh-mainmenu__item-next">
                        <ChevronRightIcon className="gh-mainmenu__item-next-svg" />
                    </ListItemIcon>
                </ListItem>
                : null*/}
                <ListItem button component={Link} to={routes.CONTENT_PRIVACY} className="gh-mainmenu__item">
                    <ListItemIcon className="gh-mainmenu__item-icon">
                        <PrivacyIcon />
                    </ListItemIcon>
                    <ListItemText primary="Privacy" className="gh-mainmenu__item-text" />
                    <ListItemIcon className="gh-mainmenu__item-next">
                        <ChevronRightIcon className="gh-mainmenu__item-next-svg" />
                    </ListItemIcon>
                </ListItem>
                <ListItem button component={Link} to={routes.CONTENT_HELP} className="gh-mainmenu__item">
                    <ListItemIcon className="gh-mainmenu__item-icon">
                        <HelpIcon />
                    </ListItemIcon>
                    <ListItemText primary="Help" className="gh-mainmenu__item-text" />
                    <ListItemIcon className="gh-mainmenu__item-next">
                        <ChevronRightIcon className="gh-mainmenu__item-next-svg" />
                    </ListItemIcon>
                </ListItem>
                <ListItem button component={Link} to={routes.CONTACT_US} className="gh-mainmenu__item">
                    <ListItemIcon className="gh-mainmenu__item-icon">
                        <ChatIcon />
                    </ListItemIcon>
                    <ListItemText primary="Contact Us" className="gh-mainmenu__item-text" />
                    <ListItemIcon className="gh-mainmenu__item-next">
                        <ChevronRightIcon className="gh-mainmenu__item-next-svg" />
                    </ListItemIcon>
                </ListItem>
            </React.Fragment>
            : 
            <React.Fragment> 
                <ListItem button component={Link} to={'/signin'} className="gh-mainmenu__item">
                    <ListItemIcon className="gh-mainmenu__item-icon">
                        <SignInIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sign In" className="gh-mainmenu__item-text" />
                    <ListItemIcon className="gh-mainmenu__item-next">
                        <ChevronRightIcon className="gh-mainmenu__item-next-svg" />
                    </ListItemIcon>
                </ListItem>
                <ListItem button component={Link} to={'/signup'} className="gh-mainmenu__item">
                    <ListItemIcon className="gh-mainmenu__item-icon">
                        <SignUpIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sign Up" className="gh-mainmenu__item-text" />
                    <ListItemIcon className="gh-mainmenu__item-next">
                        <ChevronRightIcon className="gh-mainmenu__item-next-svg" />
                    </ListItemIcon>
                </ListItem>
                {/*<ListItem button component={Link} to={routes.CONTENT_PREMIUM} className="gh-mainmenu__item gh-mainmenu__item--gold">
                    <ListItemIcon className="gh-mainmenu__item-icon">
                        <SecurityIcon />
                    </ListItemIcon>
                    <ListItemText className="gh-mainmenu__item-text gh-mainmenu__item-text--desc">
                        Golf Handicapp Premium
                        <span className="gh-mainmenu__item-text-sub">Upgrade for more features</span>
                    </ListItemText>
                    <ListItemIcon className="gh-mainmenu__item-next">
                        <ChevronRightIcon className="gh-mainmenu__item-next-svg" />
                    </ListItemIcon>
            </ListItem>*/}
                <ListItem button component={Link} to="/courses" className="gh-mainmenu__item">
                    <ListItemIcon className="gh-mainmenu__item-icon">
                        <LocationCity />
                    </ListItemIcon>
                    <ListItemText primary="Find Courses" className="gh-mainmenu__item-text" />
                    <ListItemIcon className="gh-mainmenu__item-next">
                        <ChevronRightIcon className="gh-mainmenu__item-next-svg" />
                    </ListItemIcon>
                </ListItem>
                
                <ListItem button component={Link} to={routes.CONTENT_PRIVACY} className="gh-mainmenu__item">
                    <ListItemIcon className="gh-mainmenu__item-icon">
                        <PrivacyIcon />
                    </ListItemIcon>
                    <ListItemText primary="Privacy" className="gh-mainmenu__item-text" />
                    <ListItemIcon className="gh-mainmenu__item-next">
                        <ChevronRightIcon className="gh-mainmenu__item-next-svg" />
                    </ListItemIcon>
                </ListItem>
                <ListItem button component={Link} to={routes.CONTENT_HELP} className="gh-mainmenu__item">
                    <ListItemIcon className="gh-mainmenu__item-icon">
                        <HelpIcon />
                    </ListItemIcon>
                    <ListItemText primary="Help" className="gh-mainmenu__item-text" />
                    <ListItemIcon className="gh-mainmenu__item-next">
                        <ChevronRightIcon className="gh-mainmenu__item-next-svg" />
                    </ListItemIcon>
                </ListItem>
            </React.Fragment>
            }
            
        </List>
    );
};
export default MainMenu;