import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import './HoleEntryField.scss';

const holeentryfield = (props) => {

    
    let holePar, holeStrokeIndex, selectedValue;
    //get data from either score data or templated card data

    if(props.selectedValue) {
        selectedValue = props.selectedValue;
    }

    if(props.holeData){
        [holePar, , holeStrokeIndex] = props.holeData;
        
        if(!props.selectedValue && props.holeData[props.selectedIndex]){
            selectedValue = props.holeData[props.selectedIndex];
        }
        
    }

    return (
            <FormControl margin="normal" className="gh-holeentry__radiogroup" fullWidth error={!props.valid}>
                <FormLabel className="gh-holeentry__radiogroup-label" component="legend">{props.groupLabel}</FormLabel>
                <div className="gh-holeentry__radiogroup-scroll">
                    <RadioGroup
                        ref={props.ref}
                        style={{
                            width:(props.entries*38)+'px'
                        }}
                        className="form__group form__group--numberentry gh-holeentry__radiogroup-scroll-hold"
                        aria-label={"Hole " + props.holeNumber}
                        name={"hole-" + props.holeNumber}
                    >
                        {[...Array(props.entries)].map((_, i) => {
                            let valueEntry = i;
                            if(props.startValue){
                                valueEntry += props.startValue;
                            } else {
                                valueEntry += 1;
                            }
                            let isSelected = false;
                            if(selectedValue && (selectedValue === valueEntry)){
                                isSelected = true;
                            }
                            
                            return (
                                <FormControlLabel 
                                    className={isSelected ? "form__group--numberentry-entrylabel form__group--numberentry-entrylabel--selected" : "form__group--numberentry-entrylabel"}
                                    key={i}
                                    value={valueEntry.toString()}
                                    control={<Radio 
                                        className="form__group--numberentry-entrylabel-radio"
                                        onChange={() => {
                                        props.valueSelected(props.holeNumber,valueEntry,holePar,holeStrokeIndex);//holeNumber,score,par,stroke
                                    }} 
                                    checked={isSelected} />} 
                                    label={<span className="form__group--numberentry-entrylabel-cover">{valueEntry.toString()}</span>} 
                                />
                            );
                        })}
                    </RadioGroup>
                </div>
                {props.warning ?
                        <FormHelperText className="form__warning" id="holefield-warning"><WarningIcon className="form__warning-icon" />{props.warning}</FormHelperText>
                : null }
                {!props.valid ?
                    <FormHelperText id="holefield-error">{props.error}</FormHelperText>
                : null }
            </FormControl>
    );
};
  

export default holeentryfield;
