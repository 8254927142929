import React from 'react';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Settings';
import HoleEntryField from '../../components/HoleEntryField/HoleEntryField';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import './HoleEntry.scss';

const holeentry = (props) => {

    //console.log("holeData: ",props.holeData);
    let holePar, holeStrokeIndex, holeYards;
    if(props.holeData){
        [holePar, , holeStrokeIndex, holeYards] = props.holeData;
    }

    return (
        <div className="gh-holeentry">
            <div className="gh-holeentry__meta">
                <Typography className="gh-holeentry__meta-text">
                    Hole {props.holeNumber}
                    {holePar ? 
                        <span className="gh-holeentry__meta-text-info">(Par {holePar}, SI {holeStrokeIndex}{holeYards ? ", Yards "+holeYards : null})
                            {!props.valid ? <ErrorIcon className="gh-holeentry__meta-text-info-error" /> : null}
                        </span>
                     : null}
                     {holePar ? 
                        <EditIcon onClick={() =>  {
                            props.holeSetup(props.holeNumber);
                        }} size="small" color="inherit" className="gh-holeentry__meta-icon">
                        </EditIcon> : 
                        <Button size="small" color="inherit" className="gh-holeentry__meta-button" onClick={() =>  {
                            props.holeSetup(props.holeNumber);
                         }}><AddIcon size="small" color="inherit" className="gh-holeentry__meta-button-icon" />Set up Hole</Button>}
                    
                </Typography>
            </div>

            {holePar ? <HoleEntryField
                groupLabel="Score"
                ref={props.ref}
                holeNumber={props.holeNumber}
                holeData={props.holeData}
                valid={props.valid}
                error={props.error}
                selectedIndex={1} //index of score 0=par, 1=score, 2=si
                entries={10}
                valueSelected={props.scoreSelected}
                /> : null}
            
        </div>
    );
};
  

export default holeentry;
