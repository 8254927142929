import React, {Component} from 'react';
import './UserMetaOverlay.scss';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import EditIcon from '@material-ui/icons/Edit';
import PublicIcon from '@material-ui/icons/Public';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Slider from '@material-ui/core/Slider';
import { validateForm, validateUnit } from  '../../utils/validation';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import firebase from '../../Firebase.js';
import * as actionsCreators from '../../store/actions/index';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getPlayingHandicap } from '../../store/actions/handicap';

const paperStyle = {
    background: 'radial-gradient(farthest-corner at 90% 0,#08A6BE 0%, #0E3676 50%,  #08193C 100%)',
    borderRadius: 3,
    border:'1px solid #ffffff',
    margin: '24px'
};

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    fabProgress: {
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

const marks = [
    {
      value: 4,
      label: 'Pro',
    },
    {
        value: 27,
        label: 'Medium',
    },
    {
      value: 50,
      label: 'Novice',
    },
  ];

class UserMetaOverlay extends Component {

    state = {
        open: true,
        requesting:false,
        handicapValue: this.props.currentHandicap ? getPlayingHandicap(this.props.currentHandicap) : 28, 
        form: {
            isValid:true,
            fields: {
                displayname: {
                    value: this.props.displayName,
                    valid: true,
                    errorType: null,
                    rules: {
                        notEmpty : {
                            message: 'Please provide a name.'
                        },
                        minlength: {
                            message: 'Have you got a longer name.',
                            length: 5
                        },
                        maxlength: {
                            message: 'Have you got a shorter name.',
                            length: 20
                        }
                    }
                }
            }
        }
    }

    handleClose = () => {
        this.setState({ open: false });
    };

    submitHandler = (event) => {
        event.preventDefault();
        const validateFormState = validateForm(this.state.form);
        this.setState({form:validateFormState});
        if(validateFormState.isValid){
            this.setState({requesting:true});
            this.updateUserMeta(this.state.form.fields.displayname.value, this.state.handicapValue);
        }
    }

    updateUserMeta = (displayname, handicap) => {

        const db = firebase.firestore();
        const settings = {};
        db.settings(settings);
        db.collection("user_meta").doc(this.props.userId).update({
            displayname,
            whindex: handicap,
            whmode: true,
        })
        .then(() => {
            this.setState({ open: false });
            this.props.authRefreshMeta(this.props.userId);
        })
        .catch((error) => {
            this.setState({ open: false });
        });
    }

    inputChangedHandler = (event) => {
        const inputValue = event.target.value;
        const inputName = event.target.name;
        const updateState = {...this.state};
        updateState.form.fields[inputName].value = inputValue;
        this.setState(updateState);
    }

    blurField = event => {
        const fieldName = event.target.name;
        if(validateUnit(fieldName)){
            const updateState = {...this.state};
            updateState.form.fields[fieldName].errorType = false;
            updateState.form.fields[fieldName].valid = true;
            this.setState(updateState);
        }
    }

    render() {

        const { classes } = this.props;
        const buttonClassname = classNames({
            [classes.buttonSuccess]: true,
        });
        const displayName = this.props.displayName;
        const currentHandicap = this.props.currentHandicap;

        

        let title = 'Your Details';
        let subtitle = 'We just need a few more details from you to help your experience using this app.';
        if(displayName) {
            title = 'World Handicap Index Update';
            subtitle = `Great News! We've now upgraded the site to use the new world handicap system as such we need to get a few more details off you.`;
        }
        if(currentHandicap) {
            subtitle = `Great News! We've now upgraded the site to use the new world handicap system, for your new handicap index we can calculate it for you based on your previous rounds if you have over 3 rounds, if not we can just use your old handicap as a guide and it will update itself and become more accurate as you add more rounds.`;
        }
        
        return (
            <Dialog
                open={this.state.open}
                disableBackdropClick
                disableEscapeKeyDown
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
                className="gh-overlays"
                PaperProps={{className:'gh-overlays__paper',style:paperStyle}}
                >
                <DialogTitle className="gh-overlays__title" id="form-dialog-title">{title}</DialogTitle>
                <DialogContent className="gh-overlays__content">
                    <form className="form form--overlayed" onSubmit={this.submitHandler}>
                        <FormControl margin="normal" fullWidth>
                            <Typography>{subtitle}</Typography>
                        </FormControl>
                        <FormControl style={{display: displayName ? 'none' : 'inline-flex'}} margin="normal" fullWidth error={this.state.form.fields.displayname.valid ? false : true}>
                            <InputLabel htmlFor="displayname">Display Name</InputLabel>
                            <Input 
                                onChange={this.inputChangedHandler}
                                onBlur={this.blurField}
                                className="form__input" 
                                id="displayname" 
                                startAdornment={
                                    <InputAdornment position="start">
                                    <EditIcon />
                                    </InputAdornment>
                                }
                                name="displayname" 
                                value={this.state.form.fields.displayname.value}
                                autoComplete="displayname" 
                                 />
                            {!this.state.form.fields.displayname.valid ?
                                <FormHelperText id="handicap-error">{this.state.form.fields.displayname.rules[this.state.form.fields.displayname.errorType].message}</FormHelperText>
                            : null }
                        </FormControl>

                        

                        <FormControl margin="normal" fullWidth>
                            <label htmlFor="discrete-slider-custom" className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink" data-shrink="true" for="displayname">Your Handicap Index</label>
                            <Slider
                                defaultValue={this.state.handicapValue}
                                onChange={(event, val) => {
                                    const updateState = {...this.state};
                                    updateState.handicapValue = val;
                                    this.setState(updateState);
                                }} 
                                style={{marginTop: '12px'}}
                                aria-labelledby="discrete-slider-custom"
                                step={1}
                                min={0}
                                max={54}
                                valueLabelDisplay="on"
                                marks={marks}
                            />
                            <FormHelperText style={{marginTop: '12px'}} id="handicap-helper">Dont worry, if you don't know this yet, you can guess a rough idea, we'll work it out more accurately after you've added a few rounds.</FormHelperText>
                        </FormControl>
                        
                        <FormControl margin="normal" fullWidth>
                            <div className={classes.wrapper}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    disabled={this.state.requesting}
                                    className={buttonClassname}
                                >
                                    Save
                                </Button>
                                {this.state.requesting ? <CircularProgress size={24} className={classes.buttonProgress} /> : null}
                            </div>
                        </FormControl>
                    </form>
                </DialogContent>
                </Dialog>
        );
    }
};

const mapStateToProps = state => {
    return {
        userId: state.auth.userId,
        userMeta: state.auth.userMeta
    }
}


const mapDispatchToProps = dispatch =>  {
    return {
        authRefreshMeta: (userId) => dispatch(actionsCreators.authRefreshMeta(userId))
    };
}


export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(UserMetaOverlay)));