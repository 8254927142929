import React, { Component } from 'react';
//import PropTypes from 'prop-types';
//import firebase from '../../Firebase.js';
import { connect } from 'react-redux';
import BigSmallValue from '../../components/BigSmallValue/BigSmallValue';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withRouter, Link } from 'react-router-dom';
import * as routes from '../../utils/routes.js';


export class IAPButtons extends Component {

    purchase = (length) => {
        //alert("IAP PURCHASE");

        const iapPurchse = {
            option: length,
            hashkey: this.props.userId
        }
        window.postMessage(JSON.stringify(iapPurchse));
    }

    render() {

        return (
            <React.Fragment>
                <div className="gh-offer gh-offer--reducedbottom" onClick={() => {
                        this.purchase('12months');
                    }}>
                    <BigSmallValue
                        className="gh-offer__side"
                        value={12}
                        label="MONTHS"
                        />
                    <Typography className="gh-offer__text"><span className="gh-offer__premium">Premium</span> membership for 1 year. £5.99
                    </Typography>
                    <Button 
                        size="small"
                        variant="contained"
                        className="gh-offer__price">
                            Buy
                    </Button>
                </div>
                <div className="gh-offer gh-offer--reducedbottom" onClick={() => {
                    this.purchase('6months');
                    }}>
                    <BigSmallValue
                        className="gh-offer__side"
                        value={6}
                        label="MONTHS"
                        />
                    <Typography className="gh-offer__text"><span className="gh-offer__premium">Premium</span> membership for 6 months. £3.99
                    </Typography>
                    <Button 
                        size="small"
                        variant="contained"
                        className="gh-offer__price">
                            Buy
                    </Button>
                </div>
                <Typography className="gh-terms">
                    Payment will be charged to iTunes Account at confirmation of purchase. Subscription automatically renews unless auto-renew is turned off at least 24-hours before the end of the current period.
                    <br/><br/>Account will be charged for renewal within 24-hours prior to the end of the current period, and identify the cost of the renewal.
                    <br/><br/>Subscriptions may be managed by the user and auto-renewal may be turned off by going to the user's Account Settings after purchase.
                    <br/><br/>Any unused portion of a free trial period, if offered, will be forfeited when the user purchases a subscription to that publication, where applicable.
                    
                    <br/><br/><Link className="gh-terms__link" to={routes.CONTENT_TERMS}>Terms of use</Link>
                    <br/><br/><Link className="gh-terms__link" to={routes.CONTENT_PRIVACY}>Privacy Policy</Link>
                    <br/><br/>

                </Typography>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
      isAuthenticated:state.auth.token !== null,
      userId: state.auth.userId,
      userMeta: state.auth.userMeta,
      userMetaFetched: state.auth.userMetaFetched,
      triedAuthentication: state.auth.triedAuthentication
    }
}
export default withRouter(connect(mapStateToProps)(IAPButtons));
